import React, { useState, useEffect } from "react";
import axiosInstance from "./axiosInstance";
import { Form, FormGroup, Label, Input } from "reactstrap";
import "./managecontentstyle/managecontent.css";
import { toast } from "react-toastify";
import "./managecontentstyle/buttonstyle.css";

function CreateLessonProject() {
  const [course, setCourse] = useState("");
  const [module, setModule] = useState("");
  const [lesson, setLesson] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [projectURL, setProjectURL] = useState("");
  const [helpingMaterialURL, setHelpingMaterialURL] = useState("");
  const [totalMarks, setTotalMarks] = useState("");
  const [image, setImage] = useState(null);

  const [courses, setCourses] = useState([]);
  const [modules, setModules] = useState([]);
  const [lessons, setLessons] = useState([]);
  //const [savingpath,setSavingPath] = useState([]);

  useEffect(() => {
    fetchCourses();
    //fetchPath();
  }, []);

  const fetchCourses = () => {
    axiosInstance
      .post("/api/managecontent/getAllCourses")
      .then((response) => {
        const sortedCourses = response.data.slice().sort((a, b) => {
          const coursenameA = a.coursename;
          const coursenameB = b.coursename;
          return coursenameA.localeCompare(coursenameB);
        });

        setCourses(sortedCourses);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchModules = (courseId) => {
    axiosInstance
      .post("/api/managecontent/getAllModule", { courseId })
      .then((response) => {
        const filteredModules = response.data.filter(
          (module) => module.Course_id === parseInt(courseId, 10)
        );
        // Sort the filteredModules array based on ModuleNumber
        filteredModules.sort((a, b) => a.ModuleNumber - b.ModuleNumber);
        setModules(filteredModules);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchLessons = (moduleId) => {
    axiosInstance
      .post("/api/managecontent/getAllLesson", { moduleId })
      .then((response) => {
        const filteredLessons = response.data.filter(
          (lesson) => lesson.Module_id === parseInt(moduleId)
        );
        // Sort the filteredLessons array based on LessonNo
        filteredLessons.sort((a, b) => a.LessonNo - b.LessonNo);
        setLessons(filteredLessons);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCourseSelect = (courseId) => {
    setCourse(courseId);
    fetchModules(courseId);
  };

  const handleModuleSelect = (moduleId) => {
    setModule(moduleId);
    fetchLessons(moduleId);
  };

  const handleLessonSelect = (lessonId) => {
    setLesson(lessonId);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (
      lesson.trim() === "" ||
      name.trim() === "" ||
      description.trim() === "" ||
      projectURL.trim() === "" ||
      helpingMaterialURL.trim() === "" ||
      totalMarks.trim() === "" ||
      image === null
    ) {
      // Display an error message
      toast.error("Please fill out all required fields and select an image.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return; // Exit the function, don't proceed with the API call
    }

    // Create a FormData object to send the data, including the selected image
    const formData = new FormData();
    formData.append("Lesson_id", lesson);
    formData.append("projectassignmentname", name);
    formData.append("projectassignmentdescription", description);
    formData.append("projectassignmenturl", projectURL);
    formData.append("helpingmaterialurl", helpingMaterialURL);
    formData.append("projectmarks", totalMarks);
    formData.append("image", image);

    // Make a POST request to your backend to handle the file upload
    axiosInstance
      .post("/api/managecontent/addLessonProject", formData)
      .then((response) => {
        // Handle the response if needed
        // Clear form fields and show a success message
        setLesson("");
        setName("");
        setDescription("");
        setProjectURL("");
        setHelpingMaterialURL("");
        setTotalMarks("");
        setImage(null);
        const image_val = document.getElementById('image-input');
        image_val.value = "";
        toast.success("Lesson Project has been Added", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((error) => {
        console.error(error);
        // Handle errors and show an error message if necessary
      });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };

  return (
    <div className="create-lesson-project">
      <h2>Create Lesson Project</h2>
      <Form>
        <FormGroup>
          <Label for="course-select">Course</Label>
          <Input
            type="select"
            name="select"
            id="course-select"
            value={course}
            required
            onChange={(event) => handleCourseSelect(event.target.value)}
          >
            <option>Select a course</option>
            {courses.map((course) => (
              <option key={course.id} value={course.Course_id}>
                {course.coursename}
              </option>
            ))}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="module-select">Module</Label>
          <Input
            type="select"
            name="select"
            id="module-select"
            value={module}
            required
            onChange={(event) => handleModuleSelect(event.target.value)}
          >
            <option>Select a Module</option>
            {modules.map((module) => (
              <option key={module.id} value={module.Module_id}>
                {module.modulename}
              </option>
            ))}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="lesson-select">Lesson</Label>
          <Input
            type="select"
            name="select"
            id="lesson-select"
            value={lesson}
            required
            onChange={(event) => handleLessonSelect(event.target.value)}
          >
            <option>Select a Lesson</option>
            {lessons.map((lesson) => (
              <option key={lesson.id} value={lesson.Lesson_id}>
                {lesson.lessonname}
              </option>
            ))}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="name-input">Name</Label>
          <Input
            type="text"
            name="name"
            id="name-input"
            placeholder="Enter name"
            value={name}
            required
            onChange={(e) => setName(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="description-input">Description</Label>
          <Input
            type="textarea"
            name="description"
            id="description-input"
            
            placeholder="Enter description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="project-url-input">Project URL</Label>
          <Input
            type="text"
            name="project-url"
            id="project-url-input"
            
            placeholder="Enter project URL"
            value={projectURL}
            onChange={(e) => setProjectURL(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="helping-material-url-input">Helping Material URL</Label>
          <Input
            type="text"
            name="helping-material-url"
            id="helping-material-url-input"
            
            placeholder="Enter helping material URL"
            value={helpingMaterialURL}
            onChange={(e) => setHelpingMaterialURL(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="total-marks-input">Total Marks</Label>
          <input
            type="number"
            min="0"
            name="total-marks"
            id="total-marks-input"
            
            placeholder="Enter total marks"
            value={totalMarks}
            onChange={(e) => setTotalMarks(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="image-input">Upload Image</Label>
          <Input
            type="file"
            name="image"
            id="image-input"
            
            onChange={handleFileChange}
          />
        </FormGroup>
        <div className="inz-button">
          <button
            className="inz-save-button"
            type="button"
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </Form>
    </div>
  );
}
export default CreateLessonProject;
