import React, { useState,useContext,useEffect } from 'react'

import StudentNav from '../Navbars/StudentNav'
import AdminNav from '../Navbars/AdminNav'
import TeacherNav from '../Navbars/TeacherNav';
import GuestNav from '../Navbars/GuestNav';
import { UserContext } from '../UserContext';
import './PolymathKidSchool.css'
import Footer from '../Footer'
import RD1 from '../CourseImages/Roadmap1.png'
import RD2 from '../CourseImages/Roadmap2.png'
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import { Link } from "react-router-dom";

const PolymathKidSchool = ({selectedCourse}) => {
  
  const handleCourseSelect = (course) => {
    console.log("Selected Course in Home:", course);
    selectedCourse(course);
  };

 const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
 
    useEffect(() => {
      const fetchData = async () => {
       await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
          credentials: 'include',
        }).then(response => {
            console.log("Response:",response);
          response.json().then(userInfo => {
            console.log("userinfo:",userInfo);
            setUserInfo(userInfo);

            if(userInfo?.usertype)
            {
              const uType=userInfo?.usertype;
              setUserRole(uType);
            }
          const uname = userInfo?.username;
          const avatar = userInfo?.avatar; // Store the avatar in a separate variable
          console.log("Uname: ", uname);
          console.log("Avatar: ", avatar);

          })
          .catch(err=> console.log("Error in useeffect:",err))
        });
      }
      fetchData();
    }, [isAdmin, isTeacher, isStudent, isGuest]);
  const determineUserRole = (usertype) => {
        console.log("In home.js:",usertype)
        if(usertype === 'Student')
          return "Student";
        else if(usertype === 'Teacher')
          return "Teacher";
        else if(usertype === 'Admin')
          return "Admin";
        else
          return "guest";
  };
  
  
 const navigate=useNavigate();
  const onLogin = (usertype) => {
    const userRole = determineUserRole(usertype);
    console.log("Calling setUserRole.")
    setUserRole(userRole);
  };

  function onLogout() {
      console.log("inside Onlogout.");
      fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
        credentials: 'include',
        method: 'POST',
      });
      setUserInfo(null);
      setUserRole('guest');
    }
      
      const username = userInfo?.username;
      const _avatar_ = userInfo?.avatar;
   
  return (
    <>
       {/* Navbar */}
       {username && (
        <>
        {isAdmin && <AdminNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
        {isTeacher && <TeacherNav  username={username} avatar={_avatar_} onLogout={onLogout}/>}
        {isStudent && <StudentNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
      </>
        )}  
        {!username && (
          <>
          {isGuest && <GuestNav onLogin={onLogin} />}

          </>
        )}


     <div className='School'>
    <h1 className='mainHeading'>POLYMATH-KIDS @ SCHOOLS</h1>
    <div className='innerVision'>

        
        <p className='para'>Polymath-Kids is the best platform for kids where they can learn 21st Century life skills in a structured and fun learning environment. Polymath-Kids directly collaborate with well reputed schools to provide valuable training for students. We provide the preeminent learning experience with SMART Training Portal that helps to boost the creativity, problem solving skills, ICT Literacy, mental calculation ability and overall cognitive skills in kids.

        </p> 
        <br/>
        <br/>

        <p className='para'>We facilitate schools by offering two modes of teaching 21st Century Life Skills at schools:
        <br />
        <p>
        1. Physical Mode / Online Mode with school teachers
        <br />
        2. Online Mode with Polymath-Kids instructors

        </p>
    
        </p>
        <br/>
        <p className='para'>  <p className='para'>Polymath-Kids Programs can be introduced to students as part of their school curriculum or as an after-school activity. It can help students by improving their cognitive, leadership, social and people skills, concentration and memory power. The use of our program in schools can help by boosting confidence and problem solving ability. We offer two modes of classes for Schools.
        </p>
        </p>
    </div>
   
 
<div className='classesModePics'>

    <img src={RD1} alt="RoadMap" className='roadMapPic1'/>
    <img src={RD2} alt="RoadMap" className='roadMapPic2'/>
</div>

  <div className='roadMapContactButton'>
    <Link to="/contact_us" className='portalBtn' style={{backgroundColor:"#03b4c6",padding:'1% 5% 1% 5%'}}>
    Contact For more information
    </Link>
  </div>

   </div>

    
     {/* Footer */}
     <Footer/>
    </>
  )
}

export default PolymathKidSchool
