// import "./App.css";
import React from "react";
import PDF from './PDF';
import { PDFDownloadLink } from "@react-pdf/renderer";
// import ImagePicker from "./components/ImagePicker";

const AB = () => {
  return (
    <div>
      <PDFDownloadLink document={<PDF />} filename="FORM">
      {({loading}) => (loading ? <button>Loading Document...</button> : <button>Download</button> )}
      </PDFDownloadLink>
    </div>
  )
}

export default AB
