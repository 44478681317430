import React, { useState, useRef, useEffect } from "react";
import "./managecontentstyle/AddActivityQuestion.css";
import axiosInstance from "./axiosInstance";
import { toast } from "react-toastify";
import "./managecontentstyle/buttonstyle.css";
import { FaEdit, FaTrash, FaCopy, FaPlus } from "react-icons/fa";

function EditActivityQuestion({ questionob, handleClose }) {
    const [formData, setFormData] = useState({
      Question_id: questionob.Question_id,
      question: questionob.question,
      answer: questionob.correctanswer,
      option1: questionob.option1,
      option2: questionob.option2,
      option3: questionob.option3,
      option4: questionob.option4,
      timeAllowed: questionob.timealloted,
      voiceRecording: null,
      fileUploaded: null,
      difficultyLevel: questionob.difficultyLevel,
      marks: questionob.marks,
      instruction: questionob.instructions,
    });

    const handleChange = (e) => {
      const { name, value, type, files } = e.target;

      setFormData((prevData) => ({
        ...prevData,
        [name]: type === "file" ? files[0] : value,
      }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();
        Object.entries(formData).forEach(([key, value]) => {
          formDataToSend.append(key, value);
        });

        axiosInstance.post('/api/managecourse/updateLessonActivityQuestion', formDataToSend)
          .then(response => {
            console.log(response.data);
            setFormData('');
            toast.success("Lesson Activity Question has been Updated", {
              position: toast.POSITION.TOP_RIGHT
            });
            handleClose();
          })
          .catch(error => {
            console.log(error);
          });

      };

    return (
      <div className="edit-question-dialog">

        <h4 className="inz-title">Edit Activity Question</h4>
        <form onSubmit={handleSubmit}>
          <div className="form-field">
            <label className="label">Question:</label>
            <input
              type="text"
              name="question"
              value={formData.question}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-field">
            <label className="label">Answer:</label>
            <input
              type="text"
              name="answer"
              value={formData.answer}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-field">
            <label className="label">Difficulty Level:</label>
            <select
              name="difficultyLevel"
              value={formData.difficultyLevel}
              className="inz-dropdown"
              onChange={handleChange}
              required
            >
              <option value="">Select Difficulty Level</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </select>
          </div>

          <div className="form-field">
            <label className="label">Marks:</label>
            <input
              type="number"
              min="0"
              name="marks"
              className="input-question"
              value={formData.marks}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-field">
            <label className="label">Instruction:</label>
            <input
              name="instruction"
              className="input-question"
              value={formData.instruction}
              onChange={handleChange}
            ></input>
          </div>

          <div className="form-field">
            <label className="label">Option 1:</label>
            <input
              type="text"
              name="option1"
              value={formData.option1}
              onChange={handleChange}
            />
          </div>

          <div className="form-field">
            <label className="label">Option 2:</label>
            <input
              className="input-question"
              type="text"
              name="option2"
              value={formData.option2}
              onChange={handleChange}
            />
          </div>

          <div className="form-field">
            <label className="label">Option 3:</label>
            <input
              className="input-question"
              type="text"
              name="option3"
              value={formData.option3}
              onChange={handleChange}
            />
          </div>

          <div className="form-field">
            <label className="label">Option 4:</label>
            <input
              className="input-question"
              type="text"
              name="option4"
              value={formData.option4}
              onChange={handleChange}
            />
          </div>

          <div className="form-field">
            <label className="label">Time Allowed:</label>
            <input
              className="input-question"
              type="number"
              min="0"
              name="timeAllowed"
              value={formData.timeAllowed}
              onChange={handleChange}
              
            />
          </div>

          <div className="form-field">
            <label className="label">Voice Recording Uploaded:</label>
            <input
              className="input-question"
              type="file"
              name="voiceRecording"
              onChange={handleChange}
            />
          </div>

          <div className="form-field">
            <label className="label">File Uploaded:</label>
            <input
              className="input-question"
              type="file"
              name="fileUploaded"
              onChange={handleChange}
            />
          </div>

          <div className="inz-button-row">
            <button type="submit" className="inz-save-button">
              Save
            </button>
            <button
              type="button"
              className="inz-deletesp-button"
              onClick={handleClose}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    );
}

export default EditActivityQuestion;
