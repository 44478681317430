import React, { useState, useEffect, useRef } from "react";
import axiosInstance from "./axiosInstance";
import "./managecontentstyle/ManageLessonActivities.css";
import { toast } from "react-toastify";
import EditLessonActivity from "./EditLessonActivity";
import { FormGroup, Label, Input, Button } from "reactstrap";
import AddActivityQuestion from "./AddActivityQuestion";
import { FaEdit, FaTrash, FaCopy, FaPlus } from "react-icons/fa";
import videoImage from "../images/video.png";
import quizImage from "../images/quiz.png";
import gameImage from "../images/game.png";
import oralImage from "../images/oral.png";
import mentalImage from "../images/mental.png";
import mcqImage from "../images/mcq.png";
import writtenImage from "../images/written.png";

const CopyActivity = ({ activity, handleCopyClose }) => {
  const [course, setCourse] = useState("");
  const [module, setModule] = useState("");
  const [lesson, setLesson] = useState("");
  const [courses, setCourses] = useState([]);
  const [modules, setModules] = useState([]);
  const [lessons, setLessons] = useState([]);

  useEffect(() => {
    fetchCourses();
  }, []);

  const fetchCourses = () => {
    axiosInstance
      .post("/api/managecontent/getAllCourses")
      .then((response) => {
        const sortedCourses = response.data.slice().sort((a, b) => {
          const coursenameA = a.coursename;
          const coursenameB = b.coursename;
          return coursenameA.localeCompare(coursenameB);
        });

        setCourses(sortedCourses);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchModules = (courseId) => {
    axiosInstance
      .post("/api/managecontent/getAllModule", { courseId })
      .then((response) => {
        const filteredModules = response.data.filter(
          (module) => module.Course_id === parseInt(courseId, 10)
        );
        setModules(filteredModules);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchLessons = (moduleId) => {
    axiosInstance
      .post("/api/managecontent/getAllLesson", { moduleId })
      .then((response) => {
        const filteredLessons = response.data.filter(
          (lesson) => lesson.Module_id === parseInt(moduleId)
        );
        setLessons(filteredLessons);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCourseSelect = (courseId) => {
    setCourse(courseId);
    fetchModules(courseId);
  };

  const handleModuleSelect = (moduleId) => {
    setModule(moduleId);
    fetchLessons(moduleId);
  };

  const handleLessonSelect = (lessonId) => {
    setLesson(lessonId);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      Lesson_id: lesson,
      ActivityDescription: activity.ActivityDescription,
      ActivityLink: activity.ActivityLink,
      ActivityName: activity.ActivityName,
      ActivityType: activity.ActivityType,
      CompletionDate: activity.CompletionDate,
      Instructions: activity.Instructions,
      OrderBy: activity.OrderBy,
      TotalTime: activity.TotalTime,
      Remarks: activity.Remarks,
      Score: activity.Score,
    };

    axiosInstance
      .post("/api/managecontent/addLessonActivity", payload)
      .then((response) => {
        setCourse("");
        setModule("");
        setLesson("");
        toast.success("Lesson Activity has been Copied to Selected Lesson", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="modalStyle">
      <h2>Copy Lesson Activity</h2>
      <FormGroup>
        <Label for="course-select">Course</Label>
        <Input
          type="select"
          name="select"
          id="course-select"
          value={course}
          required
          onChange={(event) => handleCourseSelect(event.target.value)}
        >
          <option>Select a course</option>
          {courses.map((course) => (
            <option key={course.id} value={course.Course_id}>
              {course.coursename}
            </option>
          ))}
        </Input>
      </FormGroup>
      <FormGroup>
        <Label for="module-select">Module</Label>
        <Input
          type="select"
          name="select"
          id="module-select"
          value={module}
          required
          onChange={(event) => handleModuleSelect(event.target.value)}
        >
          <option>Select a Module</option>
          {modules.map((module) => (
            <option key={module.id} value={module.Module_id}>
              {module.modulename}
            </option>
          ))}
        </Input>
      </FormGroup>
      <FormGroup>
        <Label for="lesson-select">Lesson</Label>
        <Input
          type="select"
          name="select"
          id="lesson-select"
          value={lesson}
          required
          onChange={(event) => handleLessonSelect(event.target.value)}
        >
          <option>Select a Lesson</option>
          {lessons.map((lesson) => (
            <option key={lesson.id} value={lesson.Lesson_id}>
              {lesson.lessonname}
            </option>
          ))}
        </Input>
      </FormGroup>
      <div className="">
        <Button
          variant="primary"
          onClick={handleSubmit}
          className="save-button"
        >
          Save
        </Button>
        <Button
          variant="primary"
          onClick={handleCopyClose}
          className="save-button twobutton"
        >
          Close
        </Button>
      </div>
    </div>
  );
};

const ActivityCard = ({ activity, handleDelete, handleEdit, handleAssign }) => {
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isCopyActivityOpen, setCopyActivityOpen] = useState(false);
  const [isAddQuestionActive, setAddQuestion] = useState(false);
  const modalRef = useRef(null);

  const handleCopyActivity = () => {
    setCopyActivityOpen(true);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeEditModal();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const openEditModal = () => {
    setEditModalOpen(true);
  };

  const closeEditModal = () => {
    setEditModalOpen(false);
  };

  const handleAddQuestion = () => {
    setAddQuestion(true);
  };

  const getActivityImage = (activityType) => {
    const images = {
      "Oral": oralImage,
      "Video": videoImage,
      "Speed Quiz": quizImage,
      "Written": writtenImage,
      "Mental": mentalImage,
      "Game": gameImage,
      "MCQ": mcqImage,
    };
    return images[activityType] || null;
  };

  return (
    <div
      className="activity-card"
      key={activity.Lesson_Activity_id}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div style={{ flex: "0 0 20%" }}>
        <img
          src={getActivityImage(activity.ActivityType)}
          alt={activity.ActivityType}
          style={{ width: "50px", height: "50px" }}
        />
      </div>
      <div style={{ flex: "0 0 50%", marginLeft: "5px" }}>
        {activity.ActivityName}
      </div>
      <div
        className="icons-container"
        style={{ flex: "0 0 30%", cursor: "pointer" }}
      >
        {activity.ActivityType !== "Game Activity" &&
          activity.ActivityType !== "Video Activity" && (
            <>
              <FaPlus
                className="add-icon"
                onClick={() => setAddQuestion(true)}
              />
            </>
          )}
           <FaEdit className="edit-icon" onClick={openEditModal} />
        <FaTrash
          className="delete-icon"
          onClick={() => handleDelete(activity.Lesson_Activity_id)}
        />
        <FaCopy className="assign-icon" onClick={handleCopyActivity} />
      </div>

      {isEditModalOpen && (
        <div className="" ref={modalRef}>
          <div className="">
            <EditLessonActivity
              activity={activity}
              handleClose={closeEditModal}
            />
          </div>
        </div>
      )}

      {isCopyActivityOpen && (
        <div className="">
          <CopyActivity
            activity={activity}
            handleCopyClose={() => setCopyActivityOpen(false)}
          />
        </div>
      )}

      {isAddQuestionActive && (
        <div className="">
          <AddActivityQuestion
            activityID={activity.Lesson_Activity_id}
            handleClose={() => setAddQuestion(false)}
          />
        </div>
      )}
    </div>
  );
};

const ManageLessonActivities = (props) => {
  const [activities, setActivities] = useState([]);

  const lesson = props.lessonid;

  useEffect(() => {
    fetchLessonActivities();
  }, []);

  const fetchLessonActivities = () => {
    axiosInstance
      .post("/api/managecourse/getLessonActivities", { lesson })
      .then((response) => {
        setActivities(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [isAssignFormOpen, setIsAssignFormOpen] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedModule, setSelectedModule] = useState("");
  const [selectedLesson, setSelectedLesson] = useState("");

  const [newActivityName, setNewActivityName] = useState("");
  const [newActivityDescription, setNewActivityDescription] = useState("");

  const [courses, setCourses] = useState([]);
  const [modules, setModules] = useState([]);
  const [lessons, setLessons] = useState([]);

  useEffect(() => {
    fetchCourses();
  }, []);

  const fetchCourses = () => {
    axiosInstance
      .post("/api/managecontent/getAllCourses")
      .then((response) => {
        const sortedCourses = response.data.slice().sort((a, b) => {
          const coursenameA = a.coursename;
          const coursenameB = b.coursename;
          return coursenameA.localeCompare(coursenameB);
        });

        setCourses(sortedCourses);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchModules = (courseId) => {
    axiosInstance
      .post("/api/managecontent/getAllModule", { courseId })
      .then((response) => {
        const filteredModules = response.data.filter(
          (module) => module.Course_id === parseInt(courseId, 10)
        );
        setModules(filteredModules);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchLessons = (moduleId) => {
    axiosInstance
      .post("/api/managecontent/getAllLesson", { moduleId })
      .then((response) => {
        const filteredLessons = response.data.filter(
          (lesson) => lesson.Module_id === parseInt(moduleId)
        );
        setLessons(filteredLessons);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCourseSelect = (courseId) => {
    setCourses(courseId);
    fetchModules(courseId);
  };

  const handleModuleSelect = (moduleId) => {
    setModules(moduleId);
    fetchLessons(moduleId);
  };

  const handleLessonSelect = (lessonId) => {
    setLessons(lessonId);
  };

  const handleEdit = (activityId) => {
    const activityToEdit = activities.find(
      (activity) => activity.Lesson_Activity_id === activityId
    );
    if (activityToEdit) {
      setNewActivityName(activityToEdit.name);
      setNewActivityDescription(activityToEdit.description);
      setSelectedActivity(activityId);
      setSelectedCourse("");
      setSelectedModule("");
      setSelectedLesson("");
      setIsAssignFormOpen(true);
    }
  };

  const handleAdd = () => {
    setSelectedActivity(null);
    setIsAssignFormOpen(true);
  };

  const handleDelete = (activityId) => {
    setActivities(
      activities.filter(
        (activity) => activity.Lesson_Activity_id !== activityId
      )
    );
    const payload = { Lesson_Activity_id: activityId };
    axiosInstance
      .post("/api/managecourse/deleteLessonActivity", payload)
      .then(() => {
        toast.success("Lesson Activity Deleted", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((error) => {
        console.log("Internal server error", error);
      });
  };

  const handleAssign = (activityId) => {
    setSelectedActivity(activityId);
    setSelectedCourse("");
    setSelectedModule("");
    setSelectedLesson("");
    setIsAssignFormOpen(true);
  };

  const handleSaveAssign = () => {
    // Handle save assign functionality
    setIsAssignFormOpen(false);
    setSelectedActivity(null);
    setSelectedCourse("");
    setSelectedModule("");
    setSelectedLesson("");
  };

  const handleSaveActivity = () => {
    if (selectedActivity) {
      // Edit existing activity
      setActivities((prevActivities) =>
        prevActivities.map((activity) => {
          if (activity.Lesson_Activity_id === selectedActivity) {
            return {
              ...activity,
              name: newActivityName,
              description: newActivityDescription,
            };
          }
          return activity;
        })
      );
    } else {
      // Add new activity
      const newActivity = {
        id: Math.floor(Math.random() * 1000),
        name: newActivityName,
        description: newActivityDescription,
      };
      setActivities((prevActivities) => [...prevActivities, newActivity]);
    }

    setIsAssignFormOpen(false);
    setSelectedActivity(null);
    setNewActivityName("");
    setNewActivityDescription("");
  };

  return (
    <div className="manage-lesson-activities">
      <h1>Manage Lesson Activities</h1>
      <div className="activity-list">
        {activities.map((activity) => (
          <ActivityCard
            key={activity.Lesson_Activity_id}
            activity={activity} // Change from 'activities' to 'activity'
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            handleAssign={handleAssign}
          />
        ))}
      </div>
    </div>
  );
};

export default ManageLessonActivities;
