import "../AdminActions/ManageClasses.css";
import "./RegisterInCourse.css";

import React, { useState, useContext, useEffect } from "react";
import StudentNav from "../Navbars/StudentNav";
import AdminNav from "../Navbars/AdminNav";
import TeacherNav from "../Navbars/TeacherNav";
import GuestNav from "../Navbars/GuestNav";
import { UserContext } from "../UserContext";
import Footer from "../Footer";
import axiosInstance from "../ManageContent/managecontentforms/axiosInstance";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom/dist/umd/react-router-dom.development";

const RegisterInCourse = () => {
  const {
    isAdmin,
    isTeacher,
    isStudent,
    isGuest,
    setUserRole,
    setUserInfo,
    userInfo,
  } = useContext(UserContext);

  useEffect(() => {
    const fetchData = async () => {
      await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
        credentials: "include",
      }).then((response) => {
        console.log("Response:", response);
        response
          .json()
          .then((userInfo) => {
            console.log("userinfo:", userInfo);
            setUserInfo(userInfo);

            if (userInfo?.usertype) {
              const uType = userInfo?.usertype;
              setUserRole(uType);
            }
            const uname = userInfo?.username;
            const avatar = userInfo?.avatar;
            console.log("Uname: ", uname);
            console.log("Avatar: ", avatar);
          })
          .catch((err) => console.log("Error in useeffect:", err));
      });
    };
    fetchData();
  }, [isAdmin, isTeacher, isStudent, isGuest]);
  const determineUserRole = (usertype) => {
    console.log("In home.js:", usertype);
    if (usertype === "Student") return "Student";
    else if (usertype === "Teacher") return "Teacher";
    else if (usertype === "Admin") return "Admin";
    else return "guest";
  };

  const navigate = useNavigate();
  const onLogin = (usertype) => {
    const userRole = determineUserRole(usertype);
    console.log("Calling setUserRole.");
    setUserRole(userRole);
  };

  async function onLogout() {
    console.log("inside Onlogout.");
    await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
      credentials: "include",
      method: "POST",
    });
    setUserInfo(null);
    setUserRole("guest");
    navigate("/");
  }

  const username = userInfo?.username;
  const _avatar_ = userInfo?.avatar;
  const [courseName, setCourseName] = useState("");
  const [courseType, setCourseType] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [courses, setCourses] = useState([]);
  const [discountCode, setDiscountCode] = useState("");

  const handleCourseNameChange = (e) => {
    setCourseName(e.target.value);
  };

  const [studentid, setStudentid] = useState();
  const [userData, setUserData] = useState({});

  const fetchCourses = (studentid) => {
    axiosInstance
      .post(`/api/studentcourseregisteration/getUnregisterCourse?studentId=${studentid}`)
      .then((response) => {
        const sortedCourses = response.data.slice().sort((a, b) => {
          const coursenameA = a.coursename;
          const coursenameB = b.coursename;
          return coursenameA.localeCompare(coursenameB);
        });
        console.error("API Course Sucess");
        setCourses(sortedCourses);
      })
      .catch((error) => {
        console.error("API Course Error: ",error);
        console.log(error);
      });
  };



  useEffect(() => {
    axiosInstance
      .get("/", { withCredentials: true })
      .then((response) => {
        setUserData(response.data);

        axiosInstance
          .post("/getStudentIDfromUser", {
            UserId: response.data.User_id,
          })
          .then((response) => {
            const data = response.data[0].Student_id;
            console.log("Student ID: ", data);
            setStudentid(data);
            fetchCourses(data);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log("Error fetching data: ", error);
      });
  }, []);



  const handleCourseTypeChange = (e) => {
    setCourseType(e.target.value);
  };

  const handlePaymentTypeChange = (e) => {
    setPaymentType(e.target.value);
  };

  const handleSlotSelection = (slot) => {
    const updatedSlots = [...selectedSlots];
    const index = updatedSlots.indexOf(slot);

    if (index > -1) {
      updatedSlots.splice(index, 1);
    } else {
      updatedSlots.push(slot);
    }

    setSelectedSlots(updatedSlots);
  };

  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const times = ["10am-12pm", "12pm-3pm", "3pm-6pm", "6pm-9pm"];
  const [selectedSlots, setSelectedSlots] = useState([]);


  const handleRegistration = () => {
    const courseid1 = courses.find(
      (c) => c.coursename === courseName
    )?.Course_id;

    const discountload = {
      discountCode: discountCode,
    };

    if (courseid1) {
      if (discountCode && studentid) {
        axiosInstance
          .post("/getdiscountid", discountload)
          .then((response) => {
            if (response.data.discountId) {
              toast.success("Coupon Applied", {
                position: toast.POSITION.TOP_RIGHT,
              });

              const payload = {
                Course_id: courseid1,
                Student_id: studentid,
                ClassType: courseType,
                Currency: "PKR",
                Discount_id: response.data.discountId,
                PaymentType: paymentType,
                SchedulePreferenceDay: selectedSlots[1],
                SchedulePreferenceTime: selectedSlots[0],
                StatusComplete: 0,
              };

              axiosInstance
                .post("/registerincourse", payload)
                .then((response) => {
                  toast.success("Student Register Successfully", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                  setCourseName("");
                  setCourseType("");
                  setPaymentType("");
                  setDiscountCode("");
                  const course_val = document.getElementById("courselist");
                  course_val.value = "";
                  const divElement = document.getElementById("dayslot");
                  const checkboxes = divElement.querySelectorAll(
                    'input[type="checkbox"]'
                  );

                  checkboxes.forEach((checkbox) => {
                    checkbox.checked = false;
                  });
                  document.getElementById("timeslot").checked = false;
                })
                .catch((error) => {
                  console.error("Error registering: ", error);
                });
            } else if (response.data.error) {
              toast.error("No such Discount Coupon", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          })
          .catch((error) => {
            console.error("Error in getdiscountid: ", error);
          });
      } else if(studentid){
        const payload = {
          Course_id: courseid1,
          Student_id: studentid,
          ClassType: courseType,
          Currency: "PKR",
          Discount_id: null,
          PaymentType: paymentType,
          SchedulePreferenceDay: selectedSlots[1],
          SchedulePreferenceTime: selectedSlots[0],
          StatusComplete: 0,
        };
        axiosInstance
          .post("/registerincourse", payload)
          .then((response) => {
            toast.success("Student Register Successfully", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setCourseType("");
            setPaymentType("");
            setDiscountCode("");
            const course_val = document.getElementById("courselist");
            course_val.value = "";
            const divElement = document.getElementById("dayslot");
            const checkboxes = divElement.querySelectorAll(
              'input[type="checkbox"]'
            );

            checkboxes.forEach((checkbox) => {
              checkbox.checked = false;
            });
            document.getElementById("timeslot").checked = false;
          })
          .catch((error) => {
            console.error("Error registering: ", error);
          });
      }
    } else {
      toast.error("Select Course", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <>
      {/* Navbar */}
      {username && (
        <>
        {isAdmin && <AdminNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
        {isTeacher && <TeacherNav  username={username} avatar={_avatar_} onLogout={onLogout}/>}
        {isStudent && <StudentNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
      </>
      )} 
      {!username && <>{isGuest && <GuestNav onLogin={onLogin} />}</>}

      <h3 className="ManageClassHeading">Register In Course</h3>
      <div
        className="ManageClassesFormDiv"
        style={{ borderRadius: "2vh", backgroundColor: "#34979c" }}
      >
        <form className="ManageClassesForm" style={{ width: "65%" }}>
          <select
            id="courselist"
            className="ManageClassDropdown"
            style={{ margin: "2%" }}
            value={courseName}
            onChange={handleCourseNameChange}
          >
            <option>Select a course</option>
            {courses.map((course, index) => (
              <option key={index} value={course.courseId}>
                {course.coursename}
              </option>
            ))}
          </select>

          <select
            className="ManageClassDropdown"
            style={{ margin: "2%" }}
            value={courseType}
            onChange={handleCourseTypeChange}
          >
            <option>Select a CourseType</option>
            <option value="Group">Group</option>
            <option value="One-on-One">One-on-One</option>
          </select>

          <select
            className="ManageClassDropdown"
            style={{ margin: "2%" }}
            value={paymentType}
            onChange={handlePaymentTypeChange}
          >
            <option>Select a PaymentType</option>
            <option value="Upfront">Upfront</option>
            <option value="Monthly">Monthly</option>
          </select>
        </form>
      </div>

      <div  id="dayslot" className="RegisterInCoursePreferences">
        <div className="days">
          {days.map((day) => (
            <div
              key={day}
              className="RegisterInCoursePreferencesDays"
            >
              <input
                type="checkbox"
                checked={selectedSlots.includes(day)}
                onChange={() => handleSlotSelection(day)}
              />
              <label>{day}</label>
            </div>
          ))}
        </div>
        <div className="Times">
          {times.map((time) => (
            <div key={time} className="RegisterInCoursePreferencesTimes">
              <input
                type="checkbox"
                id="timeslot"
                checked={selectedSlots.includes(time)}
                onChange={() => handleSlotSelection(time)}
              />

              <label>{time}</label>
            </div>
          ))}
        </div>
      </div>

      <div className="PaymentMessage">
        {paymentType === "Upfront" && (
          <div
            style={{ fontWeight: "bold", fontSize: "3vh", textAlign: "center" }}
          >
            <p style={{ color: "red" }}>
              <del>PKR 20000 / course </del>
            </p>
            <p>PKR 20000/ course % Off</p> <br />
            OR
            <p>Use Monthly Payment Type to avail easy Monthly Payments</p>
          </div>
        )}

        {paymentType === "Monthly" && (
          <div
            style={{ fontWeight: "bold", fontSize: "3vh", textAlign: "center" }}
          >
            <p>PKR / Lessons</p> <br />
            OR <br />
            <p>Use Upfront Payment Type to avail DISCOUNT</p>
          </div>
        )}
      </div>

      <div className="DiscountCode">
        <input
          type="text"
          placeholder="Enter Discount Code"
          className="DiscountInputCode"
          value={discountCode}
          onChange={(e) => setDiscountCode(e.target.value)}
        />
        <button
          className="ManageClassCreateButton RegisterCourseButton"
          onClick={handleRegistration}
        >
          Register
        </button>
      </div>

      <Footer />
    </>
  );
};

export default RegisterInCourse;
