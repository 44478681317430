import React, { useState, useContext, useEffect } from 'react';
import StudentNav from '../Navbars/StudentNav';
import AdminNav from '../Navbars/AdminNav';
import TeacherNav from '../Navbars/TeacherNav';
import GuestNav from '../Navbars/GuestNav'; 
import Footer from '../Footer';
import { useParams } from 'react-router-dom';
import './CreateCertificate.css';
import { UserContext } from '../UserContext';
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import { PDFDownloadLink } from "@react-pdf/renderer";
import PDF from './PDF';


const CreateCertificate = () => {
  const { courseId } = useParams();
  const [courseName, setCourseName] = useState('');
  const [students, setStudents] = useState([]);
  const [selectedStudentDetails, setSelectedStudentDetails] = useState(null);

  useEffect(() => {
    fetchCourseName();
    fetchStudents();

  }, [courseId]);

    const fetchCourseName = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/PDFCertificate_coursename/${courseId}`);
        setCourseName(response.data.coursename);
      } catch (error) {
        console.error('Error fetching course name:', error);
      }
    };
    const fetchStudents = async () => {
        if (courseId) {
          try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/students_by_course/${courseId}`);
            setStudents(response.data);
          } catch (error) {
            console.error('Error fetching students:', error);
          }
        } else {
          setStudents([]);
        }
    };

    const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
    };
    // Handle student selection
    const handleStudentChange = (event) => {
        const studentId = event.target.value;
        const selectedStudent = students.find(student => student.User_id.toString() === studentId);
    
        setSelectedStudentDetails(selectedStudent ? {
          firstname: selectedStudent.firstname,
          lastname: selectedStudent.lastname,
          parentname: selectedStudent.parentname,
          startDate: formatDate(selectedStudent.StartDate),
          endDate: formatDate(selectedStudent.EndDate)
        } : null);
      };
    
  const generateCertificate = () => {
    // Initialize jsPDF
    const doc = new jsPDF();

    // Add image to the PDF (adjust coordinates and size as needed)
    const img = new Image();
    img.src = 'Image1.jpeg';  
    doc.addImage(img, 'JPEG', 15, 40, 180, 120);

    
    doc.setFontSize(22);
    doc.text(20, 20, `Certificate for ${courseName}`);

    // Save the PDF file with course name as filename
    doc.save(`${courseName}_certificate.pdf`);
  };

    const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
    useEffect(() => {
      const fetchData = async () => {
       await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
          credentials: 'include',
        }).then(response => {
           //  console.log("Response:",response);
          response.json().then(userInfo => {
           //  console.log("userinfo:",userInfo);
            setUserInfo(userInfo);
            if(userInfo?.usertype)
            {
              const uType=userInfo?.usertype;
              setUserRole(uType);
            }
          const uname = userInfo?.username;
          const avatar = userInfo?.avatar; // Store the avatar in a separate variable
         //  console.log("Uname Admin Register", uname);
         //  console.log("Avatar in: ", avatar);
   
          })
          .catch(err=> console.log("Error in useeffect:",err))
        });
      }
      fetchData();
    }, [isAdmin, isTeacher, isStudent, isGuest]);
   
    const onLogin = (usertype) => {
         
        setUserRole(usertype);
      };
    const navigate=useNavigate();
    
    async function onLogout() {
    await  fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
        credentials: 'include',
        method: 'POST',
        });
        setUserInfo(null);
        console.log("navigating");
        navigate('/');
        // console.log("navigated");
        setUserRole('guest');
    }

    const username = userInfo?.username;
    const _avatar_ = userInfo?.avatar;

  return (
    <>

        {username && (
        <>
        {isAdmin && <AdminNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
        {isTeacher && <TeacherNav  username={username} avatar={_avatar_} onLogout={onLogout}/>}
        {isStudent && <StudentNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
      </>
        )}  
        {!username && (
          <>
          {isGuest && <GuestNav onLogin={onLogin} />}
          </>
        )}

        <div className='edit-bar'>
            <h3 className='HeadingPDFcert'>
                Generate Certificate
            </h3>
            <br />
            <h2 className='coursenamePDFcert'>
                {courseName}
            </h2>
        </div>
      
        
      {students.length > 0 && (
        <div className='TeacherFilterSection'>
          <div className='TeacherFilter'>
            <select id='TPcourseDropdown' onChange={handleStudentChange}>
              <option value=''>Select Student</option>
              {students.map((student) => (
                <option key={student.User_id} value={student.User_id}>
                  {student.firstname} {student.lastname}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}

      
      {selectedStudentDetails && (
        <div className='StudentDetails'>
          <h3>Student Details</h3>
          <table className='StudentTable'>
            <tbody>
              <tr>
                <th>First Name</th>
                <td>{selectedStudentDetails.firstname}</td>
              </tr>
              <tr>
                <th>Last Name</th>
                <td>{selectedStudentDetails.lastname}</td>
              </tr>
              <tr>
                <th>Parent Name</th>
                <td>{selectedStudentDetails.parentname}</td>
              </tr>
              <tr>
                <th>Start Date</th>
                <td>{selectedStudentDetails.startDate}</td>
              </tr>
              <tr>
                <th>End Date</th>
                <td>{selectedStudentDetails.endDate}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

        <div className='GenSalary'>
            {/* <button className='SalaryBtn' onClick={generateCertificate} >Generate Salary</button> */}
            <PDFDownloadLink document={<PDF />} filename="FORM" >
                {({loading}) => (loading ? <button>Loading Document...</button> : <button className='SalaryBtn'>Download Certificate</button> )}
            </PDFDownloadLink>
        </div>

        {/* Footer */}
      <Footer/>

    </>
  );
}

export default CreateCertificate;
