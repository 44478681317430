import React, { useState } from 'react';
import './EditFeePopup.css';

function EditFeePopup({ entry, courses, onClose, onSave }) {
  const [updatedEntry, setUpdatedEntry] = useState({ ...entry });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUpdatedEntry((prevEntry) => ({
      ...prevEntry,
      [name]: value,
    }));
  };

  const handleSave = () => {
    const formattedEntry = {
      Config_id: updatedEntry.Config_id,  // Ensure this is included
      Course_id: updatedEntry.Course_id,
      GroupMonthly: parseInt(updatedEntry.GroupMonthly, 10),
      GroupUpfront: parseInt(updatedEntry.GroupUpfront, 10),
      NoOfLessonsMax: parseInt(updatedEntry.NoOfLessonsMax, 10),
      NoOfLessonsMin: parseInt(updatedEntry.NoOfLessonsMin, 10),
      OneOnOneMonthly: parseInt(updatedEntry.OneOnOneMonthly, 10),
      OneOnOneUpfront: parseInt(updatedEntry.OneOnOneUpfront, 10),
      feecurrency: updatedEntry.feecurrency,
    };
    console.log("Saving entry:", formattedEntry); // Debugging line
    if (!formattedEntry.Config_id) {
      console.error("Config_id is missing or invalid");
      return;
    }
    
    onSave(formattedEntry);
    
  };
  

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h3>Edit Fee Configuration</h3>
        <label>Course</label>
        <select
          id="Course_id"
          name="Course_id"
          value={updatedEntry.Course_id}
          onChange={handleChange}
        >
          {courses.map((course) => (
            <option key={course.Course_id} value={course.Course_id}>
              {course.coursename}
            </option>
          ))}
        </select>

        <label>No of Lessons Min (Monthly)</label>
        <input
          type="number"
          name="NoOfLessonsMin"
          value={updatedEntry.NoOfLessonsMin}
          onChange={handleChange}
        />

        <label>No of Lessons Max (Upfront)</label>
        <input
          type="number"
          name="NoOfLessonsMax"
          value={updatedEntry.NoOfLessonsMax}
          onChange={handleChange}
        />

        <label>Currency</label>
        <select
          id="feecurrency"
          name="feecurrency"
          value={updatedEntry.feecurrency}
          onChange={handleChange}
        >
          <option value="PKR">PKR</option>
          <option value="USD">USD</option>
        </select>

        <label>Group Monthly Fee</label>
        <input
          type="number"
          name="GroupMonthly"
          value={updatedEntry.GroupMonthly}
          onChange={handleChange}
        />

        <label>Group Upfront Fee</label>
        <input
          type="number"
          name="GroupUpfront"
          value={updatedEntry.GroupUpfront}
          onChange={handleChange}
        />

        <label>1 on 1 Monthly Fee</label>
        <input
          type="number"
          name="OneOnOneMonthly"
          value={updatedEntry.OneOnOneMonthly}
          onChange={handleChange}
        />

        <label>1 on 1 Upfront Fee</label>
        <input
          type="number"
          name="OneOnOneUpfront"
          value={updatedEntry.OneOnOneUpfront}
          onChange={handleChange}
        />

        <div className="popup-actions">
          <button onClick={handleSave}>Save</button>
          <button onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
}

export default EditFeePopup;
