import React, { useState, useEffect } from 'react';
import axiosInstance from './axiosInstance';
import './managecontentstyle/ManageLessonPlans.css';
import {toast} from 'react-toastify';
import './managecontentstyle/buttonstyle.css';

const ManageLessonPlans = ({ lessonid }) => {
  const [projects, setProjects] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [updatedName, setUpdatedName] = useState("");
  const [updatedDescription, setUpdatedDescription] = useState("");
  const [updatetime,setupdateTime] = useState("");

  useEffect(() => {
    fetchLessonProject();
  }, []);

  const fetchLessonProject = () => {
    axiosInstance
      .post('/api/managecourse/getLessonPlanNameDescription', { lessonid })
      .then(response => {
        setProjects(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleDelete = (projectId) => {
    const updatedProjects = projects.filter((project) => project.Item_id !== projectId);

    const payload = { Item_id: projectId };

    axiosInstance
      .post('/api/managecouse/deleteLessonPlan', payload)
      .then(() => {
        toast.success("Lesson Plan Deleted",{
          position:toast.POSITION.TOP_RIGHT
        });
      })
      .catch(error => {
        console.log("Internal server error", error);
      });

    setProjects(updatedProjects);
  };


  const handleUpdate = (projectId) => {
    setSelectedProjectId(projectId);
    const selectedProject = projects.find((project) => project.Item_id === projectId);
    setUpdatedName(selectedProject.itemname);
    setUpdatedDescription(selectedProject.itemdetails);
    setupdateTime(selectedProject.itemtime);
  };

  const handleSave = (projectId) => {
    const updatedProjects = projects.map((project) => {
      if (project.Item_id === projectId) {
        return {
          ...project,
          editing: false,
          itemdetails: updatedDescription,
          itemname: updatedName,
          itemtime:updatetime
        };
      }
      return project;
    });

    const selectedProject = updatedProjects.find((project) => project.Item_id === projectId);

    const payload ={
      Item_id: selectedProject.Item_id,
      itemname:selectedProject.itemname,
      itemdetails:selectedProject.itemdetails,
      itemtime:selectedProject.itemtime
    }

    axiosInstance.post('/api/managecourse/updateLessonPlanNameDescription', payload)
      .then(() => {
        toast.success("Lesson Plan Updated",{
          position:toast.POSITION.TOP_RIGHT
        });
      })
      .catch(error => {
        console.log("Internal server error", error);
      });

    setProjects(updatedProjects);
    setSelectedProjectId(null);
  };

  return (
    <div className="manage-lesson-plans">
      <h2>Manage Lesson Plan</h2>
      <div className="project-list">
        {projects.map((project) => (
          <div className="project-card" key={project.Item_id}>
            {selectedProjectId === project.Item_id ? (
              <div className="edit-form">
                <input placeholder='Enter Name' type="text" value={updatedName} onChange={(e) => setUpdatedName(e.target.value)} />
                <textarea  placeholder='Enter description' value={updatedDescription} onChange={(e) => setUpdatedDescription(e.target.value)} />
                <input placeholder='Enter time' type="number"  min="0" value={updatetime} onChange={(e) => setupdateTime(e.target.value)} />
                <button className="inz-save-button" onClick={() => handleSave(project.Item_id)}>Save</button>
              </div>
            ) : (
              <>
                <h3 >{project.itemname}</h3>
                <p >{project.itemdetails}</p>
                <p>Time: <span>{project.itemtime}</span></p>

                <div className="inz-button-row">
                  <button className="inz-update-button" onClick={() => handleUpdate(project.Item_id)}>Update</button>
                  <button className="inz-deletesp-button" onClick={() => handleDelete(project.Item_id)}>Delete</button>
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );

};

export default ManageLessonPlans;
