import React, { useState, useEffect, useContext } from "react";
import TeacherNav from "../Navbars/TeacherNav"; // Import TeacherNav component
import Footer from "../Footer"; // Assuming Footer is also needed
import { UserContext } from "../UserContext"; // Import UserContext for user data
import "./GradeProjectPage.css";

const GradeProjectPage = () => {
  const { userInfo, onLogout } = useContext(UserContext); // Get userInfo and onLogout from UserContext
  const students = [
    { id: 1, name: "Syeda Abeeha Fatima" },
    { id: 2, name: "Rabya Rehman" },
    { id: 3, name: "Saim Ahmed" },
    { id: 4, name: "Mustafa Nabeel" },
    { id: 5, name: "Zainab Gohar" },
    { id: 6, name: "Aarav Singh" },
    { id: 7, name: "Muhammad Abdul Basra Usman" },
    { id: 8, name: "Muhammad Rahim Iqbal" },
  ];

  const [progress, setProgress] = useState(
    students.map(() => ({
      lesson: { selectedProject: "", marks: 0, totalMarks: 0, viewLink: "" },
      module: { selectedProject: "", marks: 0, totalMarks: 0, viewLink: "" },
      complete: false,
    }))
  );

  const lessonProjects = ["Lesson Project 1", "Lesson Project 2", "Lesson Project 3"];
  const moduleProjects = ["Module Project 1", "Module Project 2", "Module Project 3"];

  useEffect(() => {
    const fetchProjectData = async (studentId, index) => {
      const lessonResponse = await fetch(`/api/lessonProject/${studentId}`);
      const moduleResponse = await fetch(`/api/moduleProject/${studentId}`);

      const lessonProject = await lessonResponse.json();
      const moduleProject = await moduleResponse.json();

      const updatedProgress = [...progress];
      updatedProgress[index].lesson.totalMarks = lessonProject.totalMarks;
      updatedProgress[index].lesson.viewLink = lessonProject.viewLink;
      updatedProgress[index].module.totalMarks = moduleProject.totalMarks;
      updatedProgress[index].module.viewLink = moduleProject.viewLink;
      setProgress(updatedProgress);
    };

    students.forEach((student, index) => {
      fetchProjectData(student.id, index);
    });
  }, [students]);

  const handleProjectSelect = (index, type, value) => {
    const updatedProgress = [...progress];
    updatedProgress[index][type].selectedProject = value;
    setProgress(updatedProgress);
  };

  const handleMarksChange = (index, type, value) => {
    const updatedProgress = [...progress];
    updatedProgress[index][type].marks = value;
    setProgress(updatedProgress);
  };

  const handleSave = async (index) => {
    const studentData = {
      studentId: students[index].id,
      lesson: progress[index].lesson,
      module: progress[index].module,
      complete: progress[index].complete,
    };

    try {
      const response = await fetch("/api/saveProjectData", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(studentData),
      });

      if (response.ok) {
        alert(`Data for ${students[index].name} saved successfully!`);
      } else {
        alert(`Failed to save data for ${students[index].name}.`);
      }
    } catch (error) {
      alert(`Error saving data for ${students[index].name}: ${error.message}`);
    }
  };

  return (
    <>
      {/* Teacher Navigation Bar */}
      <TeacherNav username={userInfo?.username} avatar={userInfo?.avatar} onLogout={onLogout} />

      {/* Page Content */}
      <div className="grade-project-page">
        <h1>Scratch Beginner Course</h1>
        <div className="student-list">
          {students.map((student, index) => (
            <div key={index} className="student-row">
              <div className="student-name">{student.name}</div>
              <div className="project-inputs">
                <label>
                  Lesson Project
                  <select
                    value={progress[index].lesson.selectedProject}
                    onChange={(e) => handleProjectSelect(index, "lesson", e.target.value)}
                  >
                    <option value="" disabled>Select Lesson Project</option>
                    {lessonProjects.map((project, i) => (
                      <option key={i} value={project}>{project}</option>
                    ))}
                  </select>
                </label>
                <a href={progress[index].lesson.viewLink} target="_blank" rel="noopener noreferrer">
                  View Project
                </a>
                <input
                  type="number"
                  value={progress[index].lesson.marks}
                  onChange={(e) => handleMarksChange(index, "lesson", e.target.value)}
                  placeholder="Marks"
                />
                <span>/ {progress[index].lesson.totalMarks}</span>
              </div>
              <div className="project-inputs">
                <label>
                  Module Project
                  <select
                    value={progress[index].module.selectedProject}
                    onChange={(e) => handleProjectSelect(index, "module", e.target.value)}
                  >
                    <option value="" disabled>Select Module Project</option>
                    {moduleProjects.map((project, i) => (
                      <option key={i} value={project}>{project}</option>
                    ))}
                  </select>
                </label>
                <a href={progress[index].module.viewLink} target="_blank" rel="noopener noreferrer">
                  View Project
                </a>
                <input
                  type="number"
                  value={progress[index].module.marks}
                  onChange={(e) => handleMarksChange(index, "module", e.target.value)}
                  placeholder="Marks"
                />
                <span>/ {progress[index].module.totalMarks}</span>
              </div>
              <button onClick={() => handleSave(index)}>Save</button>
            </div>
          ))}
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </>
  );
};

export default GradeProjectPage;
