import React, { useState, useContext, useEffect } from "react";

import StudentNav from "../Navbars/StudentNav";
import AdminNav from "../Navbars/AdminNav";
import TeacherNav from "../Navbars/TeacherNav";
import GuestNav from "../Navbars/GuestNav";
import "./CourseRegistration.css";
import Footer from "../Footer";

import { useLocation } from "react-router-dom";
import { UserContext } from "../UserContext";
import { useNavigate } from "react-router-dom/dist/umd/react-router-dom.development";
import axios from "axios";
import { toast } from "react-toastify";



const ChangeTeachersAndClass = () => {
  const [filteredClassIDs, setFilteredClassIDs] = useState([]);
  const [filteredClassIDszzz, setFilteredClassIDszzz] = useState([]);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const user_name = searchParams.get("username");
  const User_id = searchParams.get("User_id");

  console.log("User_id:", User_id);

  console.log("user_name:", user_name);

  const [studentId, setStudentId] = useState(null);

  const handleGetStudentId = async () => {
    console.log("I am innn...");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LOCAL_SERVER_API}/getStudentId/${User_id}`
      );

      if (response.data.success) {
        console.log("Student Id is: ", response.data.Student_id);
        setStudentId(response.data.Student_id);
      } else {
        console.log(response.data.Message);
      }
    } catch (error) {
      console.error("Error retrieving student ID:", error);
    }
  };



  
  // const updateChangesInDatabase = async (studentId, prevClassId, newClassId) => {
  //   console.log("I am upadtng...");
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_LOCAL_SERVER_API}/updateClassForStudent/${studentId}/${prevClassId}/${newClassId}`
  //     );
  //     console.log(response);
  //   } catch (error) {
  //     console.error("Error retrieving student ID:", error);
  //   }
  // };


  const updateChangesInDatabase = async (studentId, prevClassId, newClassId) => {
    console.log("I am updating...");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LOCAL_SERVER_API}/updateClassForStudent/${studentId}/${prevClassId}/${newClassId}`
      );
      console.log(response);
      // Show a toast notification on successful save
      toast.success('Class updated successfully!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      window.location.reload()
    } catch (error) {
      console.error("Error updating class information:", error);
      // Show an error toast notification
      toast.error('Error updating class information', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };



  const [studentClasses, setStudentClasses] = useState(null);

  const getStudentClasses = async () => {
    console.log("I am innn...");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LOCAL_SERVER_API}/getStudentId/${User_id}`
      );

      if (response.data.success) {
        console.log("Student Id is: ", response.data.Student_id);

        const studID = response.data.Student_id;
        setStudentId(response.data.Student_id);


        try {
          console.log("Going in /getClassInfoForAStudent...")
          console.log("Goiing in StudentID is: ", studID)
          const response1 = await axios.get(
            `${process.env.REACT_APP_LOCAL_SERVER_API}/getClassInfoForAStudent/${studID}`
          );
    
            console.log("Student Classes are: ", response1.data.classInfo);
            setStudentClasses(response1.data.classInfo);
            // setStudentId(response.data.Student_id);
        } catch (error) {
          console.error("Error retrieving student ID:", error);
        }
      } else {
        console.log(response.data.Message);
      }
    } catch (error) {
      console.error("Error retrieving student ID:", error);
    }
  };

  // const [discount, setDiscount] = useState(["10% Off", "20% Off", "30% Off"]);

  // const [filteredClassID, setFilteredClassID] = useState(null);



  const handleClassIDChange = (index, selectedClassID) => {

    console.log("Handle Class ID Change: ", selectedClassID);
    const updatedFilteredClassIDs = [...filteredClassIDs];
    const filteredClass = classesData.find((CID) => CID.ClassID === selectedClassID);
    updatedFilteredClassIDs[index] = filteredClass;
    setFilteredClassIDs(updatedFilteredClassIDs);

    console.log("Filtered data: ", filteredClass);
  };

  // const [filteredClassID, setFilteredClassID] = useState(null);

// const handleClassIDChange = (event) => {
//   const selectedClassID = event.target.value;
//   const filteredClass = classesData.find((CID) => CID.ClassID === selectedClassID);
//   setFilteredClassID(filteredClass);
// };

  // const handleClassIDChange = (id, event) => {
  //   const selectedClassID = event.target.value;
  //   setFilteredClassIDs(
  //     filteredClassIDs.map((course) =>
  //       course.id === id ? { ...course, ClassID: selectedClassID } : course
  //     )
  //   );
  // };

  
  const [RegistrationCourses, setRegistrationCourses] = useState([]);
  const [CourseIDs, setCourseIDs] = useState([]);
  const [ClassIds, setClassIDs] = useState([]);

  useEffect(() => {
    fetchRegistrationCourses();
  }, []);
  useEffect(() => {
    fetchClassIDs(CourseIDs);
    // fetchRegistrationCourses();
  }, [RegistrationCourses]);



  useEffect(() => {

    getStudentClasses();

  }, []);

  // const [currentClassID, setCurrentClassID] = useState("")

  const checkInStudent = (index, course_id) => {

    let isInClass = "";
    if(studentClasses != null && classesData != null)
    studentClasses.map((classInfo) => {
      classesData.map((cd) => {

        console.log("I am checking..........")
        if (course_id == classInfo.Course_id && classInfo.Course_id === cd.Course_id && classInfo.Class_id === cd.Class_id) {

          // handleClassIDChange(index, classInfo.ClassID)
          // const selectedClassID = classInfo.ClassID;
          // const updatedFilteredClassIDs = [...filteredClassIDs];
          // const filteredClass = classesData.find((CID) => CID.ClassID === selectedClassID);
          // updatedFilteredClassIDs[index] = filteredClass;
          // setFilteredClassIDszzz(selectedClassID);

          console.log("true ...............")
          isInClass = classInfo.ClassID;
          return;
        }

      })
      
    });

    return isInClass;
  }

  // const checkInStudent2 = (ClassID) => {

  //   let isInClass = false;
  //   if(studentClasses != null)
  //   studentClasses.map((classInfo) => {
  //     console.log("I am checking..........")
  //     if (classInfo.Class_id === ClassID) {
  //       console.log("true ...............")
  //       isInClass = true;
  //       return;
  //     }
  //   });

  //   return isInClass;
  // }

  const [classesData, setClassesData] = useState(null);

  const fetchRegistrationCourses = async () => {
    console.log("I am innn...");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LOCAL_SERVER_API}/getStudentId/${User_id}`
      );

      if (response.data.success) {
        console.log("Student Id is: ", response.data.Student_id);
        setStudentId(response.data.Student_id);

        const sId = response.data.Student_id;

        try {
          console.log("Student in Registration: ", sId);
          const response = await axios.get(
            `${process.env.REACT_APP_LOCAL_SERVER_API}/getRegisteredCoursesOfStudent/${sId}`
          );
          const data = response.data;
          const formattedData = data.registrationCourses.map((courseData) => ({
            ...courseData,
            RegistrationDate: new Date(
              courseData.RegistrationDate
            ).toLocaleDateString("en-US", {
              month: "long",
              day: "numeric",
              year: "numeric",
            }),
          }));
          console.log("Registered Coureses Data: ", formattedData);
          setRegistrationCourses(formattedData);
          setCourseIDs(data.uniqueCourseIDs);
        } catch (error) {
          console.log(error);
        }
      } else {
        console.log(response.data.Message);
      }
    } catch (error) {
      console.error("Error retrieving student ID:", error);
    }
  };
  const fetchClassIDs = async (courseIds) => {
    console.log("I am innn...");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LOCAL_SERVER_API}/getStudentId/${User_id}`
      );

      if (response.data.success) {
        console.log("Student Id is: ", response.data.Student_id);
        setStudentId(response.data.Student_id);

        const stu_id = response.data.Student_id;

        try {
          console.log("in fetchClassIDs");
          const response = await axios.get(
            `${process.env.REACT_APP_LOCAL_SERVER_API}/getClassIDsForAStudentCourses/${stu_id}`
          );
          const data = response.data;

          // console.log("Classes Data: ", response.data);
          console.log("Classes Data: ", response.data.classIDs);
          setClassesData(response.data.classIDs);
          // setClassIDs(data.classIDs);
        } catch (error) {
          console.log(error);
        }
      } else {
        console.log(response.data.Message);
      }
    } catch (error) {
      console.error("Error retrieving student ID:", error);
    }
  };

  const {
    isAdmin,
    isTeacher,
    isStudent,
    isGuest,
    setUserRole,
    setUserInfo,
    userInfo,
  } = useContext(UserContext);
  useEffect(() => {
    const fetchData = async () => {
      await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
        credentials: "include",
      }).then((response) => {
        console.log("Response:", response);
        response
          .json()
          .then((userInfo) => {
            console.log("userinfo:", userInfo);
            setUserInfo(userInfo);

            if (userInfo?.usertype) {
              const uType = userInfo?.usertype;
              setUserRole(uType);
            }
            const uname = userInfo?.username;
            const avatar = userInfo?.avatar; // Store the avatar in a separate variable
            console.log("Uname in user.js: ", uname);
          })
          .catch((err) => console.log("Error in useeffect:", err));
      });
    };
    fetchData();
  }, [isAdmin, isTeacher, isStudent, isGuest]);

  const onLogin = (usertype) => {
    setUserRole(usertype);
  };
  const navigate = useNavigate();
  async function onLogout() {
    console.log("inside Onlogout.");
    await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
      credentials: "include",
      method: "POST",
    });
    setUserInfo(null);
    console.log("navigating");
    navigate("/");
    // console.log("navigated");
    setUserRole("guest");
  }

  const username = userInfo?.username;
  const _avatar_ = userInfo?.avatar;


  const handleSaveChanges = (course_id) => {
    console.log('Saving changes for courseId:', course_id);
    
    let isInClass = false;
    let isPresent = false;
    if(studentClasses != null && classesData != null)
    studentClasses.map((classInfo) => {
      classesData.map((cd) => {

        console.log("I am checking..........")
        if (course_id == classInfo.Course_id && classInfo.Course_id === cd.Course_id && classInfo.Class_id === cd.Class_id) {
          console.log("true ...............", cd.Class_id)

          filteredClassIDs.map((fc) =>{
            if(fc.Course_id == course_id){
              console.log("Yesss ...............", fc.Class_id)

              updateChangesInDatabase(studentId, cd.Class_id, fc.Class_id);
              isInClass = true;
              isPresent = true;

            }
          })
          // isInClass = classInfo.ClassID;
          return;
        }

      })
      
    });

    
    if(isInClass == false){
      filteredClassIDs.map((fc) =>{
        if(fc.Course_id == course_id){
          console.log("Nooooo ...............", fc.Class_id)
          isPresent = true;
          updateChangesInDatabase(studentId, -1, fc.Class_id);
          isInClass = true;

        }
      })
    }

    if(isPresent == false){
      toast.error('Already this Class is assigned', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    // return isInClass;


  };





  const getFeeOfThatCourse = async (courseId, paymentType, classType, discountID) => {
    console.log("I am innn...");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LOCAL_SERVER_API}/getStudentId/${User_id}`
      );

      return 2000;
    } catch (error) {
      console.error("Error retrieving student ID:", error);
    }
  };

  

  return (
    <>
      {/* Navbar */}
      {username && (
        <>
          {isAdmin && (
            <AdminNav
              username={username}
              avatar={_avatar_}
              onLogout={onLogout}
            />
          )}
          {isTeacher && (
            <TeacherNav
              username={username}
              avatar={_avatar_}
              onLogout={onLogout}
            />
          )}
          {isStudent && (
            <StudentNav
              username={username}
              avatar={_avatar_}
              onLogout={onLogout}
            />
          )}
        </>
      )}
      {!username && <>{isGuest && <GuestNav onLogin={onLogin} />}</>}
      <div className="edit-bar">
        <h3
          className="student-EditHeading"
          style={{ marginLeft: "0", textAlign: "center" }}
        >
          {user_name}
        </h3>
      </div>
      <table className="CourseRegistrationTable">
        <thead>
          <tr className="CourseRegistrationTable-tr">
            <th className="CourseRegistrationTable-th">Student</th>
            <th className="CourseRegistrationTable-th">Course</th>
            <th className="CourseRegistrationTable-th">Registration Date</th>
            {/* <th className='CourseRegistrationTable-th'>School</th> */}
            <th className="CourseRegistrationTable-th">Fee</th>
            {/* <th className='CourseRegistrationTable-th'>Discount</th> */}
            <th className="CourseRegistrationTable-th">Fee Type</th>
            <th className="CourseRegistrationTable-th">Class Type</th>
            <th className="CourseRegistrationTable-th">Schedule Preference</th>
            <th className="CourseRegistrationTable-th">Class ID</th>
            <th className="CourseRegistrationTable-th">Teacher</th>
            <th className="CourseRegistrationTable-th">Class Schedule</th>
            <th className="CourseRegistrationTable-th">Save Changes</th>
          </tr>
        </thead>


        {RegistrationCourses.map((RegistrationCourse, index) => (
          
            <tbody id="table-rows">
              
              <tr className="CourseRegistrationTable-tr" key={index}>
                <td className="CourseRegistrationTable-td">
                  {RegistrationCourse.firstname} {RegistrationCourse.lastname}
                </td>
                <td className="CourseRegistrationTable-td">
                  {RegistrationCourse.coursename}
                </td>
                <td className="CourseRegistrationTable-td">
                  {RegistrationCourse.RegistrationDate}
                </td>
                <td className="CourseRegistrationTable-td">

                  <b> PKR </b>
                  {RegistrationCourse.fee.toFixed(1)}
                  {/* {getFeeOfThatCourse(RegistrationCourse.Course_id, RegistrationCourse.PaymentType, RegistrationCourse.ClassType, RegistrationCourse.Discount_id)} */}
                  {/* {" "}
                  <b>PKR</b> 20000 */}
                </td>
  
                <td class="CourseRegistrationRadioType CourseRegistrationTable-td">
                  {RegistrationCourse.PaymentType}
                </td>
                <td className="CourseRegistrationRadioType CourseRegistrationTable-td">
                  {RegistrationCourse.ClassType}
                </td>
  
                <td className="CourseRegistrationTable-td">
                  {RegistrationCourse.SchedulePreferenceDay}{" "}
                  {RegistrationCourse.SchedulePreferenceTime}
                </td>
  
                <td className='CourseRegistrationTable-td'>

                      {/* <select
                        name="discount"
                        id="discount-code"
                        className="CourseRegistrationSelect"
                        onChange={(event) => handleClassIDChange(index, event.target.value)}
                        value={filteredClassIDs[index]?.ClassID || ''}
                      > */}


                        
                        {classesData ? (
                          <select
                          name="discount"
                          id="discount-code"
                          className="CourseRegistrationSelect"
                          onChange={(event) => handleClassIDChange(index, event.target.value)}
                          // value={filteredClassIDs[index]?.ClassID || ''}
                        >
                          <option value={checkInStudent(index, RegistrationCourse.Course_id)}>{checkInStudent(index, RegistrationCourse.Course_id) ? checkInStudent(index, RegistrationCourse.Course_id) : 'Select a Class'}</option>
                          {classesData
                            .filter((CID) => CID.ClassID !== checkInStudent(index, RegistrationCourse.Course_id) && RegistrationCourse.Course_id === CID.Course_id)
                            .map((CID) => (
                              <option key={CID.ClassID} value={CID.ClassID}>
                                  {CID.ClassID}
                                </option>
                            ))}
                        </select>

                        ) : (
                          <option>{classesData ? 'No Class for this Course' : 'Loading...'}</option>
                        )}

                        
                     

{/* 
                        {classesData && classesData.length > 0 ? (
                          classesData.map((CID, index) => {

                            // console.log("Checking in CID: ", CID);
                           
                            if(RegistrationCourse.Course_id === CID.Course_id){
                              return (
                                <option key={CID.ClassID} value={CID.ClassID}>
                                  {CID.ClassID}
                                </option>
                              );

                            }
                            return null; 
                          })
                        ) : (
                          <option>{classesData ? 'No Class for this Course' : 'Loading...'}</option>
                        )} */}
                      
                    </td>
                      
                    <td className="CourseRegistrationTable-td">
                      {filteredClassIDs[index] && filteredClassIDs[index].firstname && filteredClassIDs[index].lastname
                        ? `${filteredClassIDs[index].firstname} ${filteredClassIDs[index].lastname}`
                        : 'No Teacher for this class'}
                    </td>

                    <td className="CourseRegistrationTable-td">
                      {filteredClassIDs[index] && filteredClassIDs[index].ClassDay && filteredClassIDs[index].ClassTime
                        ? `${filteredClassIDs[index].ClassDay} ${filteredClassIDs[index].ClassTime}`
                        : 'No Schedule for this class'}
                    </td>


                    {/* <td className='CourseRegistrationTable-td' key={filteredClassID?.ClassID} value={filteredClassID?.ClassID}>
                      {filteredClassID ? `${filteredClassID.firstname} ${filteredClassID.lastname}` : 'No Teacher for this class'}
                    </td>
                    <td className='CourseRegistrationTable-td' key={filteredClassID?.ClassID} value={filteredClassID?.ClassID}>
                      {filteredClassID ? `${filteredClassID.ClassDay} ${filteredClassID.ClassTime}` : 'No Schedule for this class'}
                    </td> */}
                    {/* <td className='CourseRegistrationTable-td'>{studentRegistration.classSchedule}</td> */}
                    <td className='CourseRegistrationTable-td'>
                        <button className='CourseRegistrationButton' onClick={() => handleSaveChanges(RegistrationCourse.Course_id)}>Save</button>
                    </td>
              </tr>
            </tbody>
        ))}
      </table>
      <Footer />
    </>
  );
};

export default ChangeTeachersAndClass;
