import React, { useState, useEffect } from 'react';
import axiosInstance from './axiosInstance';
import './managecontentstyle/editmodule.css';
import {toast} from 'react-toastify';
import './managecontentstyle/buttonstyle.css';

const EditModule = ({ course, module }) => {
  const [courseName, setCourseName] = useState('');
  const [moduleName, setModuleName] = useState('');
  const [courseId,setCourseId]=useState('');
  const [courses, setCourses] = useState([]);
  const [modules, setModules] = useState([]);

  useEffect(() => {
    fetchCourses();
    fetchModules();
  }, []);

  const fetchCourses = async () => {
    try {
      const response = await axiosInstance.post('/api/managecontent/getAllCourses');
      const fetchedCourses = response.data;
      setCourses(fetchedCourses);
      const coursName = fetchedCourses.find(c => c.Course_id === course)?.coursename;
      setCourseName(coursName);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchModules = async () => {
    try {
      const response = await axiosInstance.post('/api/managecontent/getAllModule');
      const fetchedModules = response.data;
      setModules(fetchedModules);
      const MNAME = fetchedModules.find(m => m.Module_id === module)?.modulename;
      setModuleName(MNAME);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSave = () => {
    const payload = {
      Course_id: courseId,
      modulename: moduleName,
      Module_id:module
    };
    axiosInstance.post('/api/managecourse/updateModule', payload)
      .then(() => {
        toast.success("Module Updated",{
          position:toast.POSITION.TOP_RIGHT
        });
        setCourseName('');
        setModuleName('');
      })
      .catch(error => {
        console.log("Internal server error", error);
      });
    setCourseName('');
    setModuleName('');
  };

  const handleDelete = () => {
    const payload = {
      Module_id: module,
    };
    axiosInstance.post('/api/managecourse/deleteModule', payload)
    .then(() => {
      toast.success("Module Deleted",{
        position:toast.POSITION.TOP_RIGHT
      });
      setCourseName('');
      setModuleName('');
    })
    .catch(error => {
      console.log("Internal server error", error);
    });
    setCourseName('');
    setModuleName('');

  };

  return (
    <div className="edit-module">
      <h2>Edit Module</h2>
      <div className="form-field">
        <label>Course Name:</label>
        <select className='inz-dropdown' value={courseId} onChange={e => setCourseId(e.target.value)}>
          <option value="">Select Course</option>
          {courses.map(course => (
            <option key={course.Course_id} value={course.Course_id}>
              {course.coursename}
            </option>
          ))}
        </select>
      </div>
      <div className="form-field">
        <label>Module Name:</label>
        <input type="text" value={moduleName}  required onChange={e => setModuleName(e.target.value)} />
      </div>
      <div className="inz-button-row">
        <button className="inz-save-button" onClick={handleSave}>Save</button>
        <button className="inz-delete-button" onClick={handleDelete}>Delete</button>
      </div>
    </div>
  );
};

export default EditModule;
