import React, { useState, useEffect } from 'react';
import axiosInstance from './axiosInstance'; // Adjust the import path based on your project structure
import './StudentFee.css';

const InvoicePage = () => {
  const [invoiceData, setInvoiceData] = useState(null);
  const [formData, setFormData] = useState({
    student: '',
    course: '',
    monthlyFee: 0,
    monthlyDiscount: 0,
    membershipFee: 1000,
    membershipDiscount: 0,
    abacusFee: 1200,
    abacusDiscount: 0,
    outstandingPayment: false,
    deliveryCharges: false,
    bankDetails: '',
    note: ''
  });

  const [students, setStudents] = useState([]);
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    // Fetch courses
    const fetchCourses = async () => {
      try {
        const response = await axiosInstance.get('/courses');
        const sortedCourses = response.data.slice().sort((a, b) => {
          return a.coursename.localeCompare(b.coursename);
        });
        setCourses(sortedCourses);
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };

    // Fetch students
    const fetchStudents = async () => {
      try {
        const response = await axiosInstance.get('/student'); // Updated endpoint to match backend
        console.log('Students data:', response.data);
        setStudents(response.data);
      } catch (error) {
        console.error('Error fetching students:', error);
      }
    };

    fetchCourses();
    fetchStudents();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Find the selected student and course names
    const selectedStudent = students.find(student => student.User_id === parseInt(formData.student));
    const selectedCourse = courses.find(course => course.Course_id === parseInt(formData.course));

    const total = formData.monthlyFee + formData.membershipFee + formData.abacusFee - (formData.monthlyDiscount + formData.membershipDiscount + formData.abacusDiscount);
    
    const data = {
      ...formData,
      studentName: selectedStudent ? selectedStudent.firstname : '',
      courseName: selectedCourse ? selectedCourse.coursename : '',
      total,
      totalPayable: total
    };

    setInvoiceData(data);

    // Sending the invoice data to the server
    try {
      const response = await axiosInstance.post('/generate-invoice', data);
      console.log(response.data);
    } catch (error) {
      console.error('Error generating invoice:', error);
    }
  };

  return (
    <div className="invoice-page">
      <form onSubmit={handleSubmit} className="invoice-form">
        <div className="form-group">
          <label>Choose a Student</label>
          <select name="student" value={formData.student} onChange={handleChange}>
            <option value="">Select Student</option>
            {students.map(student => (
              <option key={student.User_id} value={student.User_id}>{student.firstname}</option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label>Choose a Course</label>
          <select name="course" value={formData.course} onChange={handleChange}>
            <option value="">Select Course</option>
            {courses.map(course => (
              <option key={course.Course_id} value={course.Course_id}>{course.coursename}</option>
            ))}
          </select>
        </div>

        {/* Monthly Fee Section */}
        <div className="form-group monthly-fee">
          <input
            type="checkbox"
            name="monthlyFee"
            checked={formData.monthlyFee > 0}
            onChange={handleChange}
          />
          <label>Monthly Fee</label>
          <input
            type="number"
            name="monthlyFee"
            value={formData.monthlyFee}
            onChange={handleChange}
            disabled={formData.monthlyFee === 0}
          />
          <input
            type="number"
            name="monthlyDiscount"
            value={formData.monthlyDiscount}
            onChange={handleChange}
            placeholder="Discount"
          />
        </div>

        {/* Membership Fee Section */}
        <div className="form-group membership-fee">
          <input
            type="checkbox"
            name="membershipFee"
            checked={formData.membershipFee > 0}
            onChange={handleChange}
          />
          <label>Membership Fee</label>
          <input
            type="number"
            name="membershipFee"
            value={formData.membershipFee}
            onChange={handleChange}
          />
          <input
            type="number"
            name="membershipDiscount"
            value={formData.membershipDiscount}
            onChange={handleChange}
            placeholder="Discount"
          />
        </div>

        {/* Soroban Abacus Fee Section */}
        <div className="form-group abacus-fee">
          <input
            type="checkbox"
            name="abacusFee"
            checked={formData.abacusFee > 0}
            onChange={handleChange}
          />
          <label>Soroban Abacus Fee</label>
          <input
            type="number"
            name="abacusFee"
            value={formData.abacusFee}
            onChange={handleChange}
          />
          <input
            type="number"
            name="abacusDiscount"
            value={formData.abacusDiscount}
            onChange={handleChange}
            placeholder="Discount"
          />
        </div>

        {/* Additional Fees */}
        <div className="form-group additional-fees">
          <input
            type="checkbox"
            name="outstandingPayment"
            checked={formData.outstandingPayment}
            onChange={handleChange}
          />
          <label>Outstanding Payment</label>
        </div>

        <div className="form-group additional-fees">
          <input
            type="checkbox"
            name="deliveryCharges"
            checked={formData.deliveryCharges}
            onChange={handleChange}
          />
          <label>Delivery Charges</label>
        </div>

        {/* Bank Details */}
        <div className="form-group">
          <label>Bank Details</label>
          <textarea
            name="bankDetails"
            value={formData.bankDetails}
            onChange={handleChange}
            placeholder="Enter bank details here"
          ></textarea>
        </div>

        {/* Note */}
        <div className="form-group">
          <label>Note</label>
          <textarea
            name="note"
            value={formData.note}
            onChange={handleChange}
            placeholder="Type note here..."
          ></textarea>
        </div>

        <button type="submit">Generate Fee & PDF</button>
      </form>

      {invoiceData && (
        <div className="invoice-display">
          <h2>INVOICE</h2>
          <p>Child Name: {invoiceData.studentName}</p>
          <p>Course Name: {invoiceData.courseName}</p>
          <p>Invoice Date: {new Date().toLocaleDateString()}</p>
          <p>Due Date: {new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toLocaleDateString()}</p>
          
          {/* Items and Amounts */}
          <table>
            <thead>
              <tr>
                <th>Item</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {/* Add rows for each item here */}
            </tbody>
          </table>

          <p>Subtotal: PKR {invoiceData.total}</p>
          <p>Total Payable: PKR {invoiceData.totalPayable}</p>

          {/* Payment Methods */}
          <div>
            <p>Payment Method:</p>
            <ul>
              <li>Bank Transfer: {invoiceData.bankDetails}</li>
              <li>Mobile Wallet: Jazz Cash Number: 03206899087</li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default InvoicePage;
