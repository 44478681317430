import React,{useContext,useEffect } from 'react'
import  "./Polymatch.css";
import Flag from 'react-world-flags';
import StudentNav from '../Navbars/StudentNav'
import AdminNav from '../Navbars/AdminNav'
import GuestNav from '../Navbars/GuestNav';
import { UserContext } from '../UserContext';
import Footer from "../Footer";
import owner from "../CourseImages/ownerImg.png";
import TeacherNav from '../Navbars/TeacherNav';
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import polymatchImage from './Polymatch.png';

import winner1 from './WinnerImages/winner1.png';
import winner2 from './WinnerImages/winner2.png';
import winner3 from './WinnerImages/winner3.png';
import winner4 from './WinnerImages/winner4.png';
import winner5 from './WinnerImages/winner5.png';
import winner6 from './WinnerImages/winner6.png';
import winner7 from './WinnerImages/winner7.png';

const Polymatch = () => {
    const flagCodes = [
        'PK', // Pakistan
        'GB', // United Kingdom
        'NO', // Norway
        'AE', // UAE
        'SA', // Saudi Arabia
        'US', // United States
        'CA', // Canada
        'AU', // Australia
        'QA', // Qatar
        'ZA', // South Africa
        'SE', // Sweden
        'OM'  // Oman
    ];
    const winners_abacus = [
        winner1,
        winner2,
        winner3,
        winner4,
        winner5,
    ];
    const winners_islamic = [
        winner6,
        winner7,
    ];
    const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
 
    useEffect(() => {
      
      const fetchData = async () => {
       await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
          credentials: 'include',
        }).then(response => {
            console.log("Response:",response);
          response.json().then(userInfo => {
            console.log("userinfo:",userInfo);
            setUserInfo(userInfo);

            if(userInfo?.usertype)
            {
              const uType=userInfo?.usertype;
              setUserRole(uType);
            }
          const uname = userInfo?.username;
          const avatar = userInfo?.avatar; // Store the avatar in a separate variable
          console.log("Uname: ", uname);
          console.log("Avatar: ", avatar);

          })
          .catch(err=> console.log("Error in useeffect:",err))
        });
      }
      fetchData();
    }, [isAdmin, isTeacher, isStudent, isGuest]);
  const determineUserRole = (usertype) => {
        console.log("In home.js:",usertype)
        if(usertype === 'Student')
          return "Student";
        else if(usertype === 'Teacher')
          return "Teacher";
        else if(usertype === 'Admin')
          return "Admin";
        else
          return "guest";
  };
  
  
 const navigate=useNavigate();
  const onLogin = (usertype) => {
    const userRole = determineUserRole(usertype);
    console.log("Calling setUserRole.")
    setUserRole(userRole);
  };

  function onLogout() {
      console.log("inside Onlogout.");
      fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
        credentials: 'include',
        method: 'POST',
      });
      setUserInfo(null);
      setUserRole('guest');
    }
      
      const username = userInfo?.username;
      const _avatar_ = userInfo?.avatar;
  return (
    <>
{username && (
       <>
       {isAdmin && <AdminNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
       {isTeacher && <TeacherNav  username={username} avatar={_avatar_} onLogout={onLogout}/>}
       {isStudent && <StudentNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
     </>
        )}  
        {!username && (
          <>
          {isGuest && <GuestNav onLogin={onLogin} />}

          </>
        )}
        <div className='PolymatchBg'>
            <div className='PolymatchSection_1'>
                <img src={polymatchImage} alt="image" />
                <p>PolyMatch is a global competition platform which is the initiative of Polymath-Kids. PolyMatch is yet another example of Polymath-Kids commitment to impart knowledge and provide STEM education and 21st Century Life Skills in a fun learning environment. PolyMatch is a dynamic platform dedicated to nurturing young minds, continues to illuminate the path of intellectual exploration with its diverse range of engaging competitions including Mental Math Abacus, Coding, Islamic Knowledge, Declamation contests and Graphic Designing. With an ever-growing roster of intellectual engagements, PolyMatch remains committed to providing a vibrant platform where young learners can embark on an enriching voyage of exploration and discovery.</p>

                <div className='PolymatchSection_2'>
                    <div className='Polymatch_MathMental'>
                        <p>PolyMatch 2023</p>
                        <a href='#mental'>Mental Math Abacus Competition</a>
                    </div>


                    <div className='Polymatch_Islamic'>
                        <p>PolyMatch 2024</p>
                        <a href='#islamic'>Islamic Knowledge Competition</a>
                    </div>
                </div>


                <div className="PolymatchSection_3" id="mental">
                    <div className="Polymatch_header">
                        <div>
                            <h1>PolyMatch 2023</h1>
                            <h2>Mental Math Abacus Competition</h2>
                        </div>
                    
                        <div className="Polymatch_dateBadge">
                            <p>22 Nov 2023</p>
                        </div>
                    </div>
                    
                    <div className='PolymatchFlagSection'>    
                        <div className="Polymatch_content">
                            <p>
                                Our first chapter of PolyMatch was a resounding success. All the young participants were tested
                                not just for mental strength but also their physical endurance was put to test. It was a
                                marathon competition which the participants thoroughly enjoyed.
                            </p>
                        </div>
                        <div className="Polymatch_participantsSection">
                            <h3>Participants From Across the Globe</h3>
                            <div className="Polymatch_flagsGrid">
                                {flagCodes.map((code) => (
                                    <Flag key={code} code={code.toLowerCase()} style={{width:"80%",height:"80%"}}/>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="gallery_container">
                            {winners_abacus.map((image, index) => (
                                <img key={index} src={image} alt={`image-${index}`} style={{width:'40%',height:"auto"}}/>
                            ))}
                    </div>
                </div>

                <div className="PolymatchSection_3" id="islamic">
                    <div className="Polymatch_header">
                        <div>
                            <h1>PolyMatch 2024</h1>
                            <h2>Islamic Knowledge Competition</h2>
                        </div>
                    
                        <div className="Polymatch_dateBadge">
                            <p>30 Mar 2024</p>
                        </div>
                    </div>
                    
                    <div className='PolymatchFlagSection'>    
                        <div className="Polymatch_content">
                            <p>
                            Our chapter of Islamic Knowledge Competition of PolyMatch was a fun way to enhance the knowledge of the participants. All the young participants not just had fun but also learned a lot. This PolyMatch competition infused them with eagerness to learn more. 
                            </p>
                        </div>
                        <div className="Polymatch_participantsSection">
                            <h3>Participants From Across the Globe</h3>
                            <div className="Polymatch_flagsGrid">
                                {flagCodes.map((code) => (
                                    <Flag key={code} code={code.toLowerCase()} style={{width:"80%",height:"80%"}}/>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="gallery_container">
                            {winners_islamic.map((image, index) => (
                                <img key={index} src={image} alt={`image-${index}`} style={{width:'40%',height:"auto"}}/>
                            ))}
                    </div>
                </div>
            </div>
        </div>

      {/* Footer */}
      <Footer />
    </>
  )
}

export default Polymatch
