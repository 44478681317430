import React,{useContext,useEffect} from 'react'

import StudentNav from '../Navbars/StudentNav'
import AdminNav from '../Navbars/AdminNav'
import TeacherNav from '../Navbars/TeacherNav';
import GuestNav from '../Navbars/GuestNav';
import { UserContext } from '../UserContext';
import Footer from '../Footer'

import { MDBAccordion, MDBAccordionItem, MDBContainer} from "mdb-react-ui-kit";
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';

const Faqs = ({selectedCourse}) => {
    const handleCourseSelect = (course) => {
        console.log("Selected Course in Home:", course);
        selectedCourse(course);
      };
    
     const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
     
        useEffect(() => {
          const fetchData = async () => {
           await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
              credentials: 'include',
            }).then(response => {
                console.log("Response:",response);
              response.json().then(userInfo => {
                console.log("userinfo:",userInfo);
                setUserInfo(userInfo);
    
                if(userInfo?.usertype)
                {
                  const uType=userInfo?.usertype;
                  setUserRole(uType);
                }
              const uname = userInfo?.username;
              const avatar = userInfo?.avatar; // Store the avatar in a separate variable
              console.log("Uname: ", uname);
              console.log("Avatar: ", avatar);
    
              })
              .catch(err=> console.log("Error in useeffect:",err))
            });
          }
          fetchData();
        }, [isAdmin, isTeacher, isStudent, isGuest]);
      const determineUserRole = (usertype) => {
            console.log("In home.js:",usertype)
            if(usertype === 'Student')
              return "Student";
            else if(usertype === 'Teacher')
              return "Teacher";
            else if(usertype === 'Admin')
              return "Admin";
            else
              return "guest";
      };
      
      
     const navigate=useNavigate();
      const onLogin = (usertype) => {
        const userRole = determineUserRole(usertype);
        console.log("Calling setUserRole.")
        setUserRole(userRole);
      };
    
      function onLogout() {
          console.log("inside Onlogout.");
          fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
            credentials: 'include',
            method: 'POST',
          });
          setUserInfo(null);
          setUserRole('guest');
        }
          
          const username = userInfo?.username;
          const _avatar_ = userInfo?.avatar;
       
  return (
    <>
          {/* Faqs */}
          {username && (
        <>
        {isAdmin && <AdminNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
        {isTeacher && <TeacherNav  username={username} avatar={_avatar_} onLogout={onLogout}/>}
        {isStudent && <StudentNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
      </>
        )}  
        {!username && (
          <>
          {isGuest && <GuestNav onLogin={onLogin} />}

          </>
        )}
    <div style={{ maxWidth: "100%", backgroundColor: "#edffff" }} id="Faq">
      <MDBContainer className="mt-0" style={{ maxWidth: "180vh" }}>
        <MDBAccordion alwaysOpen initialActive={1}>
          <div className="text-center PageHead" style={{ color: "#8b0194" }}>
            {" "}
            FAQs
          </div>        
         <MDBAccordionItem
            collapseId={3}
            headerTitle="Q: Who can participate in Polymath-Kids Classes?"
          >
            <strong>A: </strong>
            Polymath-Kids is currently offering a range of courses and online classes for a variety of subjects. Although there is no age restriction, our courses are suitable for students aged 5 to 16 years. 
          </MDBAccordionItem>
          <MDBAccordionItem
            collapseId={3}
            headerTitle="Q: What are the timings for the classes?"
          >
            <strong>A: </strong>
            The regular classes are taken at different time slots agreed between students and teachers. 
            To accommodate students from around the globe, classes are taken at regular suitable timings by our trained instructors.

          </MDBAccordionItem>
          <MDBAccordionItem
            collapseId={3}
            headerTitle="Q: What will my child learn?"
          >
            <strong>A: </strong>
            Life Skills! which are not generally taught in schools but are critical for child development.
            Currently we are offering Brain Training Abacus Program, Coding & Programming, Graphic Designing, Qur'an and Business courses for kids. Other courses such as Economics and Communication skills for children will commence shortly.
          </MDBAccordionItem>
          <MDBAccordionItem
            collapseId={3}
            headerTitle="Q: Is payment method secure? Which payment methods are available?"
          >
            <strong>A: </strong>
            Yes, payments are secure!
            We provide several payment options including: Wise Transfer, Online Bank Transfer and Jazz Cash (only available for Pakistan based students). You can also choose between a one-time payment or monthly subscription payments.

          </MDBAccordionItem>
          <MDBAccordionItem
            collapseId={3}
            headerTitle="Q: How does Polymath-Kids make online learning safe for our children?"
          >
            <strong>A: </strong>
            Polymath-Kids adheres to applicable guidelines to protect the privacy and safety of our students. Please refer to our Privacy Policy on how we secure your data and keep your child safe during our 1:1 online learning sessions as well as group classes.
          </MDBAccordionItem>
          <MDBAccordionItem
            collapseId={3}
            headerTitle="Q: How can I register with Polymath-Kids.com courses?"
          >
            <strong>A: </strong>
            You need to sign up through our website and provide us your details. We will confirm the details through email and provide information regarding fee. Once the fee for the relevant course is paid, you will have access to the chosen course portal through our website, within 24 hours.  
          </MDBAccordionItem>
          <MDBAccordionItem
            collapseId={3}
            headerTitle="Q: How long does it take to complete one module?"
          >
            <strong>A: </strong>
            The duration per module for each course may vary. Visit the course structure details of the relevant course for more details.
          </MDBAccordionItem>
       </MDBAccordion>
      </MDBContainer>
    </div>

    
{/* Footer */}
<Footer/>
    </>
  )
}

export default Faqs
