import React,{useContext} from 'react'

import StudentNav from '../Navbars/StudentNav'
import AdminNav from '../Navbars/AdminNav'
import TeacherNav from '../Navbars/TeacherNav';
import GuestNav from '../Navbars/GuestNav';
import { UserContext } from '../UserContext';
import Footer from '../Footer'


import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';

import { useRef, useState, useEffect } from 'react'
import axios from "axios";
import InvoiceRow from './InvoiceRow';

const InvoiceTable = () => {


    const [studentId, setStudentId] = useState(null);
    const [studentInvoicesData, setStudentInvoicesData] = useState(null);
    
    const getStudentInvoices = async (User_id) => {
        console.log("I am in...");
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_LOCAL_SERVER_API}/getStudentId/${User_id}`
          );
    
          if (response.data.success) {
            console.log("Student Id is: ", response.data.Student_id);
    
            const studID = response.data.Student_id;
            setStudentId(response.data.Student_id);
    
    
            try {
              console.log("Going in /get Student Invoices...")
              console.log("Goiing in StudentID is: ", studID)
              const response1 = await axios.get(
                `${process.env.REACT_APP_LOCAL_SERVER_API}/studentInvoices/${studID}`
              );
        
                console.log("Student Invoices are: ", response1.data);
                setStudentInvoicesData(response1.data);
                // setStudentClasses(response1.data.classInfo);
                // setStudentId(response.data.Student_id);
            } catch (error) {
              console.error("Error retrieving student ID:", error);
            }
          } else {
            console.log(response.data.Message);
          }
        } catch (error) {
          console.error("Error retrieving student ID:", error);
        }
      };
    
     const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
     
        useEffect(() => {
          const fetchData = async () => {
           await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
              credentials: 'include',
            }).then(response => {
                console.log("Response:",response);
              response.json().then(userInfo => {
                console.log("userinfo:",userInfo);
                setUserInfo(userInfo);

                getStudentInvoices(userInfo.User_id);
    
                if(userInfo?.usertype)
                {
                  const uType=userInfo?.usertype;
                  setUserRole(uType);
                }
              const uname = userInfo?.username;
              const avatar = userInfo?.avatar; // Store the avatar in a separate variable
              console.log("Uname: ", uname);
              console.log("Avatar: ", avatar);
    
              })
              .catch(err=> console.log("Error in useeffect:",err))
            });
          }
          fetchData();
        }, [isAdmin, isTeacher, isStudent, isGuest]);
      const determineUserRole = (usertype) => {
            console.log("In home.js:",usertype)
            if(usertype === 'Student')
              return "Student";
            else if(usertype === 'Teacher')
              return "Teacher";
            else if(usertype === 'Admin')
              return "Admin";
            else
              return "guest";
      };
      
      
     const navigate=useNavigate();
      const onLogin = (usertype) => {
        const userRole = determineUserRole(usertype);
        console.log("Calling setUserRole.")
        setUserRole(userRole);
      };
    
      function onLogout() {
          console.log("inside Onlogout.");
          fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
            credentials: 'include',
            method: 'POST',
          });
          setUserInfo(null);
          setUserRole('guest');
        }
          
        const username = userInfo?.username;
        const _avatar_ = userInfo?.avatar;
        const usertype = userInfo?.usertype;    



        const invoices = [
            {
              number: 'Invoice No. 9',
              amount: 'USD 3,800',
              dueDate: 'Feb 11, 2024',
              amountPaid: 'Paid on',
              isPaid: true
            },
            {
              number: 'Invoice No. 8',
              amount: 'PKR 2,470',
              dueDate: 'Oct 5, 2023',
              amountPaid: '',
              isPaid: false
            }
          ];
        
          const tableStyles = {
            width: '80vw',
            borderCollapse: 'collapse',
            fontSize: '14px',
            fontFamily: 'Arial, sans-serif',
            backgroundColor: '#f5f5f5'
          };
        
          const tableContainerStyles = {
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20px'
          };
        
          const tableHeaderStyles = {
            backgroundColor: '#fcbf14',
            padding: '10px',
            textAlign: 'left',
            fontWeight: 'bold'
          };
        
          const tableBodyStyles = {
            padding: '10px',
            borderBottom: '1px solid #ddd'
          };
        
          const paidColumnStyles = {
            width: '80px',
            textAlign: 'center'
          };
        
          const uploadColumnStyles = {
            width: '120px',
            textAlign: 'center'
          };

          const selectedCourse = ""
          const handleCourseSelect = (course) => {
            console.log("Selected Course in Home:", course);
            selectedCourse(course);
          };


  return (


    <div>

{username && (
        <>
             {isAdmin && <AdminNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
            {isTeacher && <TeacherNav  onCourseSelect={handleCourseSelect}  username={username} avatar={_avatar_} onLogout={onLogout}/>}
            {isStudent && <StudentNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
         </>
         )}  
         {!username && (
           <>
           {isGuest && <GuestNav onLogin={onLogin} />}
  
           </>
         )}


<div style={tableContainerStyles}>
      <table style={tableStyles}>
        <thead>
          <tr>
            <th style={tableHeaderStyles}>Paid</th>
            <th style={tableHeaderStyles}>Invoice No</th>
            <th style={tableHeaderStyles}>Amount Due</th>
            <th style={tableHeaderStyles}>Due Date</th>
            <th style={tableHeaderStyles}>Make Payment</th>
          </tr>
        </thead>
        <tbody>
          {studentInvoicesData &&
            studentInvoicesData.map((invoice, index) => {
              const isOverdue = new Date(invoice.duedate) < new Date();
              return (
                <InvoiceRow
                  key={index}
                  invoice={{
                    invoiceNumber: studentInvoicesData.length - index,
                    amount: invoice.amountdue,
                    dueDate: invoice.duedate,
                    paid: invoice.paid,
                    invoiceFile: invoice.InvoiceFile,
                    studentId: studentId,
                    feeId: invoice.Fee_id,
                    courseId: invoice.Course_id
                  }}
                  isOverdue={isOverdue}
                />
              );
            })}
        </tbody>
      </table>
    </div>



    <Footer/>

    </div>
  )
}

export default InvoiceTable;
