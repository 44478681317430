import React,{useState,useContext,useEffect} from 'react'
import logo1 from './MathCourseDetailImages/highlightDetail1.png'
import logo2 from './MathCourseDetailImages/highlightDetail2.png'
import logo3 from './MathCourseDetailImages/highlightDetail3.png'
import logo4 from './MathCourseDetailImages/highlightDetail4.png'
import logo5 from './MathCourseDetailImages/highlightDetail5.png'
import logo6 from './MathCourseDetailImages/highlightDetail6.png'
import logo7 from './MathCourseDetailImages/highlightDetail7.png'
import logo8 from './MathCourseDetailImages/highlightDetail8.png'
import logo9 from './MathCourseDetailImages/highlightDetail9.png'
import logo10 from './MathCourseDetailImages/highlightDetail10.png'
import logo11 from './MathCourseDetailImages/highlightDetail11.png'
import logo12 from './MathCourseDetailImages/highlightDetail12.png'
import StudentNav from '../Navbars/StudentNav'
import Footer from '../Footer'
import AdminNav from '../Navbars/AdminNav'
import GuestNav from '../Navbars/GuestNav'
import { UserContext } from '../UserContext';
import "./MathCourseDetails.css";
import "./MathPortalResponsive.css";
import { MDBAccordion, MDBAccordionItem, MDBContainer, MDBNavbarToggler } from "mdb-react-ui-kit";
import TeacherNav from '../Navbars/TeacherNav'
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
const arr = [logo1, logo2, logo3, logo4, logo5, logo6, logo7, logo8,logo9 , logo10,logo11,logo12]
 
const cols = ['#007793', '#00a9b9', '#10be81', '#ecaa07','#00a9b9','#10be81','#e6a70a','#f14258','#ff8d2a','#f2669c','#9769b6','#f14258'];
const HighlightsDetail = ({selectedCourse}) => {
  
    
    const handleCourseSelect = (course) => {
        // console.log("Selected Course in Home:", course);
        selectedCourse(course);
      };
    const [selected, setSelected] = useState(null);
    const toggle = (i) => {
        if (selected === i) {
            return setSelected(null)
        }
        setSelected(i)
    }
  
    const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
 
    useEffect(() => {
      const fetchData = async () => {
       await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
          credentials: 'include',
        }).then(response => {
            console.log("Response:",response);
          response.json().then(userInfo => {
            console.log("userinfo:",userInfo);
            setUserInfo(userInfo);

            if(userInfo?.usertype)
            {
              const uType=userInfo?.usertype;
              setUserRole(uType);
            }
          const uname = userInfo?.username;
          const avatar = userInfo?.avatar; // Store the avatar in a separate variable
          console.log("Uname: ", uname);
          console.log("Avatar: ", avatar);

          })
          .catch(err=> console.log("Error in useeffect:",err))
        });
      }
      fetchData();
    }, [isAdmin, isTeacher, isStudent, isGuest]);
  const determineUserRole = (usertype) => {
        console.log("In home.js:",usertype)
        if(usertype === 'Student')
          return "Student";
        else if(usertype === 'Teacher')
          return "Teacher";
        else if(usertype === 'Admin')
          return "Admin";
        else
          return "guest";
  };
  
  
 const navigate=useNavigate();
  const onLogin = (usertype) => {
    const userRole = determineUserRole(usertype);
    console.log("Calling setUserRole.")
    setUserRole(userRole);
  };

  function onLogout() {
      console.log("inside Onlogout.");
      fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
        credentials: 'include',
        method: 'POST',
      });
      setUserInfo(null);
      setUserRole('guest');
    }
      
      const username = userInfo?.username;
      const _avatar_ = userInfo?.avatar;
    return (
        <div >
  {/* Navbar */}
  {username && (
       <>
       {isAdmin && <AdminNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
       {isTeacher && <TeacherNav  onCourseSelect={handleCourseSelect}  username={username} avatar={_avatar_} onLogout={onLogout}/>}
       {isStudent && <StudentNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
   </>
        )}  
        {!username && (
          <>
          {isGuest && <GuestNav onLogin={onLogin} />}

          </>
        )}
   
    <section className='benefit-detail-background benefit-detail-background-resp'>
        <p className="PageHead" style={{ color: "#8b0194" }}>
                Our Abacus Portal Highlights
        </p>
        {/* Faqs */}
    <div style={{ maxWidth: '100%' }} className=" wrapper">
        <div className="benefit-resp ">
            {data.map((item, j) => (
                <div className=''  style={{ maxWidth: "200vh" }}>
                    <div className="gfg-highlights  benefit-resp" style={{color:cols[j], border: "1px solid", height: "120px" }}>
                        <div className='imgbene-resp'>
                            <img className='imgbene-resp' src={arr[j]}  />
                            <h3 class="second-txt benefit-resp-text" style={{
                            color:cols[j] }}>
                                {item.Question}
                            </h3>
                            <span className='span arrow-resp' style={{
                                cursor: 'pointer', color:cols[j]
                            }}>

                                {selected === j ?
                                    <i className="fa-solid fa-angle-up arrowRespFont" onClick={() => toggle(j)}></i>
                                    :
                                    <i className="fa-solid fa-angle-down arrowRespFont" onClick={() => toggle(j)}></i>
                                }
                            </span>
                        </div>
                    </div >

                    <div className=' '>
                        <div className={selected === j ? 'content show benefit-resp2 ' : 'content benefit-resp2 '} style={{color:cols[j], maxWidth: "150vh", background: "transparent", border: '1px solid' }}>{item.answer}
                        </div>
                    </div>
                </div>
            ))
            }
        </div>


    </div>

    </section>

{/* Footer */}
<Footer/>


        </div>
    )
}


const data = [
    {
        Question: "Structured Program Portal with global access",
        answer: " Polymath-Kids offer an Online Brain Train Abacus Portal that provides easy access to students from around the globe. Our structured programme makes the whole process of learning mental calculations, with speed and accuracy, troublefree. Although anyone can learn abacus, the ideal age group for learning Abacus is 5-14 years.•The programme offers total 10 modules •	Foundation Level Course - 2 modules  •	Beginner Level Course - 3 modules        •	Advanced Level Course - 5 modules",


    },
    {
        Question: "Variety of Arithmetic Operations ",
        answer: "The Brain Train Abacus Portal provides in depth knowledge, understanding and practice of a variety of arithmetic operations using Japanese Abacus Soroban. The concepts covered include Abacus Addition, Subtraction, Multiplication, Division, Decimal Numbers, Negative numbers, percentages, square root, HCF and LCM ",

    },
    {
        Question: "Animated Explanations",
        answer: "Polymath-Kids Abacus Brain Exercise Portal provide hundreds of videos explaining Abacus rules related to each concept, in depth. The videos help kids to learn abacus concepts quickly and efficiently. Abacus rules and movements are shown in animated explanations for the ease of learners. Videos provide numerous examples related to the concept in discussion to provide a solid base to students and ensure smooth transition from one concept to another."
    },
    {
        Question: "Infinite Practice Worksheets",
        answer: "Practice makes a man perfect! In a physical Abacus study environment; there are a limited number of practice worksheets. In order to practice further, either the parents or teachers have to create the extra worksheets for the students, which is a cumbersome activity. Polymath-Kids Brain Train ABACUS Portal provides the opportunity for the students to attempt the Written practice questions, Anzan Cards activity, Games and Audio activities as many times as they like to further practice. There is no need to recreate the worksheets for extra practice. Each activity is timed enabling students to focus, concentrate and develop time management skills."
    },
    {
        Question: "Anzan Videos",
        answer: "Anzan is a method where numbers flash on the screen with the speed that is appropriate for the student level. Students have to calculate the numbers as appearing on the screen and type in their answer within the given time, to get real time feedback. In order to provide correct answers, student has to focus on the numbers appearing on the screen, remember them, visualize them on their mental abacus and finally calculate the answer with speed and accuracy. Using Anzan Flash Cards, students are practicing visualization, memory, focus, speed and accuracy."
    },
    {
        Question: "Oral Activity",
        answer: "The oral activity offered by Polymath-Kids Brain Train Abacus portal provides an excellent opportunity for the students to strengthen their listening, visualization, memory and mental arithmetic calculation skills. Each lesson offers the oral activities which students have to listen to oral audio questions and type in their answers to get real time feedback. A student has the opportunity to repeat the audio, if required. Each student can complete the activity at their own pace and practice oral listening skills as many times as they like. Practicing questions using these tools can help students calculate a chain of large numbers comfortably in their mind.   "
    },
    {
        Question: "Real Time Reports",
        answer: "The system provides real time reports for students and Parents to review their progress such as accuracy level , speed and points gained."
    },
    {
        Question: "Virtual Abacus",
        answer: "Our instructors make use of Virtual Abacus to teach concepts to the students. All students also have access to the virtual abacus and can use it for fun interactive learning, if required. Kindly note that each student is required to have a physical Abacus as the research strongly suggests that physical movement of beads helps in cognitive development."
    }, 
    {
        Question: "IQ Games",
        answer: "Each week students get the opportunity to play IQ games that help nurture creativity and problem solving skills in a fun learning environment."
    },
    {
        Question: "Gamification and Rewards",
        answer: "To motivate our little learners Polymath-Kids Brain Train Portal comprises of a Reward System.     •	Students can collect points for every activity in which they have scored more than 70%.         •	More correct answers mean more POINTS!         •	The points can be redeemed anytime during the course.         •	The points cannot be redeemed against cash.        •	Amazon gift cards or Daraz.pk Gift cards of $10- $50 will be given to students as per their accumulated points."
    },
    {
        Question: "Online Exam & Certifications",
        answer: "An online exam will be conducted at the end of each module to test the student skills and readiness to move on to the next module        •	Student will be progressed to next module only after the successful completion of exam.        •	Successful students will be awarded E-certificates from Polymath-Kids at the end of each successful course.        •	Student must score at least 80% marks to move to the next module"
    },
    {
        Question: "Online Classes with Certified Expert Instructors",
        answer: "Our registered members can access the structured videos and activities and will have added benefit of attending online classes each week with an Expert Abacus Instructor. Although our portal is designed to help students learn independently. However, attending the classes with instructor help motivate the students and provides them an opportunity to connect with students from around the globe. Students registered for one-on-one classes will be able to attend one-to-one online classes with an expert instructor in addition to the access to the portal. This option is suitable for students who require high level of special attention. "
    },

]
export default HighlightsDetail
