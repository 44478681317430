import React,{useContext} from 'react'

import StudentNav from '../Navbars/StudentNav'
import AdminNav from '../Navbars/AdminNav'
import TeacherNav from '../Navbars/TeacherNav';
import GuestNav from '../Navbars/GuestNav';
import { UserContext } from '../UserContext';
import Footer from '../Footer'
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./CPS.css";
import "../MathPortal/Slider.css";
import "../MathPortal/MathCourseDetails.css";
import "../MathPortal/MathPortalResponsive.css";


import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import pic1 from "./CommunicationImages/CPS.png";
import pic2 from "./CommunicationImages/comprehension.png";
import pic3 from "./CommunicationImages/essays.png";
import pic4 from "./CommunicationImages/MCQ.png";
import pic5 from "./CommunicationImages/Listening.png";
import pic6 from "./CommunicationImages/speaking.png";
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import FeeStructure from '../components/FeeStructure';

const CPS = ({selectedCourse}) => {
    const faqData = [
        {
          question: "What is a communication skills course?",
          answer: "A communication skills course is a program designed to help individuals improve their verbal, non-verbal, and written communication abilities. It covers various aspects of effective communication, such as public speaking, active listening, interpersonal skills, and more."
        },
        {
          question: "Who can benefit from a communication skills course?",
          answer: "Communication skills courses are beneficial for anyone looking to enhance their communication abilities."
        },
        {
          question: "How long is the course, and what's the format?",
          answer: "The course duration and format can vary. We offer both one to one and group classes, and the duration will vary according to your skills."
        },
        {
          question: "Do I receive a certificate upon completion of the course?",
          answer: "Yes, participants who successfully complete our communication skills course will receive a certificate of completion, which can be a valuable addition to your resume or professional portfolio."
        },
        {
          question: "What are the benefits of improving communication skills?",
          answer: "Improved communication skills can lead to better career prospects, increased confidence, and overall personal and professional growth. Effective communication is a crucial skill in almost every aspect of life."
        },
        {
          question: "How do I register for a course?",
          answer: "To register for a course, please visit our website and follow the registration instructions provided on the course page. You can also contact our support team for assistance."
        },
        {
          question: "Are there any online resources or materials provided with the course?",
          answer: "Yes, we provide course materials, resources, and access to our online platform for interactive learning. You will receive details on accessing these materials upon enrollment."
        },
        {
          question: "What would be the method of teaching, recorded lectures or live classes?",
          answer: "We provide online live sessions."
        }
      ];
    const handleCourseSelect = (course) => {
        console.log("Selected Course in Home:", course);
        selectedCourse(course);
      };
    
     const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
     
        useEffect(() => {
          const fetchData = async () => {
           await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
              credentials: 'include',
            }).then(response => {
                console.log("Response:",response);
              response.json().then(userInfo => {
                console.log("userinfo:",userInfo);
                setUserInfo(userInfo);
    
                if(userInfo?.usertype)
                {
                  const uType=userInfo?.usertype;
                  setUserRole(uType);
                }
              const uname = userInfo?.username;
              const avatar = userInfo?.avatar; // Store the avatar in a separate variable
              console.log("Uname: ", uname);
              console.log("Avatar: ", avatar);
    
              })
              .catch(err=> console.log("Error in useeffect:",err))
            });
          }
          fetchData();
        }, [isAdmin, isTeacher, isStudent, isGuest]);
      const determineUserRole = (usertype) => {
            console.log("In home.js:",usertype)
            if(usertype === 'Student')
              return "Student";
            else if(usertype === 'Teacher')
              return "Teacher";
            else if(usertype === 'Admin')
              return "Admin";
            else
              return "guest";
      };
      
      
     const navigate=useNavigate();
      const onLogin = (usertype) => {
        const userRole = determineUserRole(usertype);
        console.log("Calling setUserRole.")
        setUserRole(userRole);
      };
    
      function onLogout() {
          console.log("inside Onlogout.");
          fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
            credentials: 'include',
            method: 'POST',
          });
          setUserInfo(null);
          setUserRole('guest');
        }
        const username = userInfo?.username;
        const _avatar_ = userInfo?.avatar;
  return (
    <div >
          
    {/* Navbar */}
    {username && (
  <>
  {isAdmin && <AdminNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
  {isTeacher && <TeacherNav  username={username} avatar={_avatar_} onLogout={onLogout}/>}
  {isStudent && <StudentNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
</>
   )}  
   {!username && (
     <>
     {isGuest && <GuestNav onLogin={onLogin} />}

     </>
   )}

     <div className="CPS-Background">
       <section className="section">
         <p className="PageHead">Communication Skills Course</p>
         <div className="PageDesc">
           <p>
           Communication Skills for Kids is a comprehensive and engaging program designed to empower children with essential communication skills. This course is tailored to help kids develop effective verbal, non-verbal, and interpersonal communication abilities, equipping them with the tools they need to express themselves confidently, interact with others harmoniously, and succeed in various aspects of their lives.
           </p>
           <div className="btn-row d-flex btn-row-resp btn-row-resp1">
             {/* <div className="btn-row-resp"> */}
             <div style={{ width: "auto", height: "50px" }}>
               <a
                 className="btn-row-resp button btn-color-course"
                 href="#course"
               >
                 Course Structure
               </a>
             </div>

             <div style={{ width: "auto", height: "50px" }}>
               <a className=" btn-row-resp button btn-color-fee" href="#fee">
                 Fee Structure
               </a>
             </div>
             <div style={{ width: "auto", height: "50px" }}>
               <a
                 className=" btn-row-resp button btn-color-Testimonal"
                 href="#testimonal"
               >
                 Testimonals
               </a>
             </div>
             <div style={{ width: "auto", height: "50px" }}>
               <a className=" btn-row-resp button btn-color-Faq" href="#Faq">
                 Faqs
               </a>
             </div>
             <div style={{ width: "auto", height: "50px" }}>
               <a
                 className=" btn-row-resp button btn-color-registered"
                 href="#"
               >
                 Get Registered
               </a>
             </div>
           </div>
         </div>
       </section>
       <div className="overlay"></div>
     </div>

     {/* Intro text */}
     <div className="scratch-background">
       <section className="section">
         <p className="PageHead" style={{ color: "#8b0194" }}>
         What Polymath-Kids Communication Skills offers?
         </p>
         <div className="PageDesc">
           <p
             style={{
               color: "#8b0194",
               marginTop: "2vh",
               fontSize: "4vh",
               fontFamily: "sans serif",
             }}
           >
        The "Communication Skills for Kids" course offers a range of valuable benefits and learning outcomes for children, including: <br />
        Enhanced Confidence: The course helps children build self-confidence, enabling them to express themselves more assertively and comfortably in various social and academic situations. <br />
        Improved Listening Skills: Students learn active listening techniques, which are crucial for understanding others better and fostering empathy.
             <br />
        Effective Speaking: Children develop clearer and more confident speech patterns through exercises that focus on pronunciation, articulation, and voice modulation. <br />

        Interpersonal Skills: The course emphasizes the importance of positive interpersonal relationships, teaching conflict resolution and problem-solving techniques. <br />

        Presentation Skills: Kids gain fundamental presentation skills, which are valuable for school projects and future public speaking opportunities. <br />

        Body Language Awareness: Students learn about non-verbal communication and how to use body language effectively to convey their messages and emotions. <br />

        Storytelling Skills: The course helps children become engaging storytellers, improving their ability to capture and hold an audience's attention. <br />

        Digital Communication Awareness: Kids are educated about responsible and respectful online communication, promoting safety and etiquette in the digital world. <br />

        Life Skills: The course equips children with essential life skills that will benefit them in various aspects of their lives, including school, family, and social interactions. <br />

        Practical Application: Through interactive activities, role-play, group discussions, and real-life examples, children can apply what they learn in practical scenarios. <br />

        Confidence in Public Speaking: Children build confidence in public speaking and overcome stage fright, preparing them for future presentations and speeches. <br />

        Cyberbullying Awareness: The course raises awareness about cyberbullying and how to prevent and respond to it, promoting safe online interactions.
             <br />
        Overall, this course empowers children with the communication skills necessary for effective self-expression, positive relationships, and success in both their academic and personal lives. It provides a solid foundation for their continued growth and development as effective communicators and responsible digital citizens. <br />
     </p>
         </div>
       </section>
     </div>

     {/* Course Structure and Syllabus */}
     <section className="section course-structure" id="course">
       <p className="PageHead" style={{ color: "#8b0194" }}>
         Course Structure and Syllabus
       </p>
       <h4 className='H4_Heading' style={{ color: "#8b0194", fontFamily: "sans-serif" }}>
         <b>Course Objectives</b>
       </h4>
       <p style={{ color: "#8b0194", fontFamily: "sans-serif",padding: "0 19% 0 25%" }}>
       <b>
       Build Confidence:
       </b>
        Encourage children to express themselves with confidence and clarity.
        <br />
        <b>
        Enhance Listening Skills: 
        </b>
        Teach kids active listening skills, fostering better understanding and empathy.   <br />
        <b>
        Develop Effective Speaking:
        </b>
        Improve articulation, pronunciation, and public speaking abilities.  <br />
        Boost Interpersonal Skills: Cultivate empathy, conflict resolution, and relationship-building skills.  <br />
        <b>
        Foster Presentation Skills: 
        </b>
        Introduce basic presentation techniques and public speaking confidence.  <br />
        <b>
        Promote Effective Body Language:
        </b>
        Teach kids the importance of non-verbal communication and body language.  <br />
        <b>
        Encourage Storytelling: 
        </b>
        Enhance narrative skills to make communication more engaging.
        <b>
        Practice Digital Communication: 
        </b>
        Teach responsible and respectful online communication.
        <br />
       </p>

       <div>
         <p className='moduleSection'>
           <img src={pic1} alt="" style={{ width: "2em", height: "2em" }} />
           Communication Skills Course For Kids
         </p>
       </div>

       {/* Module one */}
       <div className="modules">
         {/* round box */}
         <div className="round-box">
           <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
             {" "}
             Module
             <br />
             1
           </p>
         </div>
         {/* small boxes */}
         <div className="small-box" style={{ color: "#249935" }}>
           <p style={{ margin: "auto" }}>
             Activities
             <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>6</p>
           </p>

           <div
             className="small-box sm-box"
             
           >
             <p style={{ margin: "auto" }}>
               No.of Lessons
               <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>5</p>
             </p>
           </div>
         </div>

         <div className="large-box" style={{ color: "#249935" }}>
           <p style={{ margin: "auto" }}>
           Grammer
             <p
               style={{
                 fontWeight: "lighter",
                 fontSize: "15px",
                 marginBottom: "0px",
               }}
             >   
                This module is designed to provide students with a fundamental understanding of grammar rules and principles. This module aims to enhance their written and spoken communication by equipping them with the tools needed to construct grammatically correct sentences and express their ideas clearly and effectively.
             </p>
           </p>
         </div>
       </div>

         {/* Module Two */}
         <div className="modules">
         {/* round box */}
         <div className="round-box">
           <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
              
             Module
             <br />
             2
           </p>
         </div>
         {/* small boxes */}
         <div className="small-box" style={{ color: "#249935" }}>
           <p style={{ margin: "auto" }}>
             Activities
             <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>6</p>
           </p>

           <div
             className="small-box sm-box"
             
           >
             <p style={{ margin: "auto" }}>
               No.of Lessons
               <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>6</p>
             </p>
           </div>
         </div>

         <div className="large-box" style={{ color: "#249935" }}>
           <p style={{ margin: "auto" }}>
           Listening
             <p
               style={{
                 fontWeight: "lighter",
                 fontSize: "15px",
                 marginBottom: "0px",
               }}
             >
                The Listening module focuses on honing students' listening skills as a fundamental component of effective communication. This module uses various techniques & exercises of real-world scenarios & hands-on practice to develop their ability to actively listen, comprehend, and respond to verbal communication, ultimately enhancing their interpersonal and communication skills.
             </p>
           </p>
         </div>
       </div>
        {/* Module Three */}
        <div className="modules">
         {/* round box */}
         <div className="round-box">
           <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
              
             Module
             <br />
             3
           </p>
         </div>
         {/* small boxes */}
         <div className="small-box" style={{ color: "#249935" }}>
           <p style={{ margin: "auto" }}>
             Activities
             <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>10</p>
           </p>

           <div
             className="small-box sm-box"
             
           >
             <p style={{ margin: "auto" }}>
               No.of Lessons
               <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>10</p>
             </p>
           </div>
         </div>

         <div className="large-box" style={{ color: "#249935" }}>
           <p style={{ margin: "auto" }}>
           Speaking
             <p
               style={{
                 fontWeight: "lighter",
                 fontSize: "15px",
                 marginBottom: "0px",
               }}
             >
                The Speaking module is designed to empower students with the skills & confidence needed to express themselves effectively in various contexts, from everyday conversations to formal presentations. This module aims to enhance students' verbal communication abilities, including articulation, public speaking, & persuasive communication. You will learn how to engage your audience, express ideas clearly, & overcome speaking anxiety.
             </p>
           </p>
         </div>
       </div>
        {/* Module Four */}
        <div className="modules">
                {/* round box */}
                <div className="round-box">
                <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
                    
                    Module
                    <br />
                    4
                </p>
                </div>
                {/* small boxes */}
                <div className="small-box" style={{ color: "#249935" }}>
                <p style={{ margin: "auto" }}>
                    Activities
                    <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>5</p>
                </p>

                <div
                    className="small-box sm-box"
                    
                >
                    <p style={{ margin: "auto" }}>
                    No.of Lessons
                    <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>5</p>
                    </p>
                </div>
                </div>

                <div className="large-box" style={{ color: "#249935" }}>
                <p style={{ margin: "auto" }}>
                Reading
                    <p
                    style={{
                        fontWeight: "lighter",
                        fontSize: "15px",
                        marginBottom: "0px",
                    }}
                    >
                    The Reading module is designed to enhance students' reading abilities, comprehension skills, and critical thinking capabilities. This module focuses on equipping students with the tools they need to effectively extract information from written texts, critically analyze content, and communicate their understanding. We'll cover strategies for tackling various types of materials, from books to reports, helping you become a more confident and skilled reader.
                    </p>
                </p>
                </div>
        
        </div>
          {/* Module Five */}
          <div className="modules">
                {/* round box */}
                <div className="round-box">
                <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
                    
                    Module
                    <br />
                    5
                </p>
                </div>
                {/* small boxes */}
                <div className="small-box" style={{ color: "#249935" }}>
                <p style={{ margin: "auto" }}>
                    Activities
                    <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>6</p>
                </p>

                <div
                    className="small-box sm-box"
                    
                >
                    <p style={{ margin: "auto" }}>
                    No.of Lessons
                    <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>6</p>
                    </p>
                </div>
                </div>

                <div className="large-box" style={{ color: "#249935" }}>
                <p style={{ margin: "auto" }}>
                Writing
                    <p
                    style={{
                        fontWeight: "lighter",
                        fontSize: "15px",
                        marginBottom: "0px",
                    }}
                    >
                    The Writing module is designed to enhance students' writing abilities, fostering clear, concise, and persuasive written communication. This module focuses on developing the skills necessary to compose various types of written documents, including essays, reports, emails, and creative pieces. We cover grammar, sentence structure, and how to make your words flow smoothly.
                    </p>
                </p>
                </div>
        
        </div>
      
 

       <h6 style={{ color: "#8b0194", fontFamily: "sans-serif" }}>
         <b>Learn Communication Skills with 20+ Fun Activities & Projects</b>
       </h6>

       <div className="activityImagesContainer">
         <div class="row">
           <div class="col-lg mx-3">
             <img src={pic2} alt="" className='ActivityImages'/>
             <p className="mx-3">Comprehension</p>
           </div>

           <div class="col-lg mx-3">
             <img src={pic3} alt="" className='ActivityImages' />
             <p className="mx-3">Comprehension</p>
           </div>

           <div class="col-lg mx-3">
             <img src={pic4} alt="" className='ActivityImages'/>
             <p className="mx-3">MCQs</p>
           </div>

           <div class="col-lg mx-3">
             <img src={pic5} alt="" className='ActivityImages'/>
             <p className="mx-3">Listening </p>
           </div>

           <div class="col-lg mx-3">
             <img src={pic6} alt="" className='ActivityImages'/>
             <p className="mx-3">Speaking</p>
           </div>

         </div>
       </div>
     </section>

     {/* Fee Structure */}
     <FeeStructure subject="Communication Skills"/>

     {/* Testimonals */}
     <div class="  background" id="testimonal">
       <div class="row" style={{ width: "100%" }}>
         <div class="col-lg-8 mx-auto">
           <h2 className="Testimonal_Heading">Testimonials</h2>
           <div id="myCarousel" class="carousel slide" data-ride="carousel">
             {/* <!-- Carousel indicators --> */}
             <ol class="carousel-indicators">
               <li
                 data-target="#myCarousel"
                 data-slide-to="0"
                 class="active"
               ></li>
               <li data-target="#myCarousel" data-slide-to="1"></li>
             </ol>
             {/* <!-- Wrapper for carousel items --> */}
             <div class="carousel-inner">
               <div class="carousel-item active">
                 <div class="img-box">
                   <div className=" img-box1"></div>
                 </div>
                 <p
                   class="testimonial"
                   style={{ color: "black", fontSize: "3vh" }}
                 >
                   I enjoyed the learning experience with EduTechSage-Abacus.
                   The teacher was very friendly. I highly recommend
                   EduTechSage-Abacus.
                 </p>
                 <p style={{ color: "#ff7d00", fontSize: "3vh" }}>
                   <b>Mujtaba Khawaja Sahaf</b>
                 </p>
               </div>

               <div class="carousel-item">
                 <div class="img-box">
                   <div className="img-box2 "></div>
                 </div>
                 <p
                   class="testimonial"
                   style={{ color: "black", fontSize: "3vh" }}
                 >
                   I feel so proud when my class fellows call me ’BRAINIAC ’,
                   because I can effortlessly do mental calculations much
                   faster and accurate than my class mates. Thank you
                   Polymath-Kids.
                 </p>
                 <p style={{ color: "#ff7d00", fontSize: "3vh" }}>
                   <b>Wahaab Omer</b>, Age 9 Years
                 </p>
               </div>
             </div>
             {/* <!-- Carousel controls --> */}
             <a
               class="carousel-control-prev"
               href="#myCarousel"
               data-slide="prev"
             >
               <i class="fa fa-angle-left"></i>
             </a>
             <a
               class="carousel-control-next"
               href="#myCarousel"
               data-slide="next"
             >
               <i class="fa fa-angle-right"></i>
             </a>
           </div>
         </div>
       </div>
     </div>

     
   {/* Faqs */}
   <div style={{ maxWidth: '100%', backgroundColor: '#edffff' }} id="Faq">
      <MDBContainer className="mt-0" style={{ maxWidth: '180vh' }}>
        <MDBAccordion alwaysOpen initialActive={1}>
          <div className="text-center PageHead" style={{ color: "#8b0194" }}>FAQs</div>
          {faqData.map((faq, index) => (
            <MDBAccordionItem key={index} collapseId={index + 1} headerTitle={`Q: ${faq.question}`}>
              <strong>A: </strong>
              {faq.answer}
            </MDBAccordionItem>
          ))}
        </MDBAccordion>
      </MDBContainer>
    </div>
     
{/* Footer */}
<Footer/>

   </div>
  )
}

export default CPS
