import React, { useState,useContext,useEffect } from 'react'

import StudentNav from '../Navbars/StudentNav'
import AdminNav from '../Navbars/AdminNav'
import GuestNav from '../Navbars/GuestNav';
import TeacherNav from '../Navbars/TeacherNav';
import Footer from '../Footer'
import UserCard from './UserCard';
import './User.css'
import { UserContext } from '../UserContext';
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';


const User = () => {

      const [users, setUsers] = useState([]);

      useEffect(() => {
        fetchUsers();
      }, []);
      const [loading, setLoading] = useState(true);

      useEffect(() => {
        // Simulate loading time
        const loadUsers = async () => {
          // Simulate an async operation, like fetching data
          await new Promise(resolve => setTimeout(resolve, 2000));
          setLoading(false);
        };
    
        loadUsers();
      }, [users]);
      const fetchUsers =async () => {
        await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/getUsers`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then(response => response.json())
          .then(data => {
            if (data.Data) {
              const formattedUsers = data.users.map(user => ({
                ...user,
                dateofbirth: new Date(user.dateofbirth).toLocaleDateString('en-US', {
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric'
                })
              }));
              setUsers(formattedUsers);
            }
          })
          .catch(error => {
            console.log(error);
          });
      };
    
 const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
 useEffect(() => {
   const fetchData = async () => {
    await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
       credentials: 'include',
     }).then(response => {
         console.log("Response:",response);
       response.json().then(userInfo => {
         console.log("userinfo:",userInfo);
         setUserInfo(userInfo);

         if(userInfo?.usertype)
         {
           const uType=userInfo?.usertype;
           setUserRole(uType);
         }
       const uname = userInfo?.username;
       const avatar = userInfo?.avatar; // Store the avatar in a separate variable
       console.log("Uname in user.js: ", uname);
      //  console.log("Avatar in: ", avatar);

       })
       .catch(err=> console.log("Error in useeffect:",err))
     });
   }
   fetchData();
 }, [isAdmin, isTeacher, isStudent, isGuest]);

       
      const onLogin = (usertype) => {
         
        setUserRole(usertype);
      };
  const navigate=useNavigate();

      async function onLogout() {
        console.log("inside Onlogout.");
      await  fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
          credentials: 'include',
          method: 'POST',
        });
        setUserInfo(null);
        console.log("navigating");
        navigate('/');
        // console.log("navigated");
        setUserRole('guest');
      }

      const updateTable = async(userId, userType,userPreviousType)=>
      {
         // Check if the previous type is "student" and the new type is "teacher"
      // if (userPreviousType === 'Student' && userType === 'Teacher') {
        // Make an additional API call with the user ID and usertype in the body
      await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/setTables/${userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ usertype: userType,userPreviousType:userPreviousType }),
      })
        .then((response) => response.json())
        .then((data) => {
          // Handle the response of the additional API call
        })
        .catch((error) => {
          console.error('Error in additional API call:', error);
        });
    // }
      }
      //Update user's type  i.e Student,Teacher,Admin
      const updateUserType = async(userId, userType,userPreviousType) => {     
      // console.log('inside updateUserType')
      // console.log('userType',userType)
      // console.log('userPreviousType',userPreviousType)
      

       await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/updateType/${userId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ usertype: userType }),
        })
          .then((response) => response.json())
          .then((data) => {
            // Update the user's usertype in the local state
            const updatedUsers = users.map((user) =>
              user.id === userId ? { ...user, usertype: userType } : user
            );
            setUsers(updatedUsers); 
            updateTable(userId, userType,userPreviousType);
            
       
          })
     
          .catch((error) => {
            console.error('Error updating user usertype:', error);
          });
      };
      const updateUserStatus = async(userId, userStatus) => {     
        console.log('inside updateUserStatus')
  
         await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/updateUserStatus/${userId}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userstatus: userStatus }),
          })
            .then((response) => response.json())
            .then((data) => {
              // Update the user's usertype in the local state
              const updatedUsers = users.map((user) =>
                user.id === userId ? { ...user, deactivated: userStatus } : user
              );
              setUsers(updatedUsers);
            })
            .catch((error) => {
              console.error('Error updating user usertype:', error);
            });
        };
        const username = userInfo?.username;
        const _avatar_ = userInfo?.avatar;
  return (
    <>  
         {username && (
      <>
      {isAdmin && <AdminNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
      {isTeacher && <TeacherNav  username={username} avatar={_avatar_} onLogout={onLogout}/>}
      {isStudent && <StudentNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
    </>
        )}  
        {!username && (
          <>
          {isGuest && <GuestNav onLogin={onLogin} />}

          </>
        )}
    

    {/* <div className="MAcards-in-row">
       
     {users.map((user, index) => (
       <UserCard key={index} user={user} updateUserType={updateUserType} updateUserStatus={updateUserStatus} />
      ))}
      
 
    </div> */}

      {loading ? (
        <div className="loading-container">
          <ClipLoader size={50} color={"rgb(3, 180, 198)"} loading={loading} />
          <p>Please wait...</p>
        </div>
      ) : (
        <div className="MAcards-in-row">
          {users.map((user, index) => (
            <UserCard key={index} user={user} updateUserType={updateUserType} updateUserStatus={updateUserStatus} />
          ))}
        </div>
      )}
      

    <Footer/>
       </>
  );
};

export default User
