  

import axios from 'axios';

export const fetchImageData = async (setImageData, avatar) => {
  try {
    // console.log('fetchImageData.')
    const response = await axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getUimage/${avatar}`, {
      responseType: 'blob' // Ensure response is treated as a Blob object
    });

    // Convert the Blob object to a base64 string
    const reader = new FileReader();
    reader.readAsDataURL(response.data);
    reader.onloadend = () => {
      const base64Image = reader.result;

      // Set the image data
      setImageData(base64Image);
    };
  } catch (error) {
    console.error('Error fetching image data:', error);
  }
};
