import React,{useContext} from 'react'
import axios from 'axios';

import { useRef, useState, useEffect } from 'react'

import StudentNav from '../../Navbars/StudentNav'
import AdminNav from '../../Navbars/AdminNav'
import TeacherNav from '../../Navbars/TeacherNav';
import GuestNav from '../../Navbars/GuestNav';
import { UserContext } from '../../UserContext';
import Footer from '../../Footer'

import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';


import VideoActivityImage from './video-activity.png';
import './VideoActivity.css';


import { useLocation } from "react-router-dom";


const VideoActivity = () => {


  const selectedCourse = ""
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const activityId = searchParams.get("activityId");

  // console.log("activityId:", activityId);

  console.log("Activity ID:", activityId);


  const [activityLink, setActivityLink] = useState('');

  useEffect(() => {
    const fetchActivityLink = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getActivityLink/${activityId}`);
        const { ActivityLink } = response.data;
        setActivityLink(ActivityLink);
      } catch (error) {
        console.error('Error retrieving activity link:', error);
      }
    };

    fetchActivityLink();
  }, [activityId]);


  const handleCourseSelect = (course) => {
    console.log("Selected Course in Home:", course);
    selectedCourse(course);
  };

 const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
 
    useEffect(() => {
      const fetchData = async () => {
       await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
          credentials: 'include',
        }).then(response => {
            console.log("Response:",response);
          response.json().then(userInfo => {
            console.log("userinfo:",userInfo);
            setUserInfo(userInfo);

            if(userInfo?.usertype)
            {
              const uType=userInfo?.usertype;
              setUserRole(uType);
            }
          const uname = userInfo?.username;
          const avatar = userInfo?.avatar; // Store the avatar in a separate variable
          console.log("Uname: ", uname);
          console.log("Avatar: ", avatar);

          })
          .catch(err=> console.log("Error in useeffect:",err))
        });
      }
      fetchData();
    }, [isAdmin, isTeacher, isStudent, isGuest]);
  const determineUserRole = (usertype) => {
        console.log("In home.js:",usertype)
        if(usertype === 'Student')
          return "Student";
        else if(usertype === 'Teacher')
          return "Teacher";
        else if(usertype === 'Admin')
          return "Admin";
        else
          return "guest";
  };
  
  
 const navigate=useNavigate();
  const onLogin = (usertype) => {
    const userRole = determineUserRole(usertype);
    console.log("Calling setUserRole.")
    setUserRole(userRole);
  };

  function onLogout() {
      console.log("inside Onlogout.");
      fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
        credentials: 'include',
        method: 'POST',
      });
      setUserInfo(null);
      setUserRole('guest');
    }
      
    const username = userInfo?.username;
    const _avatar_ = userInfo?.avatar;
    const usertype = userInfo?.usertype;

       

  return (
    <div>



    
{username && (
        <>
             {isAdmin && <AdminNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
            {isTeacher && <TeacherNav  onCourseSelect={handleCourseSelect}  username={username} avatar={_avatar_} onLogout={onLogout}/>}
            {isStudent && <StudentNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
         </>
         )}  
         {!username && (
           <>
           {isGuest && <GuestNav onLogin={onLogin} />}
  
           </>
         )}


      <div className="MAVideoHeading">
        <div className="MAVideoActivity-image">
          <img src={VideoActivityImage} alt="" />
        </div>

        <div className="MAVideoActivity-text">
          <h3>Watch Video</h3>
        </div>
      </div>

      <div className="MAVideoContainer">
        {activityLink ? (
          <iframe
            width="560"
            height="315"
            src={activityLink}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        ) : (
          <p>Loading video...</p>
        )}
      </div>
      <Footer/>
    </div>
  );
};

export default VideoActivity;