import React,{useContext} from 'react'
import axios from 'axios';
import '../ManageUser/User.css'
// import ProfilePic from "./user.png";
import { toast } from 'react-toastify';
import StudentNav from '../Navbars/StudentNav'
import AdminNav from '../Navbars/AdminNav'
import TeacherNav from '../Navbars/TeacherNav';
import GuestNav from '../Navbars/GuestNav';
import { UserContext } from '../UserContext';
import Footer from '../Footer'
// import './ActivityStartPage.css'
import { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';


const StudentDashboard = ({user}) => {
         
    
  const [courses, setCourses] = useState();
  const [modules, setModules] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedModule, setSelectedModule] = useState('');
  const [selectedLesson, setSelectedLesson] = useState('');
  const [currentSelectedCourse, setCurrentSelectedCourse] = useState('');
  const [currentSelectedModule, setCurrentSelectedModule] = useState('');
  const [currentSelectedLesson, setCurrentSelectedLesson] = useState('');

  const handleCourseChange = (event) => {
    const courseId = event.target.value;
    

    axios
        .get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getCourseName/${courseId}`)
        .then((response) => {
          console.log("It's Course:", response.data.course);
          setSelectedCourse(response.data.course);
          setCurrentSelectedCourse(response.data.course);
        })
        .catch((error) => {
          console.error('Error fetching course:', error);
        });

    console.log("This is Selected Course Object: ", event.target.value);

    // setSelectedCourse(courseId);
    // setSelectedModule("");
    // setSelectedLesson("");
    if(courseId == currentSelectedCourse.Course_id){

      // console.log("I am inside...")
      // console.log("currentSelectedModule: ", currentSelectedModule)
      // console.log("currentSelectedLesson: ", currentSelectedLesson)
      setSelectedModule(currentSelectedModule);
      setSelectedLesson(currentSelectedLesson);
    }
    else{
      setSelectedModule("");
      setSelectedLesson("");
    }

    // Fetch modules based on the selected course
    axios
      .get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getModulesByCourse/${courseId}`)
      .then((response) => {
        console.log('Modules:', response.data);
        if (response.data.Data !== false) {
          console.log("I am here assigning modules... ")
          setModules(response.data);
        }
        
      })
      .catch((error) => {
        console.error('Error fetching modules:', error);
      });
  };

  const handleModuleChange = (event) => {

    // console.log("I am in Module Change.....")
    const moduleId = event.target.value;


    axios
        .get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getModuleName/${moduleId}`)
        .then((response) => {
          console.log('Module Name is yes:', response.data.module);
          setSelectedModule(response.data.module);
          setCurrentSelectedModule(response.data.module);
        })
        .catch((error) => {
          console.error('Error fetching module name:', error);
        });



    // setSelectedModule(moduleId);

    setSelectedLesson("");
    if(moduleId == currentSelectedModule.Module_id){
      setSelectedLesson(currentSelectedLesson);
    }
    else{
      setSelectedLesson("");
    }

    // Fetch lessons based on the selected module
    axios
      .get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getLessonsByModule/${moduleId}`)
      .then((response) => {
        console.log('Lessons:', response.data);
        if (response.data.Data !== false) {
          console.log("I am here assigning modules... ")
          setLessons(response.data);
        }
        
      })
      .catch((error) => {
        console.error('Error fetching lessons:', error);
      });
  };

  const handleLessonChange = (event) => {
    const Lesson_id = event.target.value;
    // console.log("I am in checking Lesson ID: ")
    // console.log("Event.target lesson is: ", Lesson_id)
    // console.log("SetCurrent Lesson ID is: ", currentSelectedLesson)


    axios
        .get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getLessonName/${Lesson_id}`)
        .then((response) => {
          console.log('Lesson Name:', response.data.lesson);
          setSelectedLesson(response.data.lesson);
          setCurrentSelectedLesson(response.data.lesson);
        })
        .catch((error) => {
          console.error('Error fetching lesson name:', error);
        });


    // setSelectedLesson(Lesson_id);

  }


  const saveStudentModule = (userId, moduleId) => {

    const apiUrl = `${process.env.REACT_APP_LOCAL_SERVER_API}/saveModule`;

    // Get Student_id based on user.user_Id
    axios
      .get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getStudentId/${userId}`)
      .then((response) => {

        const studentId = response.data.Student_id;
        console.log("Student ID = :", studentId)
        const startDate = new Date().toISOString().split('T')[0]; // Get the current date as the start date
        const data = {
          Module_id: moduleId,
          Student_id: studentId,
          StartDate: startDate,
          EndDate: null,
          Score: 0,
          Complete: 0,
        };

        // Save the module for the user
        axios
          .post(apiUrl, data)
          .then((response) => {
            console.log('Module saved successfully:', response.data);
            // Handle any further actions after successful module save
          })
          .catch((error) => {
            console.error('Error saving module:', error);
            // Handle error cases
          });
      })
      .catch((error) => {
        console.error('Error fetching student ID:', error);
        // Handle error cases
      });
  };



  //Saving the Student Lesson
  const saveStudentLesson = (userId, lessonId) => {
    const apiUrl = `${process.env.REACT_APP_LOCAL_SERVER_API}/saveLesson`;

    // Get Student_id based on user.user_Id
    axios
      .get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getStudentId/${userId}`)
      .then((response) => {
        const studentId = response.data.Student_id;
        console.log("Student ID:", studentId);
        const startDate = new Date().toISOString().split('T')[0]; // Get the current date as the start date
        const data = {
          Lesson_id: lessonId,
          Student_id: studentId,
          StartDate: startDate,
          EndDate: null,
          Complete: 0,
        };

        // Save the lesson for the user
        axios
          .post(apiUrl, data)
          .then((response) => {
            console.log('Lesson saved successfully:', response.data);
            // Handle any further actions after successful lesson save
          })
          .catch((error) => {
            console.error('Error saving lesson:', error);
            // Handle error cases
          });
      })
      .catch((error) => {
        console.error('Error fetching student ID:', error);
        // Handle error cases
      });
  };




  // Saving the Student Module Project Assignment
  const saveStudentModuleProjectAssignment = (userId, moduleId) => {
    const apiUrl = `${process.env.REACT_APP_LOCAL_SERVER_API}/saveStudentModuleProjectAssignments`;

    axios
      .get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getStudentId/${userId}`)
      .then((response) => {
        const studentId = response.data.Student_id;
        console.log("Student ID:", studentId);


      const currentDate = new Date(); // Get the current date
      const submissionDate = new Date(currentDate.getTime() + (7 * 24 * 60 * 60 * 1000)); // Add 7 days to the current date

      const data = {
        Module_id: moduleId,
        Student_id: studentId,
        SubmissionDate: submissionDate.toISOString().split('T')[0],
      };

    // Save the module project assignment for the student
    axios
      .post(apiUrl, data)
      .then((response) => {
        console.log('Module project assignment saved successfully:', response.data);
        // Handle any further actions after successful save
      })
      .catch((error) => {
        console.error('Error saving module project assignment:', error);
        // Handle error cases
      });

    })
  };



  const saveStudentLessonProjectAssignment = (userId, lessonId) => {
    const apiUrl = `${process.env.REACT_APP_LOCAL_SERVER_API}/saveStudentLessonProjectAssignments`;

    axios
      .get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getStudentId/${userId}`)
      .then((response) => {
        const studentId = response.data.Student_id;
        console.log("Student ID:", studentId);

        const currentDate = new Date(); // Get the current date
        const submissionDate = new Date(currentDate.getTime() + (7 * 24 * 60 * 60 * 1000)); // Add 7 days to the current date

        const data = {
          Lesson_id: lessonId,
          Student_id: studentId,
          SubmissionDate: submissionDate.toISOString().split('T')[0],
        };

        // Save the lesson project assignment for the student
        axios
          .post(apiUrl, data)
          .then((response) => {
            console.log('Lesson project assignment saved successfully:', response.data);
            // Handle any further actions after successful save
          })
          .catch((error) => {
            console.error('Error saving lesson project assignment:', error);
            // Handle error cases
          });
      });
  };



  const saveStudentLessonActivities = (userId, lessonId) => {
    const apiUrl = `${process.env.REACT_APP_LOCAL_SERVER_API}/saveStudentLessonActivities`;

    axios
      .get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getStudentId/${userId}`)
      .then((response) => {
        const studentId = response.data.Student_id;
        console.log("Student ID:", studentId);

        const data = {
          Lesson_id: lessonId,
          Student_id: studentId,
        };

        // Save the lesson project assignment for the student
        axios
          .post(apiUrl, data)
          .then((response) => {
            console.log('Student Lesson Activities saved successfully:', response.data);
            // Handle any further actions after successful save
          })
          .catch((error) => {
            console.error('Error saving Student Lesson Activities:', error);
            // Handle error cases
          });
      });
  };






  const handleSave = () => {
    // Check if all fields are selected
    if (!selectedCourse || !selectedModule || !selectedLesson) {
      toast.error('Please select a course, module, and lesson.', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
      return;
    }

    // Make API call to save the data
    const data = {
      user_id: user.User_id,
      course_id: selectedCourse.Course_id,
      module_id: selectedModule.Module_id,
      lesson_id: selectedLesson.Lesson_id,
    };

    axios
      .post(`${process.env.REACT_APP_LOCAL_SERVER_API}/saveUserCurrentStatus`, data)
      .then((response) => {
        console.log('Save response:', response.data);
        toast.success('Data saved successfully.', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      })
      .catch((error) => {
        console.error('Error saving data:', error);
        toast.error('Error saving data.', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      });

      //Insert Student_Module
      saveStudentModule(user.User_id, selectedModule.Module_id);
      saveStudentLesson(user.User_id, selectedLesson.Lesson_id);
      saveStudentModuleProjectAssignment(user.User_id, selectedModule.Module_id);
      saveStudentLessonProjectAssignment(user.User_id, selectedLesson.Lesson_id);
      saveStudentLessonActivities(user.User_id, selectedLesson.Lesson_id);


  };

    const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
     
useEffect(() => {
    const fetchData = async () => {
    await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
        credentials: 'include',
    }).then(response => {
        console.log("Response:",response);
        response.json().then(userInfo => {
        console.log("userinfo:",userInfo);
        setUserInfo(userInfo);

        if(userInfo?.usertype)
        {
            const uType=userInfo?.usertype;
            setUserRole(uType);
        }
        const uname = userInfo?.username;
        const avatar = userInfo?.avatar; // Store the avatar in a separate variable
        console.log("Uname: ", uname);
        console.log("Avatar: ", avatar);

        })
        .catch(err=> console.log("Error in useeffect:",err))
    });
    }
    fetchData();
}, [isAdmin, isTeacher, isStudent, isGuest]);
  const determineUserRole = (usertype) => {
        console.log("In home.js:",usertype)
        if(usertype === 'Student')
          return "Student";
        else if(usertype === 'Teacher')
          return "Teacher";
        else if(usertype === 'Admin')
          return "Admin";
        else
          return "guest";
  };  
 const navigate=useNavigate();
  const onLogin = (usertype) => {
    const userRole = determineUserRole(usertype);
    console.log("Calling setUserRole.")
    setUserRole(userRole);
  };
  function onLogout() {
      console.log("inside Onlogout.");
      fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
        credentials: 'include',
        method: 'POST',
      });
      setUserInfo(null);
      setUserRole('guest');
    }
      
    const [userType, setUserType] = useState('user.usertype');
      const username = userInfo?.username;
      const _avatar_ = userInfo?.avatar;

  return (
    <div>
        {/* Navbar */}
    {username && (
       <>
       {isAdmin && <AdminNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
       {isTeacher && <TeacherNav  username={username} avatar={_avatar_} onLogout={onLogout}/>}
       {isStudent && <StudentNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
     </>
       )}  
       {!username && (
         <>
         {isGuest && <GuestNav onLogin={onLogin} />}

         </>
       )}


        <div className="MAcard" >
                <div className="MAcard-head">
                        <div className="MAprofile-picture">
                            <img className="MAuser-profile-picture" src={_avatar_} alt="profile-picture"/>
                        </div>
                </div>
                {/* <!--User details--> */}
                <div className="MAuser-details">
                    <div className="MAname-email-address">
                        <p><b>Username: </b>user.username</p>
                        <p><b>Parent: </b>user.parentname</p>
                        <p>user.email</p>
                        <p>user.address</p>
                        <p><i>userType</i></p>
                    </div>
                    <div className="MAdob-phone">
                        <p>user.dateofbirth</p>
                        <p>user.phonenumber</p>
                    </div>
                </div>

                {/* <!--Current Course and Module--> */}
                <div className="MAcourse-module" style={{padding:"1px"}}>
                <div className="MAcourse">
                {courses ? (
                    <select className="MAdropdown-menu MAdropdown-fixed-width" name="course" onChange={handleCourseChange}>
                    <option value="">{selectedCourse ? selectedCourse.coursename : 'Select a Course'}</option>
                    {courses
                    .filter((course) => selectedCourse.Course_id !== course.Course_id)
                    .map((course) => (
                        <option key={course.Course_id} value={course.Course_id}>
                        {course.coursename}
                        </option>
                    ))}
                </select>

                ) : (
                    <select className="MAdropdown-menu MAdropdown-fixed-width" name="course">
                    <option value="">No courses available</option>
                    </select>
                )}
                </div>

                <div className="MAmodule">
                {modules ? (
                    <select className="MAdropdown-menu MAdropdown-fixed-width" name="module" onChange={handleModuleChange}>
                    <option value="">{selectedModule ? selectedModule.modulename : 'Select a Module'}</option>
                    {modules
                        .filter((module) => selectedModule.Module_id !== module.Module_id)
                        .map((module) => (
                        <option key={module.Module_id} value={module.Module_id}>
                            {module.modulename}
                        </option>
                        ))}
                    </select>
                ) : (
                    <select className="MAdropdown-menu MAdropdown-fixed-width" name="module">
                    <option value="">Select a Module</option>
                    </select>
                )}
                </div>


                <div className="MAlesson">
                {lessons ? (
                    <select className="MAdropdown-menu MAdropdown-fixed-width" name="lesson" onChange={handleLessonChange}>
                    <option value="">{selectedLesson ? selectedLesson.lessonName : 'Select a Lesson'}</option>
                    {lessons
                        .filter((lesson) => selectedLesson.Lesson_id !== lesson.Lesson_id)
                        .map((lesson) => (
                        <option key={lesson.Lesson_id} value={lesson.Lesson_id}>
                            {lesson.lessonName}
                        </option>
                        ))}
                    </select>
                ) : (
                    <select className="MAdropdown-menu MAdropdown-fixed-width" name="lesson">
                    <option value="">Select a Lesson</option>
                    </select>
                )}
                </div>


                <div className="MAsave-button">
                <button onClick={handleSave}>
                    Save
                </button>
                </div>

                </div>

                


        </div>


       {/* Footer */}
       <Footer/>
    </div>
  )
}

export default StudentDashboard
