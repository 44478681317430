import React,{useContext} from 'react'

import StudentNav from '../../Navbars/StudentNav'
import AdminNav from '../../Navbars/AdminNav'
import TeacherNav from '../../Navbars/TeacherNav';
import GuestNav from '../../Navbars/GuestNav';
import { UserContext } from '../../UserContext';
import Footer from '../../Footer'
import './ActivityResult.css'
import '../ActivityStart/ActivityStartPage.css'
import { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import { FaCheck, FaTimes } from 'react-icons/fa';
import img from './activityResult.jpeg';import axios from 'axios';

// import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const ActivityResult = () => {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const studentId = queryParams.get('studentId');
  const activityId = queryParams.get('activityId');


  const [studentName, setStudentName] = useState('');

  useEffect(() => {
    const fetchStudentName = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/getStudentName/${studentId}`);
        const data = await response.json();
        const { studentName } = data;
        setStudentName(studentName);
      } catch (error) {
        console.error('Error fetching student name:', error);
      }
    };

    fetchStudentName();
  }, [studentId]);


  const [activityName, setActivityName] = useState('');

  useEffect(() => {
    const fetchActivityName = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/getActivityName/${activityId}`);
        const data = await response.json();
        // const { activityName } = data;
        // console.log("Activity Name: ", data.activityName[0].ActivityName)
        setActivityName(data.activityName[0].ActivityName);
      } catch (error) {
        console.error('Error fetching activity name:', error);
      }
    };

    fetchActivityName();
  }, [activityId]);


  

  const [activityResults, setActivityResults] = useState([]);
  const [correctPercentage, setCorrectPercentage] = useState(0);
  const [marksPercentage, setMarksPercentage] = useState(0);
  const [totalTimeTaken, setTotalTimeTaken] = useState(0);
  const [numCorrectAnswers, setNumCorrectAnswers] = useState(0);
  const [totalNumOfQuestion, setTotalNumOfQuestion] = useState(0);
  const [totalGainedMarks, setTotalGainedMarks] = useState(0);
  const [maxMarks, setMaxMarks] = useState(0);

  useEffect(() => {
    const fetchActivityResults = async () => {
      try {
        // const activityId = 6; // Replace with the actual activity ID
        // const studentId = 1; // Replace with the actual student ID

        const response = await axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getActivityResult/${activityId}/${studentId}`);
        const data = response.data.activityResult;
        console.log("Activity Results:",data);
        setActivityResults(data);

        // Calculate correct percentage
        const totalQuestions = data.length;
        const correctAnswers = data.filter((result) => result.correct === 1).length;
        const calculatedCorrectPercentage = (correctAnswers / totalQuestions) * 100;
        setCorrectPercentage(calculatedCorrectPercentage);

        // Calculate marks percentage
        const maxMarks = data.reduce((acc, result) => acc + result.marks, 0);
        const totalMarks = data.reduce((acc, result) => acc + (result.correct === 1 ? result.marks : 0), 0);
        const calculatedMarksPercentage = (totalMarks / maxMarks) * 100;
        setMarksPercentage(calculatedMarksPercentage);

        // Calculate sum of time taken
        const calculatedTotalTimeTaken = data.reduce((acc, result) => acc + result.timetaken, 0);
        setTotalTimeTaken(calculatedTotalTimeTaken);

        // Calculate number of correct and incorrect answers
        setNumCorrectAnswers(correctAnswers);
        setTotalNumOfQuestion(totalQuestions);

        // Calculate total gained marks and maximum marks
        const totalGainedMarks = data.reduce((acc, result) => acc + (result.correct === 1 ? result.marks : 0), 0);
        
        setTotalGainedMarks(totalGainedMarks);
        setMaxMarks(maxMarks);

      } catch (error) {
        console.error('Error fetching activity results:', error);
      }
    };

    fetchActivityResults();
  }, []);

    function ActivitiesRecord({ ActivityRecord }) {
      const isCorrect = ActivityRecord.correctAnswer === ActivityRecord.userAnswer;
      
      return (
        <tbody>
          <tr>
            <td className='CourseRegistrationTable-td'>
              {ActivityRecord.correct ? <FaCheck style={{ color: 'green' }} /> : <FaTimes style={{ color: 'red' }} />}
            </td>
            <td className='CourseRegistrationTable-td'>{ActivityRecord.question}</td>
            <td className='CourseRegistrationTable-td'>{ActivityRecord.marks}</td>
            <td className='CourseRegistrationTable-td'>{ActivityRecord.difficultylevel}</td>
            <td className='CourseRegistrationTable-td'>{ActivityRecord.correctanswer}</td>
            <td className='CourseRegistrationTable-td'>{ActivityRecord.StudentAnswer}</td>
            <td className='CourseRegistrationTable-td'>{ActivityRecord.timetaken} sec</td>
          </tr>
        </tbody>
      );
    }
      
    const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
     
    useEffect(() => {
      const fetchData = async () => {
       await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
          credentials: 'include',
        }).then(response => {
            console.log("Response:",response);
          response.json().then(userInfo => {
            console.log("userinfo:",userInfo);
            setUserInfo(userInfo);

            if(userInfo?.usertype)
            {
              const uType=userInfo?.usertype;
              setUserRole(uType);
            }
          const uname = userInfo?.username;
          const avatar = userInfo?.avatar; // Store the avatar in a separate variable
          console.log("Uname: ", uname);
          console.log("Avatar: ", avatar);

          })
          .catch(err=> console.log("Error in useeffect:",err))
        });
      }
      fetchData();
    }, [isAdmin, isTeacher, isStudent, isGuest]);
    const determineUserRole = (usertype) => {
            console.log("In home.js:",usertype)
            if(usertype === 'Student')
            return "Student";
            else if(usertype === 'Teacher')
            return "Teacher";
            else if(usertype === 'Admin')
            return "Admin";
            else
            return "guest";
    };
    const navigate=useNavigate();
    const onLogin = (usertype) => {
        const userRole = determineUserRole(usertype);
        console.log("Calling setUserRole.")
        setUserRole(userRole);
    };

    function onLogout() {
      console.log("inside Onlogout.");
      fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
        credentials: 'include',
        method: 'POST',
      });
      setUserInfo(null);
      setUserRole('guest');
    }
      
      const username = userInfo?.username;
      const _avatar_ = userInfo?.avatar;
      const filteredRecords = [
        {
            question: "2, 2, 3, 3, 4, 4, 5, 5",
            marks: "1",
            difficultyLevel: "1",
            correctAnswer: "28",
            userAnswer: "28",
            timeTaken: "32/sec"
          }, {
            question: "2, 2, 3, 3, 4, 4, 5, 5",
            marks: "1",
            difficultyLevel: "1",
            correctAnswer: "28",
            userAnswer: "29",
            timeTaken: "32/sec"
          },
      ];
      

  return (
    <div>
      
    {/* Navbar */}
    {username && (
       <>
       {isAdmin && <AdminNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
       {isTeacher && <TeacherNav  username={username} avatar={_avatar_} onLogout={onLogout}/>}
       {isStudent && <StudentNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
     </>
       )}  
       {!username && (
         <>
         {isGuest && <GuestNav onLogin={onLogin} />}

         </>
       )}

        <div className='edit-bar'>
        <h3 className='student-ActivityStartPage'>
        {studentName}  - {activityName}
        <img src={img} alt="Image" className='ActivityResultHeadSectionImage' />
        </h3>
      </div>

      <div className='TeacherRatingSection'>
             <table className='CourseRegistrationTable' style={{width:'50vw'}}>
            <thead>
                <tr className='CourseRegistrationTable-tr'>
                    <th className='CourseRegistrationTable-th'>Correct {numCorrectAnswers}/{totalNumOfQuestion} ({correctPercentage}%)</th>
                    <th className='CourseRegistrationTable-th'>Questions</th>
                    <th className='CourseRegistrationTable-th'>Marks {totalGainedMarks}/{maxMarks} ({marksPercentage}%)</th>
                    <th className='CourseRegistrationTable-th'>Difficulty Level</th>
                    <th className='CourseRegistrationTable-th'>Correct Answer</th>
                    <th className='CourseRegistrationTable-th'>{studentName} Answer</th>
                    <th className='CourseRegistrationTable-th'>Time Taken {totalTimeTaken} sec</th>
                </tr>
            </thead>
        
            {/* {filteredRecords.map((AR, index) => (
            <ActivitiesRecord key={index} ActivityRecord={AR} />
            ))} */}
            {activityResults.map((AR, index) => (
            <ActivitiesRecord key={index} ActivityRecord={AR} />
            ))}
            </table>
        </div>
      
       {/* Footer */}
       <Footer/>
    </div>
  )
}

export default ActivityResult
