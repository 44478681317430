import React, { useState } from 'react';
import axios from 'axios';

const GenerateMeetingLink = () => {
  const [generatedLink, setGeneratedLink] = useState('');

  const generateLink = async () => {
    try {
      const userAndCourseData = {
        user: {
          User_id: 1,
          username: 'john_doe',
          usertype: 'student',
          email: 'john@example.com',
        },
        course: {
          ClassID: 'ADOBE115',
          teacherId: 3,
          Course_id: 115,
          ClassLink: 'https://xhxc3s04-8082.inc1.devtunnels.ms/1a59ceea-bde2-498e-8b4c-a8675619d39c',
          classname: 'Adobe XD',
          ClassType: 'Lecture',
          ClassDay: null,
          ClassTime: null,
          ClassNo: 15,
        },
      };

      console.log("UserAndCourseData: ", userAndCourseData);

      const response = await axios.post('https://kashmir.connectxpert.new.kashmir-shawl.com/generateLink', userAndCourseData);
      console.log("response: ", response);
      setGeneratedLink(response.data.newLink);
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '2rem' }}>
      <button
        onClick={generateLink}
        style={{
          backgroundColor: '#4CAF50',
          border: 'none',
          color: 'white',
          padding: '10px 20px',
          textAlign: 'center',
          textDecoration: 'none',
          display: 'inline-block',
          fontSize: '16px',
          margin: '4px 2px',
          cursor: 'pointer',
          borderRadius: '4px',
        }}
      >
        Generate Link
      </button>
      {generatedLink && (
        <div
          style={{
            marginTop: '2rem',
            padding: '1rem',
            backgroundColor: '#f1f1f1',
            borderRadius: '8px',
            maxWidth: '80%',
            wordWrap: 'break-word',
          }}
        >
          <h3 style={{ marginTop: 0, marginBottom: '0.5rem' }}>Generated Link:</h3>
          <a
            href={generatedLink}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#007bff', textDecoration: 'none' }}
          >
            {generatedLink}
          </a>
        </div>
      )}
    </div>
  );
};

export default GenerateMeetingLink;