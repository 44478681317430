import React, { useState, useContext, useEffect } from 'react';
import StudentNav from '../Navbars/StudentNav';
import AdminNav from '../Navbars/AdminNav';
import TeacherNav from '../Navbars/TeacherNav';
import GuestNav from '../Navbars/GuestNav'; 
import Footer from '../Footer';
import './TeacherRating.css';
import { UserContext } from '../UserContext';
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStarHalf, faStar as solidStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as regularStar } from '@fortawesome/free-regular-svg-icons';

const TeacherRating = () => {

    function RatingRecord({TeacherRatingRecord}) {
        // console.log(TeacherRatingRecord)

        const MAX_STARS = 5;
        const filledStars = Math.floor(TeacherRatingRecord.average_rating);
        const hasHalfStar = TeacherRatingRecord.average_rating - filledStars >= 0.5;
        
        const stars = [];
        for (let i = 0; i < MAX_STARS; i++) {
            if (i < filledStars) {
                stars.push(<FontAwesomeIcon key={i} icon={solidStar} className="filled-star" />);
            } else if (i === filledStars && hasHalfStar) {
                stars.push(<FontAwesomeIcon key={i} icon={faStarHalf} className="half-star" />);
            } else {
                stars.push(<FontAwesomeIcon key={i} icon={regularStar} className="empty-star" />);
            }
        }
        
        return (
             <>
             
            <tbody id="table-rows">
                <tr className='CourseRegistrationTable-tr'>
                    <td className='CourseRegistrationTable-td'>{TeacherRatingRecord.coursename}</td>
                    <td className='CourseRegistrationTable-td'>{TeacherRatingRecord.firstname} {TeacherRatingRecord.lastname}</td>
                    <td className='CourseRegistrationTable-td'>{parseFloat(TeacherRatingRecord.average_rating).toFixed(1)}</td>
        
                    <td className='CourseRegistrationTable-td'>{stars}</td>
                    
                </tr>
            </tbody> 
             
            </>
        );
    };
    const [selectedCourse, setSelectedCourse] = useState('');
    const [selectedTeacher, setSelectedTeacher] = useState('');
    
    const [teachers, setTeachers] = useState([]);
    const [courses, setCourses] = useState([]);

    const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
    useEffect(() => {
      const fetchData = async () => {
       await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
          credentials: 'include',
        }).then(response => {
           //  console.log("Response:",response);
          response.json().then(userInfo => {
           //  console.log("userinfo:",userInfo);
            setUserInfo(userInfo);
           
            if(userInfo?.usertype)
            {
              const uType=userInfo?.usertype;
              setUserRole(uType);
            }
          const uname = userInfo?.username;
          const avatar = userInfo?.avatar; // Store the avatar in a separate variable
         //  console.log("Uname Admin Register", uname);
         //  console.log("Avatar in: ", avatar);
   
          })
          .catch(err=> console.log("Error in useeffect:",err))
        });
      }
      fetchData();
    }, [isAdmin, isTeacher, isStudent, isGuest]);
   
    const onLogin = (usertype) => {
         
        setUserRole(usertype);
      };
    const navigate=useNavigate();
    
    async function onLogout() {
        console.log("inside Onlogout.");
    await  fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
        credentials: 'include',
        method: 'POST',
        });
        setUserInfo(null);
        console.log("navigating");
        navigate('/');
        // console.log("navigated");
        setUserRole('guest');
    }
    const [teachersRating, setTeachersRating] = useState([]);
    const fetchTeachersRating = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/fetchTeachersRating`);
        console.log('response.data:',response.data)
          setTeachersRating(response.data);
        } catch (error) {
          console.error('Error fetching teachers rating:', error);
        }
      };
    const fetchAllCourses=()=>
  {
        // Fetch all courses
      axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getCourses`)
      .then(response => {
        const coursesData = response.data.courses;
        setCourses(coursesData);
      })
      .catch(error => {
        console.log('Error fetching courses:', error);
        // Handle the error as needed
      });
    }
    const fetchAllTeachers = () => {
        axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getTeachersNames`)
        .then(response => {
          const teachersData = response.data.teachers;
        //   console.log("teachersData:", teachersData);
          setTeachers(teachersData);
        })
        .catch(error => {
          console.log('Error fetching teachers:', error);
        });
      };

      const handleCourseChange = (event) => {
        const { value } = event.target;
        const selectedCourse = courses.find((course) => course.Course_id === parseInt(value));
        setSelectedCourse(selectedCourse);
      };
      const handleTeacherChange = (event) => {
        const { value } = event.target;
        console.log("value:",value)
        const selectedTeacher = teachers.find((teacher) => teacher.teacher_id === parseInt(value));
        console.log("selectedTeacher:",selectedTeacher)
        setSelectedTeacher(selectedTeacher);
      };

    useEffect(() => {
        fetchTeachersRating();
        fetchAllCourses();
        fetchAllTeachers();
    }, []);
    const username = userInfo?.username;
    const _avatar_ = userInfo?.avatar;
    const filterRecords = (record) => {
        // If both course and teacher are selected, filter based on both
        if (selectedCourse && selectedTeacher) {
          return record.course_id === selectedCourse.Course_id && record.teacher_id === selectedTeacher.teacher_id;
        }
        // If only course is selected, filter based on course
        else if (selectedCourse) {
          return record.course_id === selectedCourse.Course_id;
        }
        // If only teacher is selected, filter based on teacher
        else if (selectedTeacher) {
          return record.teacher_id === selectedTeacher.teacher_id;
        }
        // If neither course nor teacher is selected, return true (show all records)
        return true;
      };
      const filteredRecords = teachersRating.filter(filterRecords);

  return (
    <>
    {/* Navbar */}
    {username && (
        <>
        {isAdmin && <AdminNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
        {isTeacher && <TeacherNav  username={username} avatar={_avatar_} onLogout={onLogout}/>}
        {isStudent && <StudentNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
      </>
        )}  
        {!username && (
          <>
          {isGuest && <GuestNav onLogin={onLogin} />}
          </>
        )}

        <div className='edit-bar'>
        <h3 className='student-EditHeading'>
            Teachers Rating
        </h3>
        </div>
       <div className='TeacherFilterSection'>
            <div className='TeacherFilter'>
                <select id='TPcourseDropdown'
                    value={selectedCourse ? selectedCourse.Course_id.toString() : ''} 
                    onChange={handleCourseChange}
                    >
                        <option value=''>Select Course</option>
                        {courses.map((course) => (
                        <option key={course.Course_id} value={course.Course_id}>
                            {course.coursename}
                        </option>
                        ))}
                </select>

            
                <select
                    id='TPteacherDropdown'
                    value={selectedTeacher ? selectedTeacher.teacher_id.toString() : ''} 
                    onChange={handleTeacherChange}
                    >
                    <option value=''>Select Teacher</option>
                        {teachers.map((teacher) => (
                        <option key={teacher.teacher_id} value={teacher.teacher_id}>
                            {teacher.firstname} {teacher.lastname}
                        </option>
                        ))}
                </select>
            </div>
        </div>  

        <div className='TeacherRatingSection'>
             <table className='CourseRegistrationTable' style={{width:'50vw'}}>
            <thead>
                <tr className='CourseRegistrationTable-tr'>
                    <th className='CourseRegistrationTable-th'>Course</th>
                    <th className='CourseRegistrationTable-th'>Teacher</th>
                    <th className='CourseRegistrationTable-th'>Average Rating</th>
                    <th className='CourseRegistrationTable-th'>Rating</th>
                </tr>
            </thead>
        
            {filteredRecords.map((TRR, index) => (
            <RatingRecord key={index} TeacherRatingRecord={TRR} />
            ))}
            </table>
        </div>
       
        <Footer/>
    </>
  )
}

export default TeacherRating
