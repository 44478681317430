import React,{useContext,useEffect } from 'react'
import  "./SummerCamp.css";
import StudentNav from '../Navbars/StudentNav'
import AdminNav from '../Navbars/AdminNav'
import GuestNav from '../Navbars/GuestNav';
import TeacherNav from '../Navbars/TeacherNav';
import { UserContext } from '../UserContext';
import Footer from "../Footer";
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import Flag from 'react-world-flags';
import summer1 from './SummerImages/summer1.png'
import summer2 from './SummerImages/summer2.png'
import {Link} from 'react-router-dom'
import MP from '../CourseImages/MathPortal.png'
import CEP from '../CourseImages/EnggineeringPortal.png'
import AP from '../CourseImages/Architecture.png'
import SP from '../CourseImages/spacePortal.png'
import GP from '../CourseImages/GraphicDesigningPortal.png'
import CommP from '../CourseImages/CommunicationPortal.png'
import Sc from '../CourseImages/Scratch.png'
import app from '../CourseImages/app.png'
import game from '../CourseImages/game.png'
import web from '../CourseImages/web.png'
import Ai from '../CourseImages/Ai.png'
import Cpp from '../CourseImages/CPP.png'
import python from '../CourseImages/Py.png'
import php from '../CourseImages/PHP.png'
import ML from '../CourseImages/ML.png'
import Dc from '../CourseImages/Declamation.png'


const SummerCountry = ({ bgColor,country, text, flagIcons }) => {
    return (
        <div className='Summer_country' style={{ backgroundColor: bgColor }}>
             {flagIcons.map((code, index) => (
                <Flag key={code} code={code.toLowerCase()} style={{width:"30%",margin:'2px'}}/>
            ))}
            <br />
            <b>{country}</b> <br />
            <b>{text}</b> <br />
            <br />
           
        </div>
    );
};
const CourseCard = ({link, bgColor1, bgColor2, courseName, ageGroup, noOfLessons, lessonsPerWeek, imageSrc }) => {
    return (
        <div className='SummerCamp_CoursesCard' style={{border:`1px solid ${bgColor1}`}}>
            <div className='SummerCourseCard' style={{ backgroundColor: bgColor1 }}>
                <div className='Section1_CoursesCard'>
                    <b>{courseName}</b>
                    <p>Suitable for Ages {ageGroup}</p>
                </div>
                <div className='CoursesCards_Image'>
                    <img src={imageSrc} alt="course" style={{ width: '10vw' }} />
                </div>
            </div>
            <div style={{ backgroundColor: bgColor2, borderRadius: "2vh" }}>
                <div className='Section2_CoursesCard' style={{color:bgColor1}}>
                    <div className="header">Age Group</div>
                    <div className="header">No. of Lessons</div>
                    <div className="header">Lessons/week</div>
                    <div>{ageGroup}</div>
                    <div>{noOfLessons}</div>
                    <div>{lessonsPerWeek}</div>
                </div>
                <div className='Section3_CoursesCard'>
                    <h3 style={{color:bgColor1}}>SUMMER DEAL 50% OFF</h3>
                    <Link to={link} className='SummerCamp_CourseDetailbtn' style={{backgroundColor:bgColor1}}>
                        Course Details
                    </Link>
                </div>
            </div>
        </div>
    );
};
const SummerCamp = () => {
    const countriesData = [
        { bgColor: '#cd99f7', country: 'Pakistan',text:'10 June to 4 July 8 July to 1 August', flagIcons: ['PK'] },
        { bgColor: '#d6b15c', country: 'UK / Norway / Turkey',text:'8 July to 1 August', flagIcons: ['GB','NO','TR'] },
        { bgColor: '#61ce66', country: 'Your Country',text:'10 June to 4 July 8 July to 1 August', flagIcons: ['SA','AE','QA'] },
        { bgColor: '#13abe2', country: 'Your Country',text:'8 July to 1 August', flagIcons: ['US','CA'] },
    ];
    
    const courseData = [
        {
            bgColor1: '#096fdb',
            bgColor2: '#d5e9ff',
            courseName: 'Mental Math Abacus',
            ageGroup: '8 to 14',
            noOfLessons: 12,
            lessonsPerWeek: 3,
            imageSrc: MP,
            link:'/abacus_home'
        },
        {
            bgColor1: '#83a6c6',
            bgColor2: '#deeaf5',
            courseName: 'Civil Engineering',
            ageGroup: '12 to 17',
            noOfLessons: 12,
            lessonsPerWeek: 3,
            imageSrc: CEP,
            link:'/engineering_civil'

        },
        {
            bgColor1: '#fe5e00',
            bgColor2: '#ffece2',
            courseName: 'Architecture & Interior Design',
            ageGroup: '12 to 17',
            noOfLessons: 12,
            lessonsPerWeek: 3,
            imageSrc: AP,
            link:'/arch_interior_design'
        },

        {
            bgColor1: '#224c8d',
            bgColor2: '#cddef7',
            courseName: 'Astronomy',
            ageGroup: '12 to 17',
            noOfLessons: 12,
            lessonsPerWeek: 3,
            imageSrc: SP,
            link:'/space_sc_atronomy'

        },

        {
            bgColor1: '#9d4194',
            bgColor2: '#fedffb',
            courseName: 'Graphic Designing',
            ageGroup: '8 to 17',
            noOfLessons: 12,
            lessonsPerWeek: 3,
            imageSrc: GP,
            link:'/graphic_designing_portal'
        },

        {
            bgColor1: '#fa374e',
            bgColor2: '#fce4e7',
            courseName: 'Scratch Programming',
            ageGroup: ' 5 to 10',
            noOfLessons: 12,
            lessonsPerWeek: 3,
            imageSrc: Sc,
            link:'/coding_scratch'
        },

        
        {
            bgColor1: '#fa374e',
            bgColor2: '#fce4e7',
            courseName: 'App Development',
            ageGroup: '10 to 17',
            noOfLessons: 12,
            lessonsPerWeek: 3,
            imageSrc:app,
            link:'/coding_app_development'
        },

        {
            bgColor1: '#fa374e',
            bgColor2: '#fce4e7',
            courseName: 'Game Development',
            ageGroup: '10 to 17',
            noOfLessons: 12,
            lessonsPerWeek: 3,
            imageSrc: game,
            link:'/coding_game_development'
        },

        {
            bgColor1: '#fa374e',
            bgColor2: '#fce4e7',
            courseName: 'Web Development',
            ageGroup: '10 to 17',
            noOfLessons: 12,
            lessonsPerWeek: 3,
            imageSrc: web,
            link:'/coding_web_development'
        },
        
        {
            bgColor1: '#fa374e',
            bgColor2: '#fce4e7',
            courseName: 'AI & Data Science',
            ageGroup: '10 to 17',
            noOfLessons: 12,
            lessonsPerWeek: 3,
            imageSrc: Ai,
            link:'/coding_ai_datascience'
        },

        {
            bgColor1: '#fa374e',
            bgColor2: '#fce4e7',
            courseName: 'C++',
            ageGroup: '13 to 17',
            noOfLessons: 12,
            lessonsPerWeek: 3,
            imageSrc: Cpp,
            link:'/coding_cplus'
        },

        {
            bgColor1: '#fa374e',
            bgColor2: '#fce4e7',
            courseName: 'Python',
            ageGroup: '13 to 17',
            noOfLessons: 12,
            lessonsPerWeek: 3,
            imageSrc: python,
            link:'/coding_python'
        },

        {
            bgColor1: '#fa374e',
            bgColor2: '#fce4e7',
            courseName: 'Machine Learning',
            ageGroup: '13 to 17',
            noOfLessons: 12,
            lessonsPerWeek: 3,
            imageSrc: ML,
            link:'/coding_machine_learning'
        },

        {
            bgColor1: '#fa374e',
            bgColor2: '#fce4e7',
            courseName: 'PHP',
            ageGroup: '13 to 17',
            noOfLessons: 12,
            lessonsPerWeek: 3,
            imageSrc: php,
            link:'/coding_php'
        },

        {
            bgColor1: '#a68969',
            bgColor2: '#f1dbc5',
            courseName: 'Communication Skills',
            ageGroup: '13 to 17',
            noOfLessons: 12,
            lessonsPerWeek: 3,
            imageSrc: CommP,
            link:'/comm_comm_skills'

        },

        {
            bgColor1: '#a68969',
            bgColor2: '#f1dbc5',
            courseName: 'Declamation Contest',
            ageGroup: '13 to 17',
            noOfLessons: 12,
            lessonsPerWeek: 3,
            imageSrc: Dc,
            link:'/comm_comm_skills'
        },

    ];
    const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
 
    useEffect(() => {
      
      const fetchData = async () => {
       await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
          credentials: 'include',
        }).then(response => {
            console.log("Response:",response);
          response.json().then(userInfo => {
            console.log("userinfo:",userInfo);
            setUserInfo(userInfo);

            if(userInfo?.usertype)
            {
              const uType=userInfo?.usertype;
              setUserRole(uType);
            }
          const uname = userInfo?.username;
          const avatar = userInfo?.avatar; // Store the avatar in a separate variable
          console.log("Uname: ", uname);
          console.log("Avatar: ", avatar);

          })
          .catch(err=> console.log("Error in useeffect:",err))
        });
      }
      fetchData();
    }, [isAdmin, isTeacher, isStudent, isGuest]);
    const determineUserRole = (usertype) => {
            console.log("In home.js:",usertype)
            if(usertype === 'Student')
            return "Student";
            else if(usertype === 'Teacher')
            return "Teacher";
            else if(usertype === 'Admin')
            return "Admin";
            else
            return "guest";
    };
    
    const navigate=useNavigate();
    const onLogin = (usertype) => {
        const userRole = determineUserRole(usertype);
        console.log("Calling setUserRole.")
        setUserRole(userRole);
    };

    function onLogout() {
      console.log("inside Onlogout.");
      fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
        credentials: 'include',
        method: 'POST',
      });
      setUserInfo(null);
      setUserRole('guest');
    }
      
    const username = userInfo?.username;
    const _avatar_ = userInfo?.avatar;

  return (
    <>
      {username && (
       <>
       {isAdmin && <AdminNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
       {isTeacher && <TeacherNav  username={username} avatar={_avatar_} onLogout={onLogout}/>}
       {isStudent && <StudentNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
     </>
        )}  
        {!username && (
          <>
          {isGuest && <GuestNav onLogin={onLogin} />}

          </>
        )}
        <div className='PolymatchBg'>
            <div class='SummerSection_1'>
                    <div class='SummerSection_btngrid'>
                        <div class='SummerSection_btn1' >Get Early Bird Discount</div>
                        <div class='SummerSection_btn2' >Book Free Demo Class</div>
                        <div class='SummerSection_btn3' >
                            <a href="#SUMMER2024" style={{color:'white'}}>
                                SUMMER CAMP 2024
                            </a>
                        </div>
                        <div class='SummerSection_btn4' >
                            <a href="#COURSES2024" style={{color:'white'}}>
                            SUMMER COURSES 2024
                            </a>
                            
                        </div>
                    </div>
                    <div class='SummerSection_video'>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/KG5Zac2e74w?si=yFEiyob7dzyVkV1f" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
            </div>

            <div className='SummerCamp24' id='SUMMER2024'>
                <b>SUMMER CAMP 2024</b>
                <p>Are you looking for a summer camp that offers more than just fun and education? <br />
                    Polymath-Kids' Summer Camp is the perfect choice for your child! <br />
                    Our one-month program is packed with exciting courses that are designed according to the STEM education standards, guaranteeing an educational experience that is both enriching and engaging.<br />
                    <br />
                    We understand that every child is unique, and that's why we offer a wide variety of courses tailored to different age groups. From Scratch programming to AI Data Science, Mental maths with abacus, Graphic Designing, Civil Engineering,  and much more, we have age-appropriate programs that are sure to spark your child's curiosity and foster a love for learning.<br />
                    <br />
                    From the first day to the last, we guarantee that your child will be engaged, entertained, and educated. And the best part? Your child will receive a certificate of completion, recognizing their hard work and dedication in completing the program. So what are you waiting for? Don't miss out on this incredible opportunity and give them a summer they'll never forget!<br />
                    <br />
                    We have limited seats, already filling up fast, so book your child's spot now!<br />
                    </p>
            </div>

            <div className='SummerSection_2'>
                <div className='Summer_countries'>
                    {countriesData.map((country, index) => (
                        <SummerCountry
                            key={index}
                            bgColor={country.bgColor}
                            text={country.text}
                            country={country.country}
                            flagIcons={country.flagIcons}
                        />
                    ))}
                </div>
                <div className='SummerCamp_Image'>
                    <img src={summer1} alt="Image"/>
                </div>
            </div>

            <div className='SummerCamp24' id='COURSES2024'>
                <b>SUMMER COURSES 2024</b>
                <p>
                Are you worried about your child spending their summer holidays with nothing to do? Do you want to give them a head start in their education? 
                <br />
                Polymath-Kids is excited to announce our Summer Courses! We know that your child's education is important to you, and that's why we are offering all our courses at fast-track. And the best part is that we are offering it at huge discount which is only available for this summer. So that you and your child can fully utilize the summer break and benefit from our STEM courses and 21st-century life skills. 
                <br />
                <br />

                From coding to engineering, business to graphic designing, maths to astronomy, and everything in between, we've got the courses your child needs to succeed. And the best part? your child can complete any of our courses within their summer break and receive a certificate of completion recognizing their hard work and dedication. 
                <br />
                <br />

                So why wait? Give your child the gift of education this summer with Polymath-Kids Summer Courses. Sign up now and take advantage of this opportunity to watch your child's capabilities reach new heights and become a Polymath!   
                <br />
                <br />

                </p>
            </div>
            <div className='SummerSection_2'>
                <div className='Summer_countries'>
                    {countriesData.map((country, index) => (
                        <SummerCountry
                            key={index}
                            bgColor={country.bgColor}
                            text={country.text}
                            country={country.country}
                            flagIcons={country.flagIcons}
                        />
                    ))}
                </div>
                <div className='SummerCamp_Image'>
                    <img src={summer2} alt="Image"/>
                </div>
            </div>

            <div className='SummerCamp_CoursesCards'>
                {/* <div className='SummerCamp_CoursesCard'>
                    <div className='SummerCourseCard'>
                        <div className='Section1_CoursesCard'>
                            <b>Mental Math Abacus</b>
                            <p>Suitable for Ages 8 to 14</p>
                        </div>
                        <div className='CoursesCards_Image'>
                            <img src={MP} alt="image" style={{width:'10vw'}}/>
                        </div>
                    </div>
                    <div style={{backgroundColor:'#d5e9ff',borderRadius:"2vh"}}>
                        <div className='Section2_CoursesCard'>
                                <div class="header">Age Group</div>
                                <div class="header">No. of Lessons</div>
                                <div class="header">Lessons/week</div>
                                <div>12 to 13</div>
                                <div>12</div>
                                <div>3</div>
                        </div>
                        <div className='Section3_CoursesCard'>
                            <h3>SUMMER DEAL 50% OFF</h3>
                            <Link to='' className='SummerCamp_CourseDetailbtn'>
                                    Course Details
                            </Link>
                        </div>
                    </div>
                   
                    
                </div> */}

                {courseData.map((course, index) => (
                                <CourseCard
                                    key={index}
                                    link={course.link}
                                    bgColor1={course.bgColor1}
                                    bgColor2={course.bgColor2}
                                    courseName={course.courseName}
                                    ageGroup={course.ageGroup}
                                    noOfLessons={course.noOfLessons}
                                    lessonsPerWeek={course.lessonsPerWeek}
                                    imageSrc={course.imageSrc}
                                />
                ))}
            </div>
        </div>
                
      {/* Footer */}
      <Footer />
    </>
  )
}

export default SummerCamp
