import React, { useState, useEffect } from 'react';
import axiosInstance from './axiosInstance';
import './managecontentstyle/ManageCourse.css';
import ManageModuleProject from './ManageModuleProject';
import EditModule from './EditModule';
import EditLesson from './EditLesson';
import ManageLessonProject from './ManageLessonProject';
import ManageLessonActivities from './ManageLessonActivities';
import ManageLessonPlans from './ManageLessonPlans';

const ManageCourse = () => {
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedModule, setSelectedModule] = useState(null);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [courses, setCourses] = useState([]);
  const [modules, setModules] = useState([]);
  const [lessons, setLessons] = useState([]);

  useEffect(() => {
    fetchCourses();
  }, []);

  const fetchCourses = () => {
    axiosInstance.post('/api/managecontent/getAllCourses')
      .then(response => {
        const sortedCourses = response.data.slice().sort((a, b) => {
          const coursenameA = a.coursename;
          const coursenameB = b.coursename;
          return coursenameA.localeCompare(coursenameB);
        });

        setCourses(sortedCourses);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const fetchModules = (courseId) => {
    axiosInstance.post('/api/managecontent/getAllModule', { courseId })
      .then(response => {
        const filteredModules = response.data.filter(module => module.Course_id === courseId);
        filteredModules.sort((a, b) => a.ModuleNumber - b.ModuleNumber);
        setModules(filteredModules);
        setSelectedModule(null);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const fetchLessons = (moduleId) => {
    axiosInstance.post('/api/managecontent/getAllLesson', { moduleId })
      .then(response => {
        const filteredLessons = response.data.filter(lesson => lesson.Module_id === moduleId);
        filteredLessons.sort((a, b) => a.lessonno - b.lessonno);
        setLessons(filteredLessons);
        setSelectedLesson(null);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleCourseSelect = (courseId) => {
    setSelectedCourse(courseId);
    fetchModules(courseId);
  };

  const handleModuleSelect = (moduleId) => {
    setSelectedModule(moduleId);
    fetchLessons(moduleId);
  };

  const handleLessonSelect = (lessonId) => {
    setSelectedLesson(lessonId);
  };

  useEffect(() => {
    
  }, [selectedLesson]);

  return (
    <div className="manage-course-container">
      <div className="course-list">
        <h6>Courses:</h6>
        {courses.map(course => (
          <div
            key={course.Course_id}
            className={`course-item ${selectedCourse === course.Course_id ? 'selected' : ''}`}
            onClick={() => handleCourseSelect(course.Course_id)}
          >
            {course.coursename}
          </div>
        ))}
      </div>
      {selectedCourse && (
        <div className="module-list">
          <h6>Modules:</h6>
          {modules.map(module => (
            <div
              key={module.Module_id}
              className={`module-item ${selectedModule === module.Module_id ? 'selected' : ''}`}
              onClick={() => handleModuleSelect(module.Module_id)}
            >
              {module.modulename}
            </div>
          ))}
        </div>
      )}
      {selectedModule && (
        <div className="lesson-list">
          <h6>Lessons:</h6>
          {lessons.map(lesson => (
            <div
              key={lesson.Lesson_id}
              className={`lesson-item ${selectedLesson === lesson.Lesson_id ? 'selected' : ''}`}
              onClick={() => handleLessonSelect(lesson.Lesson_id)}
            >
              {lesson.lessonname}
            </div>
          ))}
        </div>
      )}
       {selectedLesson && (
        <div className="additional-components">
          <EditModule key={`edit-module-${selectedLesson}`} course={selectedCourse} module={selectedModule} />
          <ManageModuleProject key={`manage-module-project-${selectedLesson}`} module={selectedModule} />
          <EditLesson key={`edit-lesson-${selectedLesson}`} lessonid={selectedLesson} />
          <ManageLessonProject key={`manage-lesson-project-${selectedLesson}`} lessonid={selectedLesson} />
          <ManageLessonPlans key={`manage-lesson-plans-${selectedLesson}`} lessonid={selectedLesson} />
          <ManageLessonActivities key={`manage-lesson-activities-${selectedLesson}`} lessonid={selectedLesson} />
        </div>
      )}
    </div>
  );
};

export default ManageCourse;
