import React, { useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

// Renders errors or successfull transactions on the screen.
function Message({ content }) {
  return <p>{content}</p>;
}

function PayPalPayment() {
  const initialOptions = {
    "client-id": "ARAT0gePt6fZ6jQQNlNTFlCg7mwjbuIAhDBQf0tWIu2N00znaWFWMptC3omSEmuT5atS_xFtupkdFO1F",
    currency: "USD",
    "data-page-type": "product-details",
    components: "buttons",
    "data-sdk-integration-source": "developer-studio",
  };

  const [message, setMessage] = useState("");

  return (
    <div className="App" style={{margin: "40vh"}}>
      <PayPalScriptProvider options={initialOptions}>
        <PayPalButtons
          style={{
            shape: "rect",
            layout: "vertical",
            color: "gold",
            label: "paypal",
            height: 48,
            tagline: false,
            shape: "pill"
          }}
          createOrder={(data, actions) => {
            return actions.order.create({
              purchase_units:[
                {
                  description: "Mathematics Course Payment",
                  amount:{
                    value: 60
                  }
                }
              ]
            })
            
          }}
          onApprove={async (data,actions) => {
            const order = await actions.order.capture();
            console.log("Order: ", order);
          }} 
        />
      </PayPalScriptProvider>
      <Message content={message} />
    </div>
  );
}

export default PayPalPayment; 







































// import React, { useState } from "react";
// // import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
// import { PayPalButton } from "react-paypal-button-v2";

// function PayPalPayment() {
//   const [state, setState] = useState(0);
//   return (
//     <div>
//       <input
//         type="number"
//         value={state}
//         onChange={(e) => setState(e.target.value)}
//       />
//       <PayPalButton
//         options={{
//           clientId: "ARAT0gePt6fZ6jQQNlNTFlCg7mwjbuIAhDBQf0tWIu2N00znaWFWMptC3omSEmuT5atS_xFtupkdFO1F",
//           currency: "USD",
//         }}
//         amount={state}
//         onSuccess={(details, data) => {
//           alert("Transaction completed by " + details.payer.name.given_name);

//           console.log({ details, data });
//         }}
//       />
//     </div>
//   );
// }

// export default PayPalPayment; 
