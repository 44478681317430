import React, { useState, useContext, useEffect } from 'react';
import StudentNav from '../Navbars/StudentNav';
import AdminNav from '../Navbars/AdminNav';
import TeacherNav from '../Navbars/TeacherNav';
import GuestNav from '../Navbars/GuestNav'; 
import Footer from '../Footer';
import './TeacherProfile.css';
import { UserContext } from '../UserContext';
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

const TeacherProfile = () => {
    
    const [selectedTeacher, setSelectedTeacher] = useState('');
  
    // Dummy data for courses and teachers (replace with actual data)
    
    // const teachers = ['Teacher A', 'Teacher B', 'Teacher C'];
   
    const [educationData, setEducationData] = useState([]);
    const [experienceData, setExperienceData] = useState([]);
    const [bankDetails, setBankDetails] = useState([]);
    const [availabilityTimes, setAvailabilityTimes] = useState([]);
    const [teacher_courses, setTeacherCourses] = useState([]);
    const [teachers, setTeachers] = useState([]);
    const [courses, setCourses] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState(null);


    const fetchEducationData = (teacherId) => {
        axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getEducationDetails/${teacherId}`)
          .then(response => {
            const data = response.data;
            // console.log("educationData:",data);
            setEducationData(data.educationDetails);
          })
          .catch(error => {
            console.log('Error fetching education details', error);
          });
    }
    const fetchExperienceData = (teacherId) => {
        axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getExperienceDetails/${teacherId}`)
          .then(response => {
            const data = response.data;
            // console.log("educationData:",data);
            setExperienceData(data.experienceDetails);
          })
          .catch(error => {
            console.log('Error fetching education details', error);
          });
    }
    const fetchBankDetails = async (teacherId) => {
        await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/getBankDetails/${teacherId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then((response) => response.json())
          .then((data) => {
            // console.log("data:",data.bankDetails)
            setBankDetails(data.bankDetails);
           
          })
          .catch((error) => {
            console.error('Error updating user usertype:', error);
          });
    };
    const fetchAvailability = async (teacherId) => {
        // console.log("teacherID:",teacherId);
        try {
          const response = await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/getTeacherAvailability/${teacherId}`);
          const data = await response.json();
          // console.log(data); // Handle the response data as needed
          setAvailabilityTimes(data.availabilityData);
        } catch (error) {
          console.error('Error fetching teacher availability:', error);
        }
    };
    const fetchTeacherCourses =async (teacherid) => {
        // console.log("userid:",userid)
        axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getTeacherTaughtCoursesByAdmin/${teacherid}`)
          .then(response => {
            const data = response.data;
            setTeacherCourses(data.courseInfo);
          })
          .catch(error => {
            console.log('Error fetching teacher courses:', error);
          });
      };
    const fetchTeacherId = async (userId) => {
        // console.log("In fetchTeacherId.");
        // console.log("userId: ",userId);
        await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/getTeacherId/${userId}`, {
            method: 'GET',
            headers: {
            'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
            // console.log("data:",data)
            // console.log("data.teacherid:",data.teacherId);
    
            // fetchBankDetails(data.teacherId);
            // fetchAvailability(data.teacherId);
            fetchEducationData(data.teacherId);
            // fetchExperienceData(data.teacherId);
            // fetchTeacherCourses(data.teacherId);
    
            // console.log("fetchTeacherCourses:",teacher_courses);
    
            
            })
            .catch((error) => {
            console.error('Error updating user usertype:', error);
            });
    };
    const fetchAllCourses=()=>
  {
        // Fetch all courses
      axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getCourses`)
      .then(response => {
        const coursesData = response.data.courses;
        setCourses(coursesData);
      })
      .catch(error => {
        console.log('Error fetching courses:', error);
        // Handle the error as needed
      });
    }
    const fetchAllTeachers = (Course_id) => {
        axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getAllTeacherForACourse`, {
          params: {
            Course_id: Course_id // Pass Course_id as a query parameter
          }
        })
        .then(response => {
          const teachersData = response.data.teachers;
        //   console.log("teachersData:", teachersData);
          setTeachers(teachersData);
        })
        .catch(error => {
          console.log('Error fetching teachers:', error);
        });
      };
      
    const handleCourseChange = (event) => {
        const { value } = event.target;
        const selectedCourse = courses.find((course) => course.Course_id === parseInt(value));
        setSelectedCourse(selectedCourse);
        fetchAllTeachers(selectedCourse.Course_id)
        // console.log("selectedCourse:",selectedCourse.Course_id)

      };

      const handleTeacherChange = (event) => {
        const { value } = event.target;
        const selectedTeacher = teachers.find((teacher) => teacher.teacher_id === parseInt(value));
        setSelectedTeacher(selectedTeacher);
        fetchEducationData(selectedTeacher.teacher_id)
        fetchExperienceData(selectedTeacher.teacher_id)
        fetchBankDetails(selectedTeacher.teacher_id)
        fetchAvailability(selectedTeacher.teacher_id)
        fetchTeacherCourses(selectedTeacher.teacher_id)
        // console.log("selectedTeacher.teacher_id:",selectedTeacher.teacher_id)

      };

    const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
    useEffect(() => {
      const fetchData = async () => {
       await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
          credentials: 'include',
        }).then(response => {
           //  console.log("Response:",response);
          response.json().then(userInfo => {
           //  console.log("userinfo:",userInfo);
            setUserInfo(userInfo);
            fetchAllCourses()
            // fetchTeacherId(userInfo?.User_id);  

            if(userInfo?.usertype)
            {
              const uType=userInfo?.usertype;
              setUserRole(uType);
            }
          const uname = userInfo?.username;
          const avatar = userInfo?.avatar; // Store the avatar in a separate variable
         //  console.log("Uname Admin Register", uname);
         //  console.log("Avatar in: ", avatar);
   
          })
          .catch(err=> console.log("Error in useeffect:",err))
        });
      }
      fetchData();
    }, [isAdmin, isTeacher, isStudent, isGuest]);
   
    const onLogin = (usertype) => {
         
        setUserRole(usertype);
      };
        const navigate=useNavigate();
      
            async function onLogout() {
              console.log("inside Onlogout.");
            await  fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
                credentials: 'include',
                method: 'POST',
              });
              setUserInfo(null);
              console.log("navigating");
              navigate('/');
              // console.log("navigated");
              setUserRole('guest');
            }

            const username = userInfo?.username;
            const _avatar_ = userInfo?.avatar;

  return (
    <>
      {/* Navbar */}
      {username && (
        <>
        {isAdmin && <AdminNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
        {isTeacher && <TeacherNav  username={username} avatar={_avatar_} onLogout={onLogout}/>}
        {isStudent && <StudentNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
      </>
        )}  
        {!username && (
          <>
          {isGuest && <GuestNav onLogin={onLogin} />}
          </>
        )}

        <div className='edit-bar'>
        <h3 className='student-EditHeading'>
            Teachers Profile
        </h3>
        </div>
        <div className='TeacherFilterSection'>
            <div className='TeacherFilter'>
                <select id='TPcourseDropdown'
                    value={selectedCourse ? selectedCourse.Course_id.toString() : ''} 
                    onChange={handleCourseChange}>
                        <option value=''>Select Course</option>
                        {courses.map((course) => (
                        <option key={course.Course_id} value={course.Course_id}>
                            {course.coursename}
                        </option>
                        ))}
                </select>

            
                <select
                    id='TPteacherDropdown'
                    value={selectedTeacher ? selectedTeacher.teacher_id.toString() : ''} 
                    onChange={handleTeacherChange}
                    >
                    <option value=''>Select Teacher</option>
                        {teachers.map((teacher) => (
                        <option key={teacher.teacher_id} value={teacher.teacher_id}>
                            {teacher.firstname} {teacher.lastname}
                        </option>
                        ))}
                </select>
            </div>
        </div>  
        
        <div className='TP-Details'>
            <div className='TPDetail-LeftSection'>
                <div className='TP-MeetSection'>
                    <p>Meeting Link:</p>
                </div>
                <div className='TP-CurrentSchedule'>
                    <b>CURRENT SCHEDULE</b>
                </div>
                <div className='TP-Availability'>
                    <b>AVAILABILITY</b>
                    {availabilityTimes.map((item, index) => (
                        <div key={index}>
                        <span style={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
                          <p style={{ width: '50%' }}>{item.day}</p>
                          <p style={{ width: "50%" }}>{item.time}</p>
                        </span>
                        <hr />
                      </div>
                    ))}
                </div>
                <div className='TP-BankDetails'>
                    <b>BANK DETAILS</b>
                        <div>
                            <p><b>Bank Name: </b>{bankDetails.BankName}</p>
                            <p><b>IBAN: </b>{bankDetails.BankAccountIBAN}</p>
                            <p><b>Mobile Wallet No: </b>{bankDetails.MobileWalletNo}</p>
                            <p><b>Preference: </b>{bankDetails.Preference}</p>
                        </div>
                </div>

            </div>  
            <div className='TPDetail-RightSection'>
                <div className='TP-CoursesSection'>
                    <b>COURSES</b>
                    {teacher_courses.map((item, index) => (
                        <span>
                          <li style={{ width: '50%' }}>{item.coursename}</li>
                        </span>
                    ))}
                </div>

                <div className='TP-Education'>
                    <b>EDUCATION</b>
                    <div className='tableInfo' style={{display:'flex',alignItems:'center',justifyContent:'space-evenly',marginLeft:"5%"}}>
                    <label htmlFor="" className='TP-Label'>Degree Name</label>
                    <label htmlFor="" className='TP-Label'>Institute Name</label>
                    <label htmlFor="" className='TP-Label'>Course Duration </label>
                    <label htmlFor="" className='TP-Label'>Year</label>
                    </div>
                    <div>
                    {educationData.map((educationItem, index) => (
                        <div key={index} style={{margin:'0 0 2% 10%',width:"94%",display:"flex",alignItems:"center",justifyContent:'space-evenly'}}>
                        <p style={{width:'25%'}}>{educationItem.degreeName}</p>
                        <p style={{width:'25%'}}>{educationItem.instituteName}</p>
                        <p style={{width:'25%'}}>{educationItem.duration}</p>
                        <p style={{width:'25%'}}>{educationItem.yearStart}</p>
                        <hr />
                        </div>
                    ))}
                    </div>
                </div>

                <div className='TP-Experience'>
                        <b>EXPERIENCE</b>
                    <div className='tableInfo' style={{display:'flex',alignItems:'center',justifyContent:'space-evenly',marginLeft:"5%"}}>
                    <label htmlFor="" className='TP-Label'>Designation</label>
                    <label htmlFor="" className='TP-Label'> Company Name</label>
                    <label htmlFor="" className='TP-Label'>Service Duration</label>
                    <label htmlFor="" className='TP-Label'>Year</label>
                    </div>
                    <div>
                {experienceData.map((experienceItem, index) => (
                    <div key={index} style={{marginLeft:'10%',width:"94%",display:"flex",alignItems:"center",justifyContent:'space-evenly'}}>
                    <p style={{width:'22%'}}>{experienceItem.Designation}</p>
                    <p style={{width:'22%'}}>{experienceItem.CompanyName}</p>
                    <p style={{width:'22%'}}>{experienceItem.DurationOfService}</p>
                    <p style={{width:'22%'}}>{experienceItem.YearStart}</p>
                    <hr />
                    
                    <br />
                    </div>
                ))}
                    </div>
                </div>

            </div> 

        </div>
        <Footer/>
    </>
  )
}

export default TeacherProfile
