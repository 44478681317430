import React ,{useRef, useState,useEffect }from 'react'
import './ResetPwPopup.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';
import OTPModal from './OTPModal';


const ResetPwPopup = ({isOpen, onClose}) => {
    const [UserName, setUserName] = useState(''); // State variable to store user email
    const [OTP, setOTP]= useState(''); 
    const [isDialogOpen2, setDialogOpen2] = useState(false);

    const handleOpenDialog = () => {
      // onClose();
      setDialogOpen2(true);
    };
  
    const handleCloseDialog2 = () => {
      onClose();
      setDialogOpen2(false);
    };
    
    const handleUserNameChange = (event) => {
      setUserName(event.target.value); // Update UserEmail state with the input value
    };

    const form = useRef();
    const  handleResetPasswordInquiry  = async(e) => {
      handleOpenDialog();
      e.preventDefault();
      axios.post(`${process.env.REACT_APP_LOCAL_SERVER_API}/getEmailByUsername`, { username: UserName })
          .then(async (response) => {
              // Assuming the API response contains an email field
              const email = response.data.email;
  
              if(email) {

                const otp = Math.floor(1000 + Math.random() * 9000);
                setOTP(otp);
                // Proceed to send the email via emailjs with OTP
                // const templateParams = {
                //     email, // Assuming you have an email template variable
                //     otp // Assuming you have an OTP template variable
                // };
                let dataSend = {
                  email: email,
                  otp: otp, // Assuming you have an OTP template variable
                };
            
                const res = await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/sendEmail`, {
                  method: "POST",
                  body: JSON.stringify(dataSend)
                  ,
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                })
                  // HANDLING ERRORS
                  .then((res) => {
                    console.log(res);
                    if (res.status > 199 && res.status < 300) {
                      alert("Send Successfully !");
                    }
                  });
                  // Proceed to send the email via emailjs
                  // emailjs.sendForm('service_p478mti', 'template_za7k209', form.current, 'P0xsNsNTFQBlZTnEa')
                  //     .then((result) => {
                  //         console.log(result.text);
                  //         toast.success('Reset Link has been sent', {
                  //             position: 'top-center',
                  //             autoClose: 3000, // Duration in milliseconds
                  //             hideProgressBar: false,
                  //             closeOnClick: true,
                  //             pauseOnHover: true,
                  //             draggable: true,
                  //         });
                  //     }, (error) => {
                  //         console.log(error.text);
                  //         toast.error('Failed to send reset link', {
                  //             position: 'top-center',
                  //             autoClose: 3000,
                  //             hideProgressBar: false,
                  //             closeOnClick: true,
                  //             pauseOnHover: true,
                  //             draggable: true,
                  //         });
                  //     });
              } else {
                  // Handle case where email is not found
                  toast.error('Username not found', {
                      position: 'top-center',
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                  });
              }
          })
          .catch((error) => {
              console.error('Error fetching email:', error);
              toast.error('An error occurred while fetching the email', {
                  position: 'top-center',
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
              });
          });
  };
    return (
      <div className={`modal ${isOpen ? 'open' : ''}`}>
        <div className="modal-overlay" onClick={onClose}></div>
        <div className="modal-content">
          <h3>Reset Password</h3>
          <p style={{ width: "50%", textAlign: 'center' }}>Enter the email address associated with your account, and we'll email you a link to reset your password.</p>
          <input
            type="email"
            value={UserName}
            onChange={handleUserNameChange}
            className='inp'
            placeholder="Enter your Username"
            style={{ width: "50%", textAlign: 'center', margin: '10px auto' }}
          />
          <button
            onClick={handleResetPasswordInquiry}
            
            className='ResetButton'
          >
            SEND OTP
          </button>
          {isDialogOpen2 && (
          <OTPModal isOpen2={isDialogOpen2} onClose2={handleCloseDialog2} SendedOtp={OTP} _username_={UserName}
          />
        )}
        </div>
      </div>
    );
};
export default ResetPwPopup