import React,{useContext} from 'react'
import './MCQActivity.css'


import StudentNav from '../../Navbars/StudentNav'
import AdminNav from '../../Navbars/AdminNav'
import TeacherNav from '../../Navbars/TeacherNav';
import GuestNav from '../../Navbars/GuestNav';
import { UserContext } from '../../UserContext';
import Footer from '../../Footer'

import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import { useLocation } from "react-router-dom";

import { fetchActivitiesImage } from '../fetchActivitiesImage';

import MCQActivityImage from './mental-activity.png'
import GoodImage from './good.png'
import CrossImage from './cross.png'
import FinishedImage from './finished.jpeg'
import { useRef, useState, useEffect } from 'react'
import axios from 'axios';
// import AdminNav from '../../Navbars/AdminNav'
// import Footer from '../../Footer'

const MCQActivity = () => {


    const selectedCourse = ""
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const activityId = searchParams.get("activityId");

    const [actualQuestions, setActualQuestions] = useState([]);
    const [currQuestion, setCurrQuestion] = useState([]);
    const [imageData, setImageData] = useState(null);

    const [count, setCount] = useState();
    const countRef = useRef();

    const totalQuestionsRef = useRef(0);
    const progressIncrement = useRef();
    
    useEffect(() => {
        // Define an async function to fetch the questions from the API
        async function fetchQuestions() {
          try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getActivityQuestions/${activityId}`);
            setActualQuestions(response.data);
            console.log(response.data[0]);
            setCurrQuestion(response.data[0]);

            console.log("..........Calling Fetch Image 01..........")
            console.log("Question Picture:",response.data[0].questionpicture)
            fetchActivitiesImage(setImageData,response.data[0].questionpicture);

            setCount(response.data[0].timealloted);
            countRef.current = response.data[0].timealloted;
            setMarksPerQuestion(response.data[0].marks);

            var total_marks = 0;
            var total_questions = 0;
            response.data.forEach(data => {
                total_marks += data.marks;
                total_questions++;
            });

            setTotalMarks(total_marks);
            setTotalQuestions(total_questions);

            totalQuestionsRef.current = total_questions;
            progressIncrement.current = (100/(totalQuestionsRef.current))*1.3;

          } catch (error) {
            console.error(error);
          }
        }
    
        // Call the async function to fetch the questions
        fetchQuestions();
      }, [activityId]);


      const submitButton = useRef(null);
      const GoodImageRef = useRef(null);
      const CrossImageRef = useRef(null);
      const FinishedImageRef = useRef(null);

      const optionRef1 = useRef(null)
      const optionRef2 = useRef(null)
      const optionRef3 = useRef(null)
      const optionRef4 = useRef(null)
    

    const [items, setItems] = useState([]);
    // const [currentQuestion, setCurrentQuestion] = useState(questions[0]);
    const [gainedMarks, setGainedMarks] = useState(0);
    const [questionNumber, setQuestionNumber] = useState(1);
    const [studentAnswer, setStudentAnswer] = useState("");
    const [totalQuestions, setTotalQuestions] = useState();
    const [marksPerQuestion, setMarksPerQuestion] = useState();
    const [totalMarks, setTotalMarks] = useState();
    
    const questionNumberRef = useRef(0);

    const [student_ID, setStudent_ID] = useState("2");
    const studentCurrAnswerRef = useRef(0);
    const marksObtainedCurrQuestionRef = useRef(0);
    const isCorrectRef = useRef(0);

    const gainedMarksRef = useRef(0);

    const ProgressRef = useRef(null);
    const progressCount = useRef(0);
    // const progressIncrement = useRef((100/(totalQuestions))*1.3);

    console.log("progress Increment is: ", progressIncrement.current);



    var intervalId;
    useEffect(() => {
            intervalId = setInterval(() => {
          countRef.current = countRef.current - 1;
          if (countRef.current === -1) {
            
            studentCurrAnswerRef.current = "";
            marksObtainedCurrQuestionRef.current = marksPerQuestion;
            isCorrectRef.current = 0;

            submitStudentQuestionResponse();


            questionNumberRef.current = questionNumberRef.current + 1;
            setQuestionNumber(questionNumber => questionNumber + 1);
            setCurrQuestion(actualQuestions[questionNumberRef.current]);

            console.log("..........Calling Fetch Image..........")
            fetchActivitiesImage(setImageData,actualQuestions[questionNumberRef.current].questionpicture);
            setMarksPerQuestion(actualQuestions[questionNumberRef.current].marks);
            setCount(actualQuestions[questionNumberRef.current].timealloted);
            setItems([...items, 0]);
            countRef.current = actualQuestions[questionNumberRef.current].timealloted;
            GoodImageRef.current.style.display = 'none';
            CrossImageRef.current.style.display = "inline-block";

            progressCount.current += progressIncrement.current;
            ProgressRef.current.style.width = `${progressCount.current}px`;

          } else {
            setCount(countRef.current);
          }
        }, 1000);
    
        // Return a cleanup function to clear the interval
        return () => clearInterval(intervalId);
      }, [questionNumber, count]);
    
      useEffect(() => {
        if (questionNumber === totalQuestions && count === 0) {

            studentCurrAnswerRef.current = "";
            marksObtainedCurrQuestionRef.current = marksPerQuestion;
            isCorrectRef.current = 0;
            submitStudentQuestionResponse();

            setItems([...items, 0]);
            clearInterval(intervalId);

            GoodImageRef.current.style.display = 'none';
            CrossImageRef.current.style.display = "inline-block";
          
            submitButton.current.style.display = "none";
            FinishedImageRef.current.style.display = "inline-block";

            progressCount.current += progressIncrement.current;
            ProgressRef.current.style.width = `${progressCount.current}px`;
        }
      }, [questionNumber, count]);

      const handleStudentAnswer = (event) =>{
        setStudentAnswer(event.target.value);
      }


      const totalTimeTakenOfAllQuestions = useRef(0);
      const totalTimeOfAllQuestions = useRef(0);

      const submitStudentQuestionResponse = async () => {
        // event.preventDefault();

        totalTimeTakenOfAllQuestions.current += currQuestion.timealloted - countRef.current - 1;
        totalTimeOfAllQuestions.current += currQuestion.timealloted;

        try {
            // console.log("I am in submitting response...")
            const response = await axios.post(`${process.env.REACT_APP_LOCAL_SERVER_API}/saveActivityQuestion`, {
                Question_id: currQuestion.Question_id,
                Student_id: student_ID,
                timetaken: currQuestion.timealloted - countRef.current - 1,
                StudentAnswer: studentCurrAnswerRef.current,
                MarksObtained: marksObtainedCurrQuestionRef.current,
                TotalTime: currQuestion.timealloted,
                correct: isCorrectRef.current
            });
            console.log(response.data);
        } catch (error) {
            console.error(error);
        }
    };




    const completeActivityRef = useRef();

    const submitCompleteActivityResponse = async () => {
        // e.preventDefault();
    
        try {
            console.log("I am in submitting Complete response...")
            const response = await axios.post(`${process.env.REACT_APP_LOCAL_SERVER_API}/saveActivity`, {
                Activity_id: activityId,
                Student_id: student_ID,
                MarksObtained: gainedMarksRef.current,
                MarksObtainedPercentage: gainedMarksRef.current / totalMarks * 100,
                Remarks: 'Good Efforts',
                SubmitActivityFile: '',
                TimeTaken: totalTimeTakenOfAllQuestions.current,
                TotalMarks: totalMarks,
                TotalTime: totalTimeOfAllQuestions.current,
                Complete: completeActivityRef.current,
            });
            console.log(response.data);
        } catch (error) {
            console.error(error);
        }
      };




      const handleSubmit = (event) => {
        event.preventDefault();
        console.log(studentAnswer);

        console.log("I am in handle submit...")

        if(questionNumber == totalQuestions){

            if(optionRef1.current.checked && currQuestion.option1 == currQuestion.correctanswer){
                setItems([...items, 1]);
                gainedMarksRef.current = gainedMarksRef.current + marksPerQuestion;
                setGainedMarks(gainedMarks => gainedMarks + marksPerQuestion);
                CrossImageRef.current.style.display = 'none';
                GoodImageRef.current.style.display = "inline-block";

                studentCurrAnswerRef.current = currQuestion.option1;
                marksObtainedCurrQuestionRef.current = marksPerQuestion;
                isCorrectRef.current = 1;


                submitStudentQuestionResponse();


            }
            else if(optionRef2.current.checked && currQuestion.option2 == currQuestion.correctanswer){
                setItems([...items, 1]);
                gainedMarksRef.current = gainedMarksRef.current + marksPerQuestion;
                setGainedMarks(gainedMarks => gainedMarks + marksPerQuestion);
                CrossImageRef.current.style.display = 'none';
                GoodImageRef.current.style.display = "inline-block";

                studentCurrAnswerRef.current = currQuestion.option2;
                marksObtainedCurrQuestionRef.current = marksPerQuestion;
                isCorrectRef.current = 1;

                submitStudentQuestionResponse();

            }
            else if(optionRef3.current.checked && currQuestion.option3 == currQuestion.correctanswer){
                setItems([...items, 1]);
                gainedMarksRef.current = gainedMarksRef.current + marksPerQuestion;
                setGainedMarks(gainedMarks => gainedMarks + marksPerQuestion);
                CrossImageRef.current.style.display = 'none';
                GoodImageRef.current.style.display = "inline-block";

                studentCurrAnswerRef.current = currQuestion.option3;
                marksObtainedCurrQuestionRef.current = marksPerQuestion;
                isCorrectRef.current = 1;

                submitStudentQuestionResponse();
            }
            else if(optionRef4.current.checked && currQuestion.option4 == currQuestion.correctanswer){
                setItems([...items, 1]);
                gainedMarksRef.current = gainedMarksRef.current + marksPerQuestion;
                setGainedMarks(gainedMarks => gainedMarks + marksPerQuestion);
                CrossImageRef.current.style.display = 'none';
                GoodImageRef.current.style.display = "inline-block";

                studentCurrAnswerRef.current = currQuestion.option4;
                marksObtainedCurrQuestionRef.current = marksPerQuestion;
                isCorrectRef.current = 1;

                submitStudentQuestionResponse();
            }
            else{
                setItems([...items, 0]);
                GoodImageRef.current.style.display = 'none';
                CrossImageRef.current.style.display = "inline-block";

                if(optionRef1.current.checked){
                    studentCurrAnswerRef.current = currQuestion.option1;
                }else if(optionRef2.current.checked){
                    studentCurrAnswerRef.current = currQuestion.option2;
                }else if(optionRef3.current.checked){
                    studentCurrAnswerRef.current = currQuestion.option3;
                }else if(optionRef4.current.checked){
                    studentCurrAnswerRef.current = currQuestion.option4;
                }
                marksObtainedCurrQuestionRef.current = 0;
                isCorrectRef.current = 0;

                submitStudentQuestionResponse();
            }

            clearInterval(intervalId);
            submitButton.current.style.display = "none";
            FinishedImageRef.current.style.display = "inline-block";

            progressCount.current += progressIncrement.current;
            ProgressRef.current.style.width = `${progressCount.current}px`;

            completeActivityRef.current = 1;
            submitCompleteActivityResponse();

        }
        else{

            if(optionRef1.current.checked && currQuestion.option1 == currQuestion.correctanswer){
                setItems([...items, 1]);
                gainedMarksRef.current = gainedMarksRef.current + marksPerQuestion;
                setGainedMarks(gainedMarks => gainedMarks + marksPerQuestion);
                CrossImageRef.current.style.display = 'none';
                GoodImageRef.current.style.display = "inline-block";

                studentCurrAnswerRef.current = currQuestion.option1;
                marksObtainedCurrQuestionRef.current = marksPerQuestion;
                isCorrectRef.current = 1;


                submitStudentQuestionResponse();
            }
            else if(optionRef2.current.checked && currQuestion.option2 == currQuestion.correctanswer){
                setItems([...items, 1]);
                gainedMarksRef.current = gainedMarksRef.current + marksPerQuestion;
                setGainedMarks(gainedMarks => gainedMarks + marksPerQuestion);
                CrossImageRef.current.style.display = 'none';
                GoodImageRef.current.style.display = "inline-block";

                studentCurrAnswerRef.current = currQuestion.option2;
                marksObtainedCurrQuestionRef.current = marksPerQuestion;
                isCorrectRef.current = 1;


                submitStudentQuestionResponse();
            }
            else if(optionRef3.current.checked && currQuestion.option3 == currQuestion.correctanswer){
                setItems([...items, 1]);
                gainedMarksRef.current = gainedMarksRef.current + marksPerQuestion;
                setGainedMarks(gainedMarks => gainedMarks + marksPerQuestion);
                CrossImageRef.current.style.display = 'none';
                GoodImageRef.current.style.display = "inline-block";

                studentCurrAnswerRef.current = currQuestion.option3;
                marksObtainedCurrQuestionRef.current = marksPerQuestion;
                isCorrectRef.current = 1;


                submitStudentQuestionResponse();

            }
            else if(optionRef4.current.checked && currQuestion.option4 == currQuestion.correctanswer){
                setItems([...items, 1]);
                gainedMarksRef.current = gainedMarksRef.current + marksPerQuestion;
                setGainedMarks(gainedMarks => gainedMarks + marksPerQuestion);
                CrossImageRef.current.style.display = 'none';
                GoodImageRef.current.style.display = "inline-block";
                


                studentCurrAnswerRef.current = currQuestion.option4;
                marksObtainedCurrQuestionRef.current = marksPerQuestion;
                isCorrectRef.current = 1;


                submitStudentQuestionResponse();

            }
            else{
                setItems([...items, 0]);
                GoodImageRef.current.style.display = 'none';
                CrossImageRef.current.style.display = "inline-block";


                if(optionRef1.current.checked){
                    studentCurrAnswerRef.current = currQuestion.option1;
                }else if(optionRef2.current.checked){
                    studentCurrAnswerRef.current = currQuestion.option2;
                }else if(optionRef3.current.checked){
                    studentCurrAnswerRef.current = currQuestion.option3;
                }else if(optionRef4.current.checked){
                    studentCurrAnswerRef.current = currQuestion.option4;
                }

                marksObtainedCurrQuestionRef.current = 0;
                isCorrectRef.current = 0;
                
                submitStudentQuestionResponse();
                
            }
            questionNumberRef.current = questionNumberRef.current + 1;
            setQuestionNumber(questionNumber => questionNumber + 1);
            setCurrQuestion(actualQuestions[questionNumberRef.current])
            console.log("..........Calling Fetch Image..........")
            fetchActivitiesImage(setImageData,actualQuestions[questionNumberRef.current].questionpicture);
            setMarksPerQuestion(actualQuestions[questionNumberRef.current].marks);

            progressCount.current += progressIncrement.current;
            ProgressRef.current.style.width = `${progressCount.current}px`;

            setCount(actualQuestions[questionNumberRef.current].timealloted);
            countRef.current = actualQuestions[questionNumberRef.current].timealloted;

            optionRef1.current.checked = false;
            optionRef2.current.checked = false;
            optionRef3.current.checked = false;
            optionRef4.current.checked = false;
        }


      }








      const handleCourseSelect = (course) => {
        console.log("Selected Course in Home:", course);
        selectedCourse(course);
      };
    
     const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
     
        useEffect(() => {
          const fetchData = async () => {
           await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
              credentials: 'include',
            }).then(response => {
                console.log("Response:",response);
              response.json().then(userInfo => {
                console.log("userinfo:",userInfo);
                setUserInfo(userInfo);
    
                if(userInfo?.usertype)
                {
                  const uType=userInfo?.usertype;
                  setUserRole(uType);
                }
              const uname = userInfo?.username;
              const avatar = userInfo?.avatar; // Store the avatar in a separate variable
              console.log("Uname: ", uname);
              console.log("Avatar: ", avatar);
    
              })
              .catch(err=> console.log("Error in useeffect:",err))
            });
          }
          fetchData();
        }, [isAdmin, isTeacher, isStudent, isGuest]);
      const determineUserRole = (usertype) => {
            console.log("In home.js:",usertype)
            if(usertype === 'Student')
              return "Student";
            else if(usertype === 'Teacher')
              return "Teacher";
            else if(usertype === 'Admin')
              return "Admin";
            else
              return "guest";
      };
      
      
     const navigate=useNavigate();
      const onLogin = (usertype) => {
        const userRole = determineUserRole(usertype);
        console.log("Calling setUserRole.")
        setUserRole(userRole);
      };
    
      function onLogout() {
          console.log("inside Onlogout.");
          fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
            credentials: 'include',
            method: 'POST',
          });
          setUserInfo(null);
          setUserRole('guest');
        }
          
        const username = userInfo?.username;
        const _avatar_ = userInfo?.avatar;
        const usertype = userInfo?.usertype;

           


  return (
    <div>


        {username && (
        <>
             {isAdmin && <AdminNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
            {isTeacher && <TeacherNav  onCourseSelect={handleCourseSelect}  username={username} avatar={_avatar_} onLogout={onLogout}/>}
            {isStudent && <StudentNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
         </>
         )}  
         {!username && (
           <>
           {isGuest && <GuestNav onLogin={onLogin} />}
  
           </>
         )}


        {/* <AdminNav/> */}
         <div className="MAMCQheading">
            <div className="MAMCQactivity-image">
                <img src={MCQActivityImage} alt=""/>
            </div>

            <div className="MAMCQactivity-text">
                <h3>Scratch M4 L1 MCQ Activity 1</h3>
            </div>
        </div>

    <div className="MAMCQcontainer">
        <div className="MAMCQhead">

            <div className="MAMCQquestions">

                <div className="MAMCQquestions-numbers">
                    <p>Question <span id="MAcurrent-question">{questionNumber}</span> of <span id="MAtotal-questions">{totalQuestions}</span></p>
                </div>

                <div className="MAMCQprogress-bar">
                    <div id="MAprogress" ref={ProgressRef}></div>
                </div>
                

            </div>

            <div className="MAMCQscore">

                <div className="MAMCQscore-text">
                    <h4>Score</h4>
                </div>

                <div className="MAMCQnumbered-score">
                    <h2> <span id="MAcurrent-score">{gainedMarks}</span> / <span id="MAtotal-score">{totalMarks}</span></h2>
                </div>

            </div>

        </div>


        <div className="MAMCQbody">

            <div className="MAMCQremaining-body">

            <div class="MAMCQquestion">
                <div class="MAMCQmental-question-section">

                    <div class="MAMCQmcq-text">
                        <h4>Q: <span id="MAMCQmental-question-text">{currQuestion.question}</span></h4>
                    </div>

                    <div className="MAMCQmcq-options">


                    <input type="radio" ref={optionRef1} id={"option1"} name="mcq" value={currQuestion.option1} />
                    <label for={"option1"}> {currQuestion.option1}</label><br />

                    <input type="radio" ref={optionRef2} id={"option2"} name="mcq" value={currQuestion.option2} />
                    <label for={"option2"}> {currQuestion.option2}</label><br />

                    <input type="radio" ref={optionRef3} id={"option3"} name="mcq" value={currQuestion.option3} />
                    <label for={"option3"}> {currQuestion.option3}</label><br />

                    <input type="radio" ref={optionRef4} id={"option4"} name="mcq" value={currQuestion.option4} />
                    <label for={"option4"}> {currQuestion.option4}</label><br />

                    </div>
                    <div class="MAMCQplay-button">
                        <button ref={submitButton} className='MAMCQsubmitButton' onClick={handleSubmit}><h5>Submit</h5></button>
                    </div>
                    
                </div>
            </div>
            <div class="MApicture">
                        {imageData ? (
                        <img
                          src={imageData}
                          alt="Question Image"
                          // className="MApicture"
                        />
                      ) : (
                        // <img src={imageData} alt="Question Image" /> 
                        <p></p>
                      )}
                    {/* <img src="" alt=""/> */}
                </div>

                <div className="MAMCQtime-marks">
                    <div className="MAMCQtime">
                        <div className="MAMCQtime-text">
                            <h4>Time</h4>
                        </div>
                        <div className="MAMCQtimer">
                            <h4 id="MAtime-counter">{count}</h4>
                        </div>
                    </div>

                    <div className="MAMCQmarks">
                        <div className="MAMCQmarks-text">
                            <h4>Marks</h4>
                        </div>
                        <div className="MAMCQmarker">
                            <h4><span id="MAmarks-per-question">{marksPerQuestion}</span></h4>
                        </div>
                    </div>

                </div>

                <div className="MAMCQinstructions">
                    <select className="MAMCQinstructions-dropdown" name="role">
                        {/* <option value="Instructions">Instructions</option> */}
                        <option value="Instruction-1">{currQuestion.instructions}</option>
                        {/* <option value="Instruction-2">Do not use calculator</option> */}
                    </select>
                </div>

                
            </div>

            <div id="MAmarks-color">
                {
                    items.map((item, index)=>(
                        <div className="MAbox">
                            <div className="MAbox1">
                                <h3>{index + 1}</h3>
                            </div>
                            <div className={`MAbox2${item}`}>

                            </div>
                        </div>
                    ))
                }

            </div>

        </div>
    </div>

    <div className="MAMCQremarks">
        <div className="MAMCQcorrect-wrong-picture">
            <img id="MAremarksId" src={GoodImage} ref={GoodImageRef} alt="" style={{ width: '100px', display: 'none'}}/>
            <img id="remarksId" src={CrossImage} ref={CrossImageRef} alt="" style={{ width: '100px', display: 'none'}}/>
        </div>

        <div className="MAMCQfinished-picture">
            <img id="finishedId" src={FinishedImage} ref={FinishedImageRef} alt="" style={{ width: '100px', display: 'none'}}/>
        </div>
    </div>
      <Footer/>
    </div>
  )
}

export default MCQActivity;
