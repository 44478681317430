import React, { useEffect,useRef, useState } from 'react';
import axios from 'axios';
import Logo from "../CourseImages/Logo.png";
import './Nav.css'
import { Link } from "react-router-dom";
import AttendClassModal from './AttendClassModal';
import { fetchImageData } from './fetchImage';
import dummy_user from "./dummy_user.png";

const TeacherNav = ({ username,avatar, onLogout ,onCourseSelect}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [imageData, setImageData] = useState(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setSelectedCourse(null);
  };

  // const handleCourseSelection = (course) => {
  //   setIsOpen2(!isOpen2);
  //   setSelectedCourse(course.Course_id);
  //   onCourseSelect(course.coursename);
  // };
  const handleCourseSelection = (courseId) => {
    setSelectedCourse(courseId);
    setIsOpen2(prevState => prevState !== courseId);
    onCourseSelect(courseId);  
  };
  
  
  const handleOpenDialog = () => {
    console.log('inside handleOpenDialog')
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  useEffect(() => {  
    fetchImageData(setImageData,avatar);
    axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getTeacherTaughtCourses`)
      .then(response => { 
        console.log("response.data.courseInfo:",response.data)
        setCourses(response.data.courseInfo);
        // console.log(response)
      })
      .catch(error => {
        console.error('Error fetching courses:', error);
      });
  }, []); 

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

    const [courses, setCourses] = useState([]);
  
  
  return (
    <div style={{ width: "100%" }}>
      <nav
        class="navbar navbar-expand-lg navbar-light "
        style={{ backgroundColor: "#edffff" }}
      >
        <div class="container-fluid" >
        <img src={Logo} alt="Logo" className='pageLogo' />
          <button
            style={{ marginLeft: "auto" }}
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div
            class="collapse navbar-collapse mx-3 Navbar-Right-Aligned"
            id="navbarSupportedContent"
            style={{marginLeft: "57%"}}
          >
            <ul class="navbar-nav" style={{ marginLeft: "10%" }}>
              <li class="nav-item active  ">
              <Link class="nav-link navLinks" to="/">Home
                <span class="sr-only">
                (current)
                </span>
              </Link>
              </li>

              <li className="dropdown-container" ref={dropdownRef}>
                  <a className="nav-link dropdown-toggle navLinks" onClick={toggleDropdown}>
                    My Courses
                  </a>
                  {isOpen && (
                    <li className="dropdown-menu-scrollable dropdown-menu_">
                      {courses.map((course) => (
                        <li key={course.Course_id}>
                          <a
                            className={`nav-link navLinks dropdown-item_ ${selectedCourse === course.Course_id ? 'active' : ''}`}
                            onClick={() => handleCourseSelection(course.Course_id)}
                            style={{ padding: "0" }}
                          >
                            <span style={{ width: "30vh", padding: "1vh" }} className="dropdown-toggle course-text">
                              {course.coursename}
                            </span>
                          </a>
                          {isOpen2 && selectedCourse === course.Course_id && (
                            <li className="nested-dropdown-menu_">
                              <Link to="/StudentDashboard" style={{ color: "#03b4c6", paddingLeft: "4vh" }} className="dropdown-item_ ">
                                Students DashBoard
                              </Link><br/>
                              <Link to="/GradeProjectPage" style={{ color: "#03b4c6", paddingLeft: "4vh" }} className="dropdown-item_ ">
                                Grade Projects
                              </Link><br/>
                              <a 
                                style={{ color: "#03b4c6", paddingLeft: "4vh", cursor: "pointer" }}
                                className="dropdown-menu-scrollable dropdown-item_ "
                                onClick={handleOpenDialog}
                              >
                                Take Class
                              </a>
                            </li>
                          )}
                        </li>
                      ))}
                    </li>
                  )}
                  {isDialogOpen && (
                    <AttendClassModal isOpen={isDialogOpen} onClose={handleCloseDialog} 
                                  classes={courses} selectedCourse={selectedCourse}/>
                  )}
              </li>

              <li class="nav-item ">
                  <Link class="nav-link navLinks" to="/calenderview">
                    My Calender View
                  </Link>
                </li>
              
              <li class="nav-item dropdown">
              <a className="nav-link dropdown-toggle navLinks navLinksArrowHide" style={{backgroundColor:'transparent', padding:'0'}} href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {imageData ? (
                    <img
                      src={imageData}
                      alt="Avatar"
                      className="avatar-image"
                    />
                  ) : (
                    <img src={dummy_user} alt="Default Avatar" className="avatar-image" /> 
                  )}
              </a>

                  <div class="dropdown-menu user-profile-dropdown"  style={{left:'-13vh'}}  aria-labelledby="navbarDropdownMenuLink">
                    <Link class="dropdown-item UserDropdown" onClick={onLogout}>Logout</Link>
                    <Link class="dropdown-item UserDropdown" to="/ChangePassword" >Change Password</Link>
                    <Link class="dropdown-item UserDropdown" to="/TeacherEditProfile" >Edit Profile</Link>
                    
                  </div>
                </li>   
               
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );

};

export default TeacherNav;
